import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { applicantBegin, applicantEnd } from './applicants';
import axios from '../../utils/axios';

const initialState = {
  letters: {
    approvals: [],
    denials: [],
    withdrawals: [],
    recerts: [],
    customs: [],
  },
  loading: false,
};



const slice = createSlice({
  name: 'letters',
  initialState,
  reducers: {
    lettersBegin: (letters) => {
      letters.loading = true;
    },
    lettersEnd: (letters) => {
      letters.loading = false;
    },
    getLetters: (letters, action) => {
      letters.letters = {
        ...letters.letters,
        ...action.payload,
      };
      letters.loading = false;
    },
    viewLetter: (letters, action) => {
      letters.letters.email = action.payload;
      letters.loading = false;
    },
  },
});



export const {
  lettersBegin,
  lettersEnd,
  getLetters,
  viewLetter,
} = slice.actions;



// ///////////////////////////////////
// //////////// LETTERS //////////////
// ///////////////////////////////////

// GET_LETTERS
export const startGetLetters = (applicant_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  dispatch(lettersBegin());
  try {
    const response = await axios.get(`${baseURL}/letters/${applicant_uuid}`);
    dispatch(getLetters(response.data));
    dispatch(applicantEnd());
    dispatch(lettersEnd());
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    dispatch(lettersEnd());
  }
};



// VIEW_LETTER
export const startViewLetter = (payload) => async (dispatch) => {
  dispatch(viewLetter(null));
  dispatch(applicantBegin());
  dispatch(lettersBegin());
  try {
    const response = await axios.post(`${baseURL}/letters/view`, payload);
    dispatch(viewLetter(response.data));
  } catch (error) {
    dispatch(viewLetter(null));
    console.error(error.response);
    dispatch(applicantEnd());
    dispatch(lettersEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// SEND/REISSUE
export const startSendLetter = (send_type, payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(applicantBegin());
  dispatch(lettersBegin());
  try {
    const response = await axios.post(`${baseURL}/letters/send`, payload);
    if (send_type === 'download') {
      await dispatch(startDownloadLetterAttachment({
        type: payload.type,
        uuid: response.data.uuid,
        letter_id: payload.letter_id,
        user_uuid: payload.user_uuid,
      }));
    } else {
      toast.success('Sent!');
    }
    dispatch(startGetLetters(payload.user_uuid));
    dispatch(applicantEnd());
    dispatch(lettersEnd());
    toggle();
  } catch (error) {
    if (setSubmitting) setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
    dispatch(lettersEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



export const startResendLetter = (letter_instance_id, send_type, payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(applicantBegin());
  dispatch(lettersBegin());
  try {
    await axios.post(`${baseURL}/letters/resend/${letter_instance_id}`, payload);
    toast.success('Sent!');
    dispatch(startGetLetters(payload.user_uuid));
    dispatch(applicantEnd());
    dispatch(lettersEnd());
    toggle();
  } catch (error) {
    if (setSubmitting) setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
    dispatch(lettersEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// LETTER UPDATE SENT
export const startLetterUpdateSent = (user_uuid, payload) => async (dispatch) => {
  dispatch(applicantBegin());
  dispatch(lettersBegin());
  try {
    await axios.put(`${baseURL}/letters/update-sent`, payload);
    dispatch(startGetLetters(user_uuid));
    dispatch(applicantEnd());
    dispatch(lettersEnd());
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    dispatch(lettersEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// DOWNLOAD LETTER ATTACHMENT
export const startDownloadLetterAttachment = (payload) => async (dispatch) => {
  dispatch(applicantBegin());
  dispatch(lettersBegin());
  try {
    const response = await axios({ url: `${baseURL}/letters/download-attachment`, method: 'POST', responseType: 'blob', data: payload });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(startGetLetters(payload.user_uuid));
    dispatch(applicantEnd());
    dispatch(lettersEnd());
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    dispatch(lettersEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// DOWNLOAD ALL RECENT CUSTOM LETTERS
export const startDownloadAllRecentCustomLetters = (payload) => async (dispatch) => {
  dispatch(applicantBegin());
  dispatch(lettersBegin());
  try {
    const response = await axios({ url: `${baseURL}/letters/download-latest`, method: 'POST', responseType: 'blob', data: payload });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(startGetLetters(payload.user_uuid));
    dispatch(applicantEnd());
    dispatch(lettersEnd());
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    dispatch(lettersEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};


export default slice.reducer;
