import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'bumblebee-ui';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { formatUTCDateForHTML } from 'wrkrb';

import { Basic1InstanceView, Basic2InstanceView } from '../components/checklists';
import { setApplicantFoldData } from '../redux/reducers/applicants';
import { setStickyChecklist, setChecklistSticky } from '../redux/reducers/checklists';
import { useToggle } from '../hooks';
import { history } from '../routers/AppRouter';


const StickyChecklists = ({ is_sticky_checklist, checklist_sticky, checklist_instances, applicant_uuid }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isOpen, toggle] = useToggle(true);

  useEffect(() => {
    if (!location.pathname.includes('/applicants/')) {
      dispatch(setStickyChecklist(false));
      dispatch(setChecklistSticky({}));
      dispatch(setApplicantFoldData(true));
    }
  }, [location.pathname]);


  const renderChecklists = (checklist_sticky) => {
    console.log('render checklists');
    switch (checklist_sticky.type) {
      case 'basic-1':
        return <Basic1InstanceView config={checklist_sticky} sticky />;

      case 'basic-2':
        return <Basic2InstanceView config={checklist_sticky} sticky />;

      default:
        return null;
    }
  };


  const renderChecklistMenu = (checklist_instances) => checklist_instances.map((d, i) => {
    const processed_options = JSON.parse(d.options);
    const [latestChecklistItem] = processed_options.payload
      .filter((d) => d.completed_at)
      .sort((a, b) => Date.parse(b.completed_at) - Date.parse(a.completed_at));
    const { is_complete, completed_by, completed_at } = processed_options;
    return (
      <div className='row d-flex justify-content-between py-3 px-4 pointer' key={i} onClick={() => dispatch(setChecklistSticky(d))}>
        <div>
          <h6 className='d-inline-block'>{d.name}</h6>
          {is_complete && (
            <div className='d-inline-block pl-2'>
              <span className='text-success f-bold mr-2'><Icon name='check' className='pr-2'/>Completed</span>
              <span className='d-inline-block text-muted'>{`${completed_by}, ${formatUTCDateForHTML(completed_at, true)}`}</span>
            </div>
          )}
          {(!is_complete && latestChecklistItem) &&
            <div className='d-inline-block pl-2'>
              <span className='text-muted f-bold mr-2'><Icon name='check' className='pr-2'/>In Progress</span>
              <span className='d-inline-block text-muted'>{`${latestChecklistItem.completed_by}, ${formatUTCDateForHTML(latestChecklistItem.completed_at, true)}`}</span>
            </div>
          }
        </div>
        <Icon name='chevron-right' title='go to checklist' className='d-inline-block' />
      </div>
    );
  });

  return is_sticky_checklist && (
    <div className={`sticky-container ${isOpen ? 'active' : ''}`}>
      <div className='sticky-card'>
        <div className='sticky-header'>
          <div className='d-flex justify-content-between'>
            {!checklist_sticky.name
              ? <h6 className='my-auto'>Checklists</h6>
              : (
                <div className='my-auto'>
                  <span className='fas fa-chevron-left px-2 pointer' onClick={() => dispatch(setChecklistSticky({}))} />
                  <h6 className='d-inline-block mx-1'>{checklist_sticky.name}</h6>
                </div>
              )
            }
            <div>
              {isOpen &&
                <span className='fas fa-browser mx-2 pointer' onClick={() => {
                  history.push(`/applicants/${applicant_uuid}/checklists`);
                  toggle();
                }} />
              }
              <span className='fas fa-window-minimize mx-2 pointer' onClick={toggle} />
              <span className='fas fa-times mx-2 pointer' onClick={() => {
                dispatch(setStickyChecklist(false));
                dispatch(setChecklistSticky({}));
              }} />
            </div>
          </div>
        </div>
        <div className='sticky-body'>
          {checklist_sticky.name
            ? renderChecklists(checklist_sticky)
            : renderChecklistMenu(checklist_instances)
          }
        </div>
      </div>
    </div>
  );
};


StickyChecklists.propTypes = {
  is_sticky_checklist: PropTypes.bool,
  checklist_sticky: PropTypes.object,
  checklist_instances: PropTypes.array,
  applicant_uuid: PropTypes.string,
};

export default memo(StickyChecklists);
