import axios from 'axios';
import ls from 'local-storage';
import { toast } from 'react-toastify';

import { store } from '../app';
import { logout } from '../redux/reducers/auth';
import { logout as logoutServicer } from '../redux/reducers/servicers/servicersAuth';
import { clearConfigs } from '../redux/reducers/config';
import { history } from '../routers/AppRouter';

// Add a request interceptor
const axiosInstance = axios.create();
axiosInstance.defaults.withCredentials = true;


// RESPONSE INTERCEPTOR
axiosInstance.interceptors.response.use((response) => response, (error) => {
  const { pathname } = history.location;
  const { status } = error.response;
  if (status === 401) {
    if (pathname.includes('/servicers')) {
      const isServicerLoggedIn = store.getState().servicers.auth.isLoggedIn;
      if (isServicerLoggedIn) toast.warn('Something is wrong. Please log back in.');
      ls.clear();
      store.dispatch(logoutServicer());
      store.dispatch(clearConfigs());
      history.push('/servicers/login');
    } else {
      const { isLoggedIn } = store.getState().auth;
      if (isLoggedIn) toast.warn('Something is wrong. Please log back in.');
      ls.clear();
      store.dispatch(logout());
      store.dispatch(clearConfigs());
      history.push('/');
    }
  }

  if (status === 498) {
    const originalRequest = error.config;
    return new Promise((resolve, reject) => {
      // Hit the /refresh/access-token route for new accessToken
      axiosInstance.get(`${pathname.includes('/servicers') ? servicerBaseURL : baseURL}/refresh/access-token`)
        .then(() => {
          axios(originalRequest)
            .then((respo) => resolve(respo))
            .catch((errr) => {
              if (errr.response.status === 401) {
                if (pathname.includes('/servicers')) {
                  const isServicerLoggedIn = store.getState().servicers.auth.isLoggedIn;
                  if (isServicerLoggedIn) toast.warn('Something is wrong. Please log back in.');
                  ls.clear();
                  store.dispatch(logoutServicer());
                  store.dispatch(clearConfigs());
                  history.push('/servicers/login');
                } else {
                  const { isLoggedIn } = store.getState().auth;
                  if (isLoggedIn) toast.warn('Something is wrong. Please log back in.');
                  ls.clear();
                  store.dispatch(logout());
                  store.dispatch(clearConfigs());
                  history.push('/');
                }
              }
            });
        })
        .catch((err) => {
          console.log(err);
          return reject(err);
        });
    });
  }

  return Promise.reject(error);
});


export default axiosInstance;
