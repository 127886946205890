import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Asterisk } from 'bumblebee-ui';
import { nanoid } from 'nanoid';

const Basic1Template = ({ options, setFieldValue, errors, touched, handleBlur }) => {
  const parsed_options = JSON.parse(options);
  const { is_complete, payload } = parsed_options;

  return (
    <Fragment>
      <h5 className='mb-4'>Checklist Items</h5>
      <div className='mb-4 mt-2'>
        <button type='button' className='btn btn-primary' onClick={() => {
          const options = JSON.stringify({ is_complete, payload: [...payload, { id: nanoid(), value: '', title: '' }] });
          setFieldValue('options', options);
        }}><span className='fas fa-plus pr-2' />Add Checklist Item</button>
      </div>
      {payload.map((item, i) => {
        const optionsArr = [...payload];
        const index = optionsArr.findIndex((x) => x.id === item.id);
        const [selectedItem] = optionsArr.filter((d) => d.id === item.id);
        return (
          <div className='form-row' key={i}>
            <div className={`form-group col mb-0 ${(errors.options && touched.options) ? 'has-danger' : ''}`}>
              <label htmlFor='options'>Checklist Item<Asterisk /></label>
              <input
                className={errors.options ? 'form-control form-control-danger' : 'form-control'}
                type='text'
                aria-label='options'
                name='options'
                onChange={(e) => {
                  selectedItem.title = e.target.value;
                  optionsArr[index] = selectedItem;
                  const temp_options = JSON.stringify({ is_complete, payload: [...optionsArr] });
                  setFieldValue('options', temp_options);
                }}
                onBlur={handleBlur}
                value={item.title} />
              {!selectedItem.title && <small className='error mt-1 text-danger'>Please add a title to the checklist item.</small>}
            </div>

            <div className='form-group col-auto text-center'>
              <label className='invisible d-block'>hi</label>
              <button type='button' className='btn btn-outline-light border' onClick={() => {
                optionsArr.splice(index, 1);
                const temp_options = JSON.stringify({ is_complete, payload: [...optionsArr] });
                setFieldValue('options', temp_options);
              }}><span className='fas fa-trash-alt text-danger' /></button>
            </div>
          </div>
        );
      })}
    </Fragment>
  );
};

Basic1Template.propTypes = {
  options: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
};

export default Basic1Template;
