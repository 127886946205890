import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Asterisk } from 'bumblebee-ui';
import { nanoid } from 'nanoid';

const Basic2Template = ({ options, setFieldValue, errors, handleBlur }) => {
  const parsed_options = JSON.parse(options);
  const { is_complete, payload } = parsed_options;

  return (
    <Fragment>
      <h5 className='mb-4'>Checklist Items</h5>
      {payload.map((item, i) => {
        const optionsArr = [...payload];
        const index = optionsArr.findIndex((x) => x.id === item.id);
        const [selectedItem] = optionsArr.filter((d) => d.id === item.id);
        return (
          <div className='form-row' key={i}>
            <div className={'form-group col mb-0'}>
              <label htmlFor='options'>Question<Asterisk /></label>
              <input
                className={errors.options ? 'form-control form-control-danger' : 'form-control'}
                type='text'
                aria-label='options'
                name='options'
                onChange={(e) => {
                  selectedItem.title = e.target.value;
                  optionsArr[index] = selectedItem;
                  const temp_options = JSON.stringify({ is_complete, payload: [...optionsArr] });
                  setFieldValue('options', temp_options);
                }}
                onBlur={handleBlur}
                value={item.title} />
              {!selectedItem.title && <small className='error mt-1 text-danger'>Please enter a question.</small>}
            </div>

            <div className={'form-group col mb-0'}>
              <label htmlFor='options'>Response Type<Asterisk /></label>
              <select
                className={errors.options ? 'form-control form-control-danger' : 'form-control'}
                name='options'
                onChange={(e) => {
                  selectedItem.response_type = e.target.value;
                  optionsArr[index] = selectedItem;
                  const temp_options = JSON.stringify({ is_complete, payload: [...optionsArr] });
                  setFieldValue('options', temp_options);
                }}
                onBlur={handleBlur}
                value={item.response_type} >
                <option value=''>Select...</option>
                <option value='yes/no'>Single choice (Yes, No)</option>
                <option value='yes/no/na'>Single choice (Yes, No, NA)</option>
                <option value='yes/no/other'>Single choice (Yes, No, Other)</option>
                <option value='confirm/waive'>Single choice (Confirm, Waive)</option>
                <option value='free text'>Free Text</option>
                <option value='date'>Date Select</option>
              </select>
              {!selectedItem.response_type && <small className='error mt-1 text-danger'>Please select a response type.</small>}
            </div>

            <div className='form-group col-auto text-center'>
              <label className='invisible d-block'>hi</label>
              <div className='d-flex align-items-center'>
                <button type='button' className='btn p-0' onClick={() => {
                  if (i !== 0) {
                    [optionsArr[i], optionsArr[i - 1]] = [optionsArr[i - 1], optionsArr[i]];
                    const temp_options = JSON.stringify({ is_complete, payload: [...optionsArr] });
                    setFieldValue('options', temp_options);
                  }
                }} >
                  <span className={`fas fa-chevron-up mb-0 ml-2 ${i !== 0 ? 'text-primary pointer' : 'text-muted'}`} />
                </button>
                <button type='button' className='btn p-0' onClick={() => {
                  if (i !== optionsArr.length - 1) {
                    [optionsArr[i], optionsArr[i + 1]] = [optionsArr[i + 1], optionsArr[i]];
                    const temp_options = JSON.stringify({ is_complete, payload: [...optionsArr] });
                    setFieldValue('options', temp_options);
                  }
                }}
                >
                  <span className={`fas fa-chevron-down mb-0 mx-3 ${i !== optionsArr.length - 1 ? 'text-primary pointer' : 'text-muted'}`} />
                </button>
                <button type='button' className='btn btn-outline-light border' onClick={() => {
                  optionsArr.splice(index, 1);
                  const temp_options = JSON.stringify({ is_complete, payload: [...optionsArr] });
                  setFieldValue('options', temp_options);
                }}><span className='fas fa-trash text-danger' /></button>
              </div>
            </div>
          </div>
        );
      })}
      <div className='mb-4 mt-2'>
        <button type='button' className='btn btn-primary' onClick={() => {
          const options = JSON.stringify({ is_complete, payload: [...payload, { id: nanoid(), value: '', title: '', response_type: '' }] });
          setFieldValue('options', options);
        }}><span className='fas fa-plus pr-2' />Add Checklist Item</button>
      </div>
    </Fragment>
  );
};

Basic2Template.propTypes = {
  options: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
};

export default Basic2Template;
