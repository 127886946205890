import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import axios from '../../../utils/axios';
import { startGetServicerCDFUploads } from './files';

const initialState = {
  record: {},
  records: [],
  record_responses: [],
  loading: false,
  draft_loading: false,
  response_record_drafts: [],
  response_record_draft: {},
};



const slice = createSlice({
  name: 'create',
  initialState,
  reducers: {
    createBegin: (create) => {
      create.loading = true;
    },
    createEnd: (create) => {
      create.loading = false;
    },
    draftBegin: (create) => {
      create.draft_loading = true;
    },
    draftEnd: (create) => {
      create.draft_loading = false;
    },
    sendRecordsToState: (create) => {
      create.loading = false;
    },
    getRecordResponses: (create, action) => {
      create.record_responses = action.payload;
      create.loading = false;
    },
    getRecordResponse: (create, action) => {
      create.record = action.payload;
      create.loading = false;
    },
    getResponseRecordDrafts: (create, action) => {
      create.response_record_drafts = action.payload;
      create.loading = false;
      create.response_record_draft = {};
    },
    getResponseRecordDraft: (create, action) => {
      create.response_record_draft = action.payload;
      create.draft_loading = false;
    },
    createResponseRecordDraft: (create) => {
      create.draft_loading = false;
    },
    updateResponseRecordDraft: (create) => {
      create.draft_loading = false;
    },
    deleteResponseRecordDraft: (create) => {
      create.draft_loading = false;
      create.response_record_draft = {};
    },
  },
});



export const {
  createBegin,
  createEnd,
  draftBegin,
  draftEnd,

  // CDF RECORDS
  sendRecordsToState,
  getRecordResponse,
  getRecordResponses,

  // RESPONSE RECORD DRAFTS
  getResponseRecordDrafts,
  getResponseRecordDraft,
  createResponseRecordDraft,
  updateResponseRecordDraft,
  deleteResponseRecordDraft,
} = slice.actions;



// SEND_RESPONSE_RECORDS
export const startSendRecordsToState = (values) => async (dispatch) => {
  const payload = values.map((d) => {
    delete d.isComplete;
    return d;
  });
  dispatch(createBegin());
  try {
    await axios.post(`${servicerBaseURL}/servicer-cdf-create`, payload);
    await dispatch(sendRecordsToState());
    await dispatch(startGetServicerCDFUploads());
    toast.success('Records Sent!');
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
    dispatch(createEnd());
  }
};

// GET_RESPONSE_RECORDS
export const startGetRecordResponses = () => async (dispatch) => {
  dispatch(createBegin());
  try {
    const response = await axios.get(`${servicerBaseURL}/servicer-cdf-create/response-records`);
    await dispatch(getRecordResponses(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(createEnd());
  }
};


// GET_RESPONSE_RECORD
export const startGetRecordResponse = (record_type, record_uuid) => async (dispatch) => {
  dispatch(createBegin());
  try {
    const response = await axios.get(`${servicerBaseURL}/servicer-cdf-create/response-records/${record_type}/${record_uuid}`);
    await dispatch(getRecordResponse(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(createEnd());
  }
};



// GET_RESPONSE_RECORD_DRAFTS
export const startGetResponseRecordDrafts = () => async (dispatch) => {
  dispatch(createBegin());
  try {
    const response = await axios.get(`${servicerBaseURL}/servicer-cdf-create/response-record-drafts`);
    await dispatch(getResponseRecordDrafts(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(createEnd());
  }
};



// GET_RESPONSE_RECORD_DRAFT
export const startGetResponseRecordDraft = (draft_uuid) => async (dispatch) => {
  dispatch(draftBegin());
  try {
    const response = await axios.get(`${servicerBaseURL}/servicer-cdf-create/response-record-drafts/${draft_uuid}`);
    await dispatch(getResponseRecordDraft(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(draftEnd());
  }
};



// CREATE_RESPONSE_RECORD_DRAFT
export const startCreateResponseRecordDraft = (payload, setSubmitting) => async (dispatch) => {
  dispatch(draftBegin());
  try {
    await axios.post(`${servicerBaseURL}/servicer-cdf-create/response-record-drafts`, payload);
    await dispatch(createResponseRecordDraft());
    await dispatch(startGetResponseRecordDrafts());
    toast.success('Created Draft');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(draftEnd());
  }
};



// UPDATE_RESPONSE_RECORD_DRAFT
export const startUpdateResponseRecordDraft = (draft_uuid, payload, setSubmitting) => async (dispatch) => {
  dispatch(draftBegin());
  try {
    await axios.put(`${servicerBaseURL}/servicer-cdf-create/response-record-drafts/${draft_uuid}`, payload);
    await dispatch(updateResponseRecordDraft());
    await dispatch(startGetResponseRecordDrafts());
    toast.success('Updated Draft!');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(draftEnd());
  }
};



// DELETE_CHECKLIST_TEMPLATE
export const startDeleteResponseRecordDraft = (draft_uuid) => async (dispatch) => {
  dispatch(draftBegin());
  try {
    await axios.delete(`${servicerBaseURL}/servicer-cdf-create/response-record-drafts/${draft_uuid}`);
    await dispatch(deleteResponseRecordDraft());
    await dispatch(startGetResponseRecordDrafts());
    toast.warning('Deleted Draft!');
  } catch (error) {
    console.error(error.response);
    dispatch(draftEnd());
  }
};



export default slice.reducer;
