import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
  applicantBegin, applicantEnd,
  // COAPP
  getCoapplicants, addCoapplicant, updateCoapplicant, deleteCoapplicant,
  // HOUSEHOLD
  getHousehold, addHousehold, updateHousehold, deleteHousehold,
  // LOAN
  getLoans, addLoan, updateLoan, deleteLoan,
  // ASSISTANCE REQUEST
  getAssistanceRequests, addAssistanceRequest, updateAssistanceRequest,
  // FINANCIAL RECORDS
  getFinancialRecords, addFinancialRecord, updateFinancialRecord, deleteFinancialRecord, getAMIVersionsData,
  // HARDSHIP
  getHardship, addHardship, updateHardship,
  // SIGNATURES
  signatureBegin, signatureEnd, getSignatures, downloadManualSignatures, downloadCompletedSignature, voidEnvelope, sendSignatureReminder, updateSignatureEmail,
  // Township
  getTownships,
  startGetStatus,
} from './applicants';
import { startGetDocuments, documentsBegin, uploadDocument, documentsEnd } from './documents';
import { history } from '../../routers/AppRouter';
import axios from '../../utils/axios';

const initialState = {
  loading: false,
  is_equal_to_ofr: false,
  afrs: [],
  afr: {},
  loansAndAFRLoans: [],
};

const slice = createSlice({
  name: 'afr',
  initialState,
  reducers: {
    // ADDITIONAL FUNDING REQUESTS
    getAllAFRs: (afr, action) => {
      afr.afrs = action.payload;
      afr.loading = false;
    },
    getAFRProfile: (afr, action) => {
      afr.afr = action.payload;
      afr.loading = false;
    },
    setAFREqualToOFR: (afr, action) => {
      afr.is_equal_to_ofr = action.payload;
      afr.loading = false;
    },
    updateAFRProfile: (afr) => {
      afr.loading = false;
    },
    clearAFRProfile: (afr) => {
      afr.afr = {};
      afr.loading = false;
    },
    offerAFR: (afr) => {
      afr.loading = false;
    },
  },
});



export const {
  // ADDITIONAL FUNDING REQUESTS
  getAllAFRs,
  getAFRProfile,
  setAFREqualToOFR,
  updateAFRProfile,
  clearAFRProfile,
  offerAFR,
} = slice.actions;

// ///////////////////////////////////////////
// // ADDITIONAL FUNDING REQUESTS (AFR) //////
// ///////////////////////////////////////////

// GET_ALL_AFRS
export const startGetAllAFRs = (user_uuid) => async (dispatch) => {
  dispatch(getAllAFRs([]));
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/afr/user/${user_uuid}`);
    dispatch(getAllAFRs(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// GET_AFR_PROFILE
export const startGetAFRProfile = (afr_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/afr/user/afr-id/${afr_uuid}`);
    dispatch(getAFRProfile(response.data));
    dispatch(setAFREqualToOFR(response.data.is_equal_to_ofr));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// UPDATE_AFR_PROFILE
export const startUpdateAFRProfile = (afr_uuid, payload, setSubmitting = () => { }, toggle = () => { }) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/afr/user/${afr_uuid}`, payload);
    await dispatch(updateAFRProfile());
    await dispatch(startGetAFRProfile(afr_uuid));
    toast.success('Updated!');
    toggle();
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// ACTIVATE_AFR_BULK
export const startActivateAFRBulk = (payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/afr/activate`, payload);
    await dispatch(updateAFRProfile());
    toast.success('Updated!');
    toggle();
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};


// ACTIVATE_AFR
export const startActivateAFR = (user_uuid, toggle, setSubmitting) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/afr/activate-single/${user_uuid}`);
    await dispatch(updateAFRProfile());
    await dispatch(startGetStatus(user_uuid));
    toast.success('Updated!');
    toggle();
    dispatch(offerAFR());
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
    if (error.response.status === 400 && error.response.data?.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error('It looks like something went wrong. Please try again.');
    }
  }
};



// ACTIVATE_MANUAL_AFR
export const startActivateManualAFR = (applicant_uuid, toggle, setSubmitting) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/afr/activate/manual/${applicant_uuid}`);
    await dispatch(updateAFRProfile());
    await dispatch(startGetStatus(applicant_uuid));
    toast.success('Additional Funding Request Created');
    toggle();
    dispatch(offerAFR());
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
    if (error.response.status === 400 && error.response.data?.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error('It looks like something went wrong. Please try again.');
    }
  }
};



// /////////////////////////////////
// /////// AFR COAPPLICANTS ////////
// /////////////////////////////////

// Get AFR Coapplicants
export const startGetAFRCoapplicants = (afr_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/afr/coapplicants/${afr_uuid}`);
    dispatch(getCoapplicants(response.data.coapplicants));
    dispatch(setAFREqualToOFR(response.data.is_equal_to_ofr));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// Add AFR Coapplicant
export const startAddAFRCoapplicant = (afr_uuid, payload, setSubmitting, user_uuid, setDisplay) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/afr/coapplicants/${afr_uuid}`, payload);
    await dispatch(addCoapplicant());
    await dispatch(startGetAFRCoapplicants(afr_uuid));
    await dispatch(startGetAFRProfile(afr_uuid));
    toast.success('Created!');
    setDisplay('view');
    history.push(`/applicants/${user_uuid}/co-applicant`);
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// Update AFR Coapplicant
export const startUpdateAFRCoapplicant = (afr_uuid, coapplicant_uuid, payload, setSubmitting, user_uuid, setDisplay) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/afr/coapplicants/${coapplicant_uuid}`, payload);
    await dispatch(updateCoapplicant());
    await dispatch(startGetAFRCoapplicants(afr_uuid));
    toast.success('Updated!');
    setDisplay('view');
    history.push(`/applicants/${user_uuid}/co-applicant`);
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// Delete AFR Coapplicant
export const startDeleteAFRCoapplicant = (afr_uuid, coapplicant_uuid, user_uuid, setDisplay) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.delete(`${baseURL}/afr/coapplicants/${coapplicant_uuid}`);
    await dispatch(deleteCoapplicant());
    await dispatch(startGetAFRCoapplicants(afr_uuid));
    toast.warning('Deleted!');
    setDisplay('view');
    history.push(`/applicants/${user_uuid}/co-applicant`);
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};


// ///////////////////////////////
// /////// AFR HOUSEHOLD /////////
// ///////////////////////////////

// Get AFR Household
export const startGetAFRHousehold = (afr_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/afr/household-members/${afr_uuid}`);
    dispatch(getHousehold(response.data.household_members));
    dispatch(setAFREqualToOFR(response.data.is_equal_to_ofr));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// Add AFR Household
export const startAddAFRHousehold = (afr_uuid, payload, setSubmitting, toggle, user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/afr/household-members/${afr_uuid}`, payload);
    await dispatch(addHousehold());
    await dispatch(startGetAFRHousehold(afr_uuid));
    await dispatch(startGetAFRProfile(afr_uuid));
    history.push(`/applicants/${user_uuid}/household`);
    toggle();
    toast.success('Created!');
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// Update AFR Household
export const startUpdateAFRHousehold = (afr_uuid, household_uuid, payload, setSubmitting, toggle, user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/afr/household-members/${household_uuid}`, payload);
    await dispatch(updateHousehold());
    await dispatch(startGetAFRHousehold(afr_uuid));
    history.push(`/applicants/${user_uuid}/household`);
    toggle();
    toast.success('Updated!');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// Delete AFR Household
export const startDeleteAFRHousehold = (afr_uuid, household_uuid, toggle, user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.delete(`${baseURL}/afr/household-members/${household_uuid}`);
    await dispatch(deleteHousehold());
    await dispatch(startGetAFRHousehold(afr_uuid));
    history.push(`/applicants/${user_uuid}/household`);
    toggle();
    toast.warning('Deleted!');
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// /////////////////////////////////
// /////////// AFR LOANS ///////////
// /////////////////////////////////

// Get AFR Loans
export const startGetAFRLoans = (afr_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/afr/loans/${afr_uuid}`);
    dispatch(getLoans(response.data.loans));
    dispatch(setAFREqualToOFR(response.data.is_equal_to_ofr));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// Add AFR Loan
export const startAddAFRLoan = (afr_uuid, payload, setSubmitting, user_uuid, setDisplay) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/afr/loans/${afr_uuid}`, payload);
    await dispatch(addLoan());
    await dispatch(startGetAFRLoans(afr_uuid));
    await dispatch(startGetAFRProfile(afr_uuid));
    setDisplay('view');
    toast.success('Created!');
    history.push(`/applicants/${user_uuid}/loan`);
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// Update AFR Loan
export const startUpdateAFRLoan = (afr_uuid, loan_uuid, payload, setSubmitting, user_uuid, setDisplay) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/afr/loans/${loan_uuid}`, payload);
    await dispatch(updateLoan());
    await dispatch(startGetAFRLoans(afr_uuid));
    setDisplay('view');
    toast.success('Updated!');
    history.push(`/applicants/${user_uuid}/loan`);
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// Delete AFR Loan
export const startDeleteAFRLoan = (afr_uuid, loan_uuid, user_uuid, toggleDelete, setDisplay) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.delete(`${baseURL}/afr/loans/${loan_uuid}`);
    await dispatch(deleteLoan());
    await dispatch(startGetAFRLoans(afr_uuid));
    toast.warning('Deleted!');
    setDisplay('view');
    toggleDelete();
    history.push(`/applicants/${user_uuid}/loan`);
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};


// ///////////////////////////////////
// /////// AFR ASSISTANCE REQUESTS ///////
// ///////////////////////////////////

// Get AFR Assistance Requests
export const startGetAFRAssistanceRequests = (afr_uuid) => async (dispatch) => {
  dispatch(getAssistanceRequests([]));
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/afr/assistance-requests/${afr_uuid}`);
    dispatch(getAssistanceRequests(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// Add AFR Assistance Request
export const startAddAFRAssistanceRequest = (afr_uuid, user_uuid, payload, setSubmitting, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/afr/assistance-requests/${afr_uuid}`, payload);
    await dispatch(addAssistanceRequest());
    await dispatch(startGetAFRAssistanceRequests(afr_uuid));
    await dispatch(startGetAFRProfile(afr_uuid));
    toast.success('Saved!');
    toggle();
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// Update AFR Assistance Request
export const startUpdateAFRAssistanceRequest = (assistance_uuid, payload, setSubmitting, toggle, afr_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/afr/assistance-requests/${assistance_uuid}`, payload);
    await dispatch(updateAssistanceRequest());
    await dispatch(startGetAFRAssistanceRequests(afr_uuid));
    toggle();
    toast.success('Updated!');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// /////////////////////////////////////////
// /////// AFR FINANCIAL_RECORDS ///////////
// /////////////////////////////////////////

// Get AFR Financial Records
export const startGetAFRFinancialRecords = (afr_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/afr/financial-records/${afr_uuid}`);
    dispatch(getFinancialRecords(response.data));
    dispatch(setAFREqualToOFR(response.data.is_equal_to_ofr));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// Add AFR Financial Record
export const startAddAFRFinancialRecord = (payload, setSubmitting, toggle, afr_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/afr/financial-records/${afr_uuid}`, payload);
    await dispatch(addFinancialRecord());
    await dispatch(startGetAFRFinancialRecords(afr_uuid));
    await dispatch(startGetAFRProfile(afr_uuid));
    toggle();
    toast.success('Created!');
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// Update AFR Financial Record
export const startUpdateAFRFinancialRecord = (financialRecord_id, payload, setSubmitting, toggle, afr_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/afr/financial-records/${financialRecord_id}`, payload);
    await dispatch(updateFinancialRecord());
    await dispatch(startGetAFRFinancialRecords(afr_uuid));
    toggle();
    toast.success('Updated!');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// Get Delete Financial Record
export const startDeleteAFRFinancialRecord = (financialRecord_id, toggle, afr_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.delete(`${baseURL}/afr/financial-records/${financialRecord_id}`);
    await dispatch(deleteFinancialRecord());
    await dispatch(startGetAFRFinancialRecords(afr_uuid));
    toggle();
    toast.warning('Deleted!');
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// Get AFR AMI Versions Data
export const startGetAFRAMIVersionsData = (afr_uuid, total_incomes) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/afr/financial-records/ami/${afr_uuid}`, { total_incomes });
    dispatch(getAMIVersionsData(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// Update AFR AMI Version
export const startUpdateAFRAMIVersion = (afr_uuid, ami_version, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/afr/user/ami/${afr_uuid}`, { ami_version });
    await dispatch(startGetAFRProfile(afr_uuid));
    await dispatch(startGetAFRFinancialRecords(afr_uuid));
    toast.success('Updated!');
    toggle();
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// ME Township: Get available townships
export const startGetTownshipsAFR = (afr_uuid) => async (dispatch) => {
  dispatch(getTownships([]));
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/townships/afr/${afr_uuid}`);
    dispatch(getTownships(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// ME Township: Assign township to applicant
export const startAssignTownshipAFR = (afr_uuid, township_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/townships/afr/${afr_uuid}/${township_uuid}`);
    await dispatch(startGetAFRProfile(afr_uuid));
    await dispatch(startGetAFRFinancialRecords(afr_uuid));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// /////////////////////////////////////
// /////////// AFR HARDSHIP ////////////
// /////////////////////////////////////

// Get AFR Hardship
export const startGetAFRHardship = (afr_uuid) => async (dispatch) => {
  dispatch(getHardship([]));
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/afr/hardship/${afr_uuid}`);
    dispatch(getHardship(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// Add AFR Hardship
export const startAddAFRHardship = (afr_uuid, payload, setSubmitting, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/afr/hardship/${afr_uuid}`, payload);
    await dispatch(addHardship());
    await dispatch(startGetAFRHardship(afr_uuid));
    await dispatch(startGetAFRProfile(afr_uuid));
    toast.success('Saved!');
    toggle();
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// Update AFR Hardship
export const startUpdateAFRHardship = (assistance_uuid, payload, setSubmitting, toggle, afr_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/afr/hardship/${assistance_uuid}`, payload);
    await dispatch(updateHardship());
    await dispatch(startGetAFRHardship(afr_uuid));
    toggle();
    toast.success('Updated!');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// ////////////////////////////////
// /////// AFR SIGNATURES /////////
// ////////////////////////////////

// GET_AFR_SIGNATURES
export const startGetAFRSignatures = (afr_uuid) => async (dispatch) => {
  dispatch(signatureBegin());
  try {
    const response = await axios.get(`${baseURL}/afr/signatures/${afr_uuid}`);
    if (response.data !== '') {
      if (!response.data.agreement_options) {
        response.data.agreement_options = {};
      }
      dispatch(getSignatures(response.data));
    }
  } catch (error) {
    console.error(error.response);
    dispatch(signatureEnd());
  }
};



// DOWNLOAD_COMPLETED_AFR_SIGNATURE
export const startDownloadCompletedAFRSignature = (external_id) => async (dispatch) => {
  dispatch(signatureBegin());
  try {
    const response = await axios({ url: `${baseURL}/afr/signatures/download/${external_id}`, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(downloadCompletedSignature());
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
    dispatch(signatureEnd());
  }
};



// DOWNLOAD_MANUAL_SIGNATURES
export const startDownloadManualAFRSignatures = (afr_uuid) => async (dispatch) => {
  dispatch(signatureBegin());
  try {
    const response = await axios({ url: `${baseURL}/afr/signatures/download-manual/${afr_uuid}`, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    await dispatch(startGetAFRProfile(afr_uuid));
    dispatch(downloadManualSignatures());
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
    dispatch(signatureEnd());
  }
};



// VOID_AFR_ENVELOPE
export const startVoidAFREnvelope = (user_uuid, afr_uuid, cb) => async (dispatch) => {
  dispatch(signatureBegin());
  try {
    await axios.put(`${baseURL}/afr/signatures/void/${afr_uuid}/${user_uuid}`);
    await dispatch(voidEnvelope());
    await dispatch(startGetAFRProfile(afr_uuid));
    await dispatch(getSignatures({ agreement_options: {} }));
    cb();
    toast.warning('AFR Signature Voided');
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
    dispatch(signatureEnd());
  }
};



// TOGGLE_AFR_SIGNATURE
export const startToggleAFRSignature = (user_uuid, desiredSigType, toggle, afr_uuid) => async (dispatch) => {
  dispatch(signatureBegin());
  try {
    await axios.put(`${baseURL}/afr/signatures/toggle/${afr_uuid}/${desiredSigType}`);
    await dispatch(startGetAFRSignatures(afr_uuid));
    await dispatch(startGetAFRProfile(afr_uuid));
    await dispatch(startGetDocuments(user_uuid));
    toggle();
    toast.success('AFR Signature Toggled');
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
    toggle();
    dispatch(signatureEnd());
  }
};



// SEND_SIGNATURE_REMINDER
export const startSendAFRSignatureReminder = (agreement_id, signatory, afr_uuid, toggleReminder) => async (dispatch) => {
  dispatch(signatureBegin());
  try {
    const payload = {
      recipientParticipantIds: signatory.map((d) => d.id),
      agreementId: agreement_id,
    };
    await axios.post(`${baseURL}/afr/signatures/reminders/${afr_uuid}`, payload);
    await dispatch(sendSignatureReminder());
    toast.success('Reminder Issued!');
    toggleReminder();
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
    dispatch(signatureEnd());
  }
};



// UPDATE_AFR_SIGNATURE_EMAIL
export const startUpdateAFRSignatureEmail = (data, setSubmitting, agreement_id, afr_uuid, toggleUpdateSignatureEmail, user_uuid) => async (dispatch) => {
  dispatch(signatureBegin());
  try {
    const payload = {
      agreementId: agreement_id,
      participantSetId: data.id,
      email: data.email,
    };
    await axios.put(`${baseURL}/afr/signatures/update-signer/${afr_uuid}`, payload);
    await dispatch(updateSignatureEmail());
    await dispatch(startGetAFRSignatures(afr_uuid));
    toast.success('Updated Email!');
    toggleUpdateSignatureEmail();
    history.push(`/applicants/${user_uuid}/signatures`);
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
    dispatch(signatureEnd());
  }
};



export default slice.reducer;

