import { createSlice } from '@reduxjs/toolkit';
import ls from 'local-storage';
import { toast } from 'react-toastify';

import { history } from '../../../routers/AppRouter';
import axios from '../../../utils/axios';

const initialState = {
  currentUser: {},
  registerToken: {},
  isLoggedIn: false,
  isPWResetTokenValid: false,
  loading: false,
};



const slice = createSlice({
  name: 'servicersAuth',
  initialState,
  reducers: {
    servicersAuthBegin: (servicersAuth) => {
      servicersAuth.loading = true;
    },
    servicersAuthEnd: (servicersAuth) => {
      servicersAuth.loading = false;
    },
    login: (servicersAuth, action) => {
      servicersAuth.currentUser = action.payload || {};
      servicersAuth.registerToken = {};
      servicersAuth.isLoggedIn = true;
      servicersAuth.loading = false;
    },
    logout: (servicersAuth) => {
      servicersAuth.currentUser = {};
      servicersAuth.isLoggedIn = false;
      servicersAuth.loading = false;
    },
    getRegisterToken: (servicersAuth, action) => {
      servicersAuth.registerToken = action.payload;
      servicersAuth.loading = false;
    },
    sendForgotPasswordEmail: (servicersAuth) => {
      servicersAuth.loading = false;
    },
    resetPasswordValid: (servicersAuth, action) => {
      servicersAuth.isPWResetTokenValid = action.payload;
      servicersAuth.loading = false;
    },
    resetPassword: () => {
      const payload = initialState;
      return payload;
    },
  },
});



export const {
  servicersAuthBegin,
  servicersAuthEnd,

  login,
  logout,
  getRegisterToken,
  sendForgotPasswordEmail,
  resetPasswordValid,
  resetPassword,
} = slice.actions;



// SERVICERS_LOGIN
export const startLogin = ({ payload, setSubmitting, deepLink, navigate }) => async (dispatch) => {
  dispatch(servicersAuthBegin());
  try {
    const response = await axios.post(`${servicerBaseURL}/login`, payload);
    const { refresh_tos, user } = response.data;
    ls('user-servicer', user);
    await dispatch(login(user));
  } catch (error) {
    let isToast = true;
    let toastMessage = 'It looks like something went wrong. Please try again.';

    if (error.response?.status === 409) {
      isToast = false;
      ls('email', payload.email);

      deepLink
        ? navigate('/servicers/two-factor', { state: { deepLink } })
        : navigate('/servicers/two-factor');
    }

    if (error.response?.status === 423) {
      toastMessage = 'Too many failed login attempts.  Please try again later.';
      navigate('/servicers/login');
    }

    if (isToast) toast.error(toastMessage);
    console.error(error.response);
    dispatch(servicersAuthEnd());
    setSubmitting();
  }
};



// SERVICERS_REGISTER_TOKEN
export const startGetRegisterToken = (token) => async (dispatch) => {
  dispatch(servicersAuthBegin());
  try {
    const response = await axios.get(`${servicerBaseURL}/register/${token}`);
    await dispatch(getRegisterToken(response.data));
  } catch (error) {
    if (error.response.status === 400) {
      dispatch(getRegisterToken({ isInvalid: true }));
    }
    console.error(error.response);
    dispatch(servicersAuthEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// SERVICERS_REGISTER
export const startRegister = (payload, setSubmitting) => async (dispatch) => {
  dispatch(servicersAuthBegin());
  try {
    const response = await axios.post(`${servicerBaseURL}/register`, payload);
    const { user } = response.data;
    ls('user-servicer', user);
    await dispatch(login(user));
  } catch (error) {
    console.error(error.response);

    if (error.response?.status === 409) {
      ls('email', payload.email);
      history.push('/servicers/two-factor');
    } else {
      dispatch(servicersAuthEnd());
      setSubmitting();
      toast.error('It looks like something went wrong. Please try again.');
    }
  }
};



// VALIDATE SESSION
export const startValidateSession = (values, setSubmitting, deepLink) => async (dispatch) => {
  dispatch(servicersAuthBegin());
  try {
    const response = await axios.post(`${servicerBaseURL}/validate/session`, values);
    if (response.data.user) {
      const { user } = response.data;
      ls('user-servicer', user);
      ls.remove('email');
      await dispatch(login(user));
      if (deepLink) {
        history.push(deepLink);
      }
    } else {
      toast.warn('Something went wrong, Resend a new code');
      setSubmitting();
    }
  } catch (error) {
    console.error(error.response);
    dispatch(servicersAuthEnd());
    setSubmitting();
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// RESEND_TWO_FACTOR
export const startResendTwoFactor = () => async (dispatch) => {
  dispatch(servicersAuthBegin());
  try {
    const email = ls('email');
    await axios.post(`${servicerBaseURL}/resend/mfa`, { email });
    toast.success('New Code Sent');
  } catch (error) {
    console.error(error.response);
    dispatch(servicersAuthEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// LOGOUT
export const startLogout = () => async (dispatch) => {
  dispatch(servicersAuthBegin());
  try {
    await axios.get(`${servicerBaseURL}/logout`);
    ls.clear();
    dispatch(logout());
    history.push('/servicers/login');
  } catch (error) {
    console.error(error.response);
    ls.clear();
    dispatch(logout());
    history.push('/servicers/login');
    toast.error('It looks like something went wrong.');
  }
};



// SEND_FORGOT_PASSWORD_EMAIL
export const startSendForgotPasswordEmail = (payload, setSubmitting, cb) => async (dispatch) => {
  dispatch(servicersAuthBegin());
  try {
    await axios.post(`${servicerBaseURL}/reset-password-email`, payload);
    setSubmitting();
    dispatch(sendForgotPasswordEmail());
    cb();
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(servicersAuthEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// RESET_PASSWORD_VALID
export const startValidateToken = (token) => async (dispatch) => {
  dispatch(servicersAuthBegin());
  try {
    const response = await axios.get(`${servicerBaseURL}/reset-password/${token}`);
    dispatch(resetPasswordValid(response.data.isValid));
  } catch (error) {
    console.error(error.response);
    dispatch(servicersAuthEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// RESET_PASSWORD
export const startResetPassword = (payload, setSubmitting) => async (dispatch) => {
  dispatch(servicersAuthBegin());
  try {
    await axios.post(`${servicerBaseURL}/reset-password`, payload);
    dispatch(resetPassword());
    ls.clear();
    toast.success('Password updated. Please log back in.');
    history.push('/servicers/login');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(servicersAuthEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



export default slice.reducer;
