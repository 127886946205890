import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import axios from '../../../utils/axios';

const initialState = {
  servicer_cdf_uploads: [],
  admin_cdf_uploads: [],
  loading: false,
};



const slice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    filesBegin: (files) => {
      files.loading = true;
    },
    filesEnd: (files) => {
      files.loading = false;
    },

    getServicerCDFUploads: (files, action) => {
      files.servicer_cdf_uploads = action.payload;
      files.loading = false;
    },
    getAdminCDFUploads: (files, action) => {
      files.admin_cdf_uploads = action.payload;
      files.loading = false;
    },
  },
});



export const {
  filesBegin,
  filesEnd,

  getServicerCDFUploads,
  getAdminCDFUploads,
} = slice.actions;



// GET_SERVICER_CDF_UPLOADS
export const startGetServicerCDFUploads = () => async (dispatch) => {
  dispatch(filesBegin());
  try {
    const response = await axios.get(`${servicerBaseURL}/servicer-cdf-uploads`);
    dispatch(getServicerCDFUploads(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(filesEnd());
  }
};



// Upload CDF
export const startUploadCDF = (file) => async (dispatch) => {
  dispatch(filesBegin());
  try {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    const formData = new FormData();
    formData.append('document', file);

    await axios.post(`${servicerBaseURL}/servicer-cdf-uploads`, formData, config);
    await dispatch(startGetServicerCDFUploads());
    toast.success('Uploaded!');
  } catch (error) {
    toast.error('It looks like something went wrong. Please try again.');
    console.error(error.response);
  }
  dispatch(filesEnd());
};



// Download Servicer-Uploaded CDF
export const startDownloadServicerCDF = (file_uuid) => async (dispatch) => {
  dispatch(filesBegin());
  try {
    const response = await axios({ url: `${servicerBaseURL}/servicer-cdf-uploads/${file_uuid}`, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
  }
  dispatch(filesEnd());
};



// GET_ADMIN_CDF_UPLOADS
export const startGetAdminCDFUploads = () => async (dispatch) => {
  dispatch(filesBegin());
  try {
    const response = await axios.get(`${servicerBaseURL}/admin-cdf-uploads`);
    dispatch(getAdminCDFUploads(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(filesEnd());
  }
};



// Download Admin-Uploaded CDF
export const startDownloadAdminCDF = (file_uuid) => async (dispatch) => {
  dispatch(filesBegin());
  try {
    const response = await axios({ url: `${servicerBaseURL}/admin-cdf-uploads/${file_uuid}`, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
  }
  dispatch(filesEnd());
};



// App Config - Get all admin/servicer CDF uploads
export const startGetAdminAndServicerCDFUploads = (vendor_uuid) => async (dispatch) => {
  dispatch(getAdminCDFUploads([]));
  dispatch(getServicerCDFUploads([]));
  dispatch(filesBegin());
  try {
    const response = await axios.get(`${baseURL}/config/vendors/cdf/${vendor_uuid}`);
    dispatch(getAdminCDFUploads(response.data.admin_uploads));
    dispatch(getServicerCDFUploads(response.data.servicer_uploads));
  } catch (error) {
    console.error(error.response);
    dispatch(filesEnd());
  }
};



// App Config - Download admin/servicer file
export const startDownloadAdminOrServicerCDF = (upload_type, file_uuid) => async (dispatch) => {
  dispatch(filesBegin());
  try {
    const response = await axios({ url: `${baseURL}/config/vendors/cdf/${upload_type}/${file_uuid}`, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
  }
  dispatch(filesEnd());
};



export default slice.reducer;
