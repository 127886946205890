import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { startGetLetters } from './letters';
import { startGetDocuments } from '../reducers/documents';
import { history } from '../../routers/AppRouter';
import axios from '../../utils/axios';
import { startGetAFRLoans } from './afr';

const initialState = {
  signature_loading: false,
  loading: false,
  current_status: null,
  status: [],
  applicantsRequestId: 1,
  applicants: {
    data: [],
  },
  applicant: { pre_screen: [] },
  coapplicants: [],
  household: [],
  loans: [],
  loansAndAFRLoans: [],
  financial_records: {
    income: [],
    expense: [],
    ami_versions_data: [],
  },
  assistance_requests: [],
  hardship: [],
  signatures: { agreement_options: {} },
  flags: [],
  ssn: '',
  fold_data: true,
  processor_statuses: [],
  townships: {
    loading: false,
    data: [],
  },
  prohibited_applicants_search_result: [],
};



const slice = createSlice({
  name: 'applicants',
  initialState,
  reducers: {
    applicantBegin: (applicants) => {
      applicants.loading = true;
    },
    applicantEnd: (applicants) => {
      applicants.loading = false;
    },
    signatureBegin: (applicants) => {
      applicants.signature_loading = true;
    },
    signatureEnd: (applicants) => {
      applicants.signature_loading = false;
    },

    // STATUS
    getCurrentStatus: (applicants, action) => {
      applicants.current_status = action.payload;
      applicants.loading = false;
    },
    getStatus: (applicants, action) => {
      applicants.status = action.payload;
      applicants.loading = false;
    },
    getProcessorStatuses: (applicants, action) => {
      applicants.processor_statuses = action.payload;
      applicants.loading = false;
    },
    sendStatus: (applicants) => {
      applicants.loading = false;
    },
    bulkSendStatus: (applicants) => {
      applicants.loading = false;
    },
    sendNote: (applicants) => {
      applicants.loading = false;
    },
    bulkSendNotes: (applicants) => {
      applicants.loading = false;
    },
    updatePinStatus: (applicants) => {
      applicants.loading = false;
    },

    // APPLICANTS
    updateApplicantsRequestId: (applicants, action) => {
      applicants.applicantsRequestId = action.payload;
    },
    getApplicants: (applicants, action) => {
      applicants.applicants = action.payload;
      applicants.loading = false;
    },
    getApplicant: (applicants, action) => {
      applicants.applicant = action.payload;
      applicants.loading = false;
    },
    updateApplicant: (applicants) => {
      applicants.loading = false;
    },
    updateApplicantMeter: (applicants) => {
      applicants.loading = false;
    },
    bulkUpdateApplicants: (applicants) => {
      applicants.loading = false;
    },
    assignApplicant: (applicants, action) => {
      applicants.applicants.data = applicants.applicants.data.map((d) => (d.uuid === action.payload.uuid
        ? {
          ...d,
          admin_id: action.payload.admin_id,
          admin_name: action.payload.admin_name,
          group_id: action.payload.group_id,
          group_name: action.payload.group_name,
        }
        : d));
      applicants.loading = false;
    },
    createManualApplicant: (applicants) => {
      applicants.loading = false;
    },
    deleteApplicant: (applicants) => {
      applicants.loading = false;
    },
    exportApplicants: (applicants) => {
      applicants.loading = false;
    },
    getVerifiedAddresses: (applicants, action) => {
      applicants.addresses = action.payload;
      applicants.loading = false;
    },
    getLWCHomesteadOcc: (applicants) => {
      applicants.loading = false;
    },
    getSSN: (applicants, action) => {
      applicants.ssn = action.payload;
      applicants.loading = false;
    },
    getCoAppSSN: (applicants, action) => {
      applicants.coapplicants = applicants.coapplicants.map((d) => (d.uuid === action.payload.uuid
        ? { ...d, ssn: action.payload.ssn }
        : d));
      applicants.loading = false;
    },
    getHouseholdSSN: (applicants, action) => {
      applicants.household = applicants.household.map((d) => (d.uuid === action.payload.uuid
        ? { ...d, ssn: action.payload.ssn }
        : d));
      applicants.loading = false;
    },
    removeSSN: (applicants) => {
      applicants.ssn = '';
    },
    removeCoAppSSN: (applicants, action) => {
      applicants.coapplicants = applicants.coapplicants.map((d) => (d.uuid === action.payload
        ? { ...d, ssn: '' }
        : d));
    },
    removeHouseholdSSN: (applicants, action) => {
      applicants.household = applicants.household.map((d) => (d.uuid === action.payload
        ? { ...d, ssn: '' }
        : d));
    },
    setApplicantFoldData: (applicants, action) => {
      applicants.fold_data = action.payload;
      applicants.loading = false;
    },
    downloadApplicantExport: (applicants) => {
      applicants.loading = false;
    },
    checkEmail: (applicants) => {
      applicants.loading = false;
    },
    updateEmail: (applicants) => {
      applicants.loading = false;
    },
    statusCheckEmail: (applicants) => {
      applicants.loading = false;
    },
    unblockEmail: (applicants) => {
      applicants.loading = false;
    },
    prohibitApplicant: (applicants) => {
      applicants.loading = false;
    },
    prohibitApplicantSearchResult: (applicants, action) => {
      applicants.loading = false;
      applicants.prohibited_applicants_search_result = action.payload;
    },
    resetProhibitApplicantSearchResult: (applicants) => {
      applicants.loading = false;
      applicants.prohibited_applicants_search_result = [];
    },

    // CO-APPLICANTS
    getCoapplicants: (applicants, action) => {
      applicants.coapplicants = action.payload;
      applicants.loading = false;
    },
    addCoapplicant: (applicants) => {
      applicants.loading = false;
    },
    updateCoapplicant: (applicants) => {
      applicants.loading = false;
    },
    deleteCoapplicant: (applicants) => {
      applicants.loading = false;
    },

    // HOUSEHOLD
    getHousehold: (applicants, action) => {
      applicants.household = action.payload;
      applicants.loading = false;
    },
    addHousehold: (applicants) => {
      applicants.loading = false;
    },
    updateHousehold: (applicants) => {
      applicants.loading = false;
    },
    deleteHousehold: (applicants) => {
      applicants.loading = false;
    },

    // LOANS
    getLoans: (applicants, action) => {
      applicants.loans = action.payload;
      applicants.loading = false;
    },
    getLoansAndAFRLoans: (applicants, action) => {
      applicants.loansAndAFRLoans = action.payload;
      applicants.loading = false;
    },
    addLoan: (applicants) => {
      applicants.loading = false;
    },
    updateLoan: (applicants) => {
      applicants.loading = false;
    },
    deleteLoan: (applicants) => {
      applicants.loading = false;
    },

    // ASSISTANCE_REQUESTS
    getAssistanceRequests: (applicants, action) => {
      applicants.assistance_requests = action.payload;
      applicants.loading = false;
    },
    updateAssistanceRequest: (applicants) => {
      applicants.loading = false;
    },
    addAssistanceRequest: (applicants) => {
      applicants.loading = false;
    },

    // FINANCIAL_RECORDS
    getFinancialRecords: (applicants, action) => {
      applicants.financial_records = {
        ...action.payload,
        ami_versions_data: applicants.financial_records.ami_versions_data,
      };
      applicants.loading = false;
    },
    addFinancialRecord: (applicants) => {
      applicants.loading = false;
    },
    updateFinancialRecord: (applicants) => {
      applicants.loading = false;
    },
    deleteFinancialRecord: (applicants) => {
      applicants.loading = false;
    },
    getAMIVersionsData: (applicants, action) => {
      applicants.financial_records.ami_versions_data = action.payload;
      applicants.loading = false;
    },

    // HARDSHIP
    getHardship: (applicants, action) => {
      applicants.hardship = action.payload;
      applicants.loading = false;
    },
    addHardship: (applicants) => {
      applicants.loading = false;
    },
    updateHardship: (applicants) => {
      applicants.loading = false;
    },

    // SIGNATURES
    getSignatures: (applicants, action) => {
      applicants.signatures = action.payload;
      applicants.signature_loading = false;
    },
    voidEnvelope: (applicants) => {
      applicants.signature_loading = false;
    },
    downloadCompletedSignature: (applicants) => {
      applicants.signature_loading = false;
    },
    downloadManualSignatures: (applicants) => {
      applicants.signature_loading = false;
    },
    unlockSignature: (applicants) => {
      applicants.signature_loading = false;
    },
    sendSignatureReminder: (applicants) => {
      applicants.signature_loading = false;
    },
    updateSignatureEmail: (applicants) => {
      applicants.signature_loading = false;
    },



    // FLAGS
    getFlags: (applicants, action) => {
      applicants.flags = action.payload;
      applicants.loading = false;
    },
    addFlags: (applicants) => {
      applicants.loading = false;
    },



    // LWC
    getHouseholdsForLWC: (applicants, action) => {
      applicants.household = action.payload;
      applicants.loading = false;
    },
    getLWCDataByUser: (applicants, action) => {
      applicants.lwc_user_data = action.payload;
      applicants.loading = false;
    },

    // PRESCREENING
    savePrescreeningValues: (applicants, action) => {
      applicants.applicant = { prescreen: action.payload };
      applicants.loading = false;
    },
    resetPrescreening: (applicants) => {
      applicants.applicant = { prescreen: [] };
      applicants.loading = false;
    },
    sendPrescreenFailure: (applicants) => {
      applicants.loading = false;
    },

    // REVIEWERS
    addReviewer: (applicants) => {
      applicants.loading = false;
    },
    bulkAddReviewer: (applicants) => {
      applicants.loading = false;
    },

    // Townships
    townshipsBegin: (applicants) => {
      applicants.townships.loading = true;
    },
    townshipsEnd: (applicants) => {
      applicants.townships.loading = false;
    },
    getTownships: (applicants, action) => {
      applicants.townships.data = action.payload;
      applicants.townships.loading = false;
    },

    // RESET AFR STATE
    resetAFRState: (applicants) => {
      applicants.coapplicants = [];
      applicants.household = [];
      applicants.loans = [];
      applicants.financial_records = {
        income: [],
        expense: [],
        ami_versions_data: [],
      },
      applicants.assistance_requests = [];
      applicants.hardship = [];
      applicants.signatures = { agreement_options: {} };
    },
  },
});



export const {
  applicantBegin,
  applicantEnd,
  signatureBegin,
  signatureEnd,

  resetAFRState,

  // STATUS
  getCurrentStatus,
  getStatus,
  getProcessorStatuses,
  sendStatus,
  bulkSendStatus,
  sendNote,
  bulkSendNotes,
  updatePinStatus,

  // APPLICANTS
  updateApplicantsRequestId,
  getApplicants,
  getApplicant,
  updateApplicant,
  updateApplicantMeter,
  bulkUpdateApplicants,
  assignApplicant,
  createManualApplicant,
  deleteApplicant,
  exportApplicants,
  getVerifiedAddresses,
  getLWCHomesteadOcc,
  getSSN,
  getCoAppSSN,
  getHouseholdSSN,
  removeSSN,
  removeCoAppSSN,
  removeHouseholdSSN,
  setApplicantFoldData,
  downloadApplicantExport,
  checkEmail,
  updateEmail,
  statusCheckEmail,
  unblockEmail,
  prohibitApplicant,
  prohibitApplicantSearchResult,
  resetProhibitApplicantSearchResult,

  // CO-APPLICANTS
  getCoapplicants,
  addCoapplicant,
  updateCoapplicant,
  deleteCoapplicant,

  // HOUSEHOLD
  getHousehold,
  addHousehold,
  updateHousehold,
  deleteHousehold,

  // LOANS
  getLoans,
  getLoansAndAFRLoans,
  addLoan,
  updateLoan,
  deleteLoan,

  //                                                                                       // ASSISTANCE_REQUESTS
  getAssistanceRequests,
  updateAssistanceRequest,
  addAssistanceRequest,

  // FINANCIAL_RECORDS
  getFinancialRecords,
  addFinancialRecord,
  updateFinancialRecord,
  deleteFinancialRecord,
  getAMIVersionsData,

  // HARDSHIP
  getHardship,
  addHardship,
  updateHardship,

  // SIGNATURES
  getSignatures,
  voidEnvelope,
  downloadCompletedSignature,
  downloadManualSignatures,
  unlockSignature,
  sendSignatureReminder,
  updateSignatureEmail,



  // LETTERS
  getLetters,
  viewLetter,



  // FLAGS
  getFlags,
  addFlags,



  // LWC
  getHouseholdsForLWC,
  getLWCDataByUser,

  // PRESCREENING
  savePrescreeningValues,
  resetPrescreening,
  sendPrescreenFailure,

  // REVIEWERS
  addReviewer,
  bulkAddReviewer,

  // Townships
  townshipsBegin,
  townshipsEnd,
  getTownships,
} = slice.actions;



const assembleSSPaginationURL = (url, payload) => {
  let newUrl = url;
  if (payload) {
    newUrl += payload.page ? `?page=${payload.page}` : '?page=1';
    newUrl += payload.limit ? `&limit=${payload.limit}` : '';
    newUrl += payload.sort_column ? `&sort_column=${payload.sort_column}` : '';
    newUrl += payload.sort_order ? `&sort_order=${payload.sort_order}` : '';
  }
  return newUrl;
};



// ////////////////////////////////////
// ///////////// STATUS ///////////////
// ////////////////////////////////////

// GET_CURRENT_STATUS
export const startGetCurrentStatus = (user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/status/current/${user_uuid}`);
    dispatch(getCurrentStatus(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// GET_STATUS
export const startGetStatus = (user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/status/${user_uuid}`);
    dispatch(getStatus(response.data));
    await dispatch(startGetCurrentStatus(user_uuid));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};

/// GET PROCESSOR STATUSES (EXCLUDING SYSTEM GENERATED)
export const startGetProcessorStatuses = () => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/status/processor-status`);
    dispatch(getProcessorStatuses(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};

// SEND_STATUS
export const startSendStatus = (user_uuid, payload, setSubmitting, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/status/${user_uuid}`, payload);
    await dispatch(sendStatus());
    dispatch(startGetStatus(user_uuid));
    toggle();
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// BULK_SEND_STATUS
export const startBulkSendStatus = (payload, setSubmitting, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/status/bulk`, payload);
    dispatch(bulkSendStatus());
    toggle();
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// SEND_NOTE
export const startSendNote = (user_uuid, payload, setSubmitting, resetForm) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/status/notes/${user_uuid}`, payload);
    await dispatch(sendNote());
    dispatch(startGetStatus(user_uuid));
    resetForm();
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// BULK_SEND_NOTES
export const startBulkSendNotes = (payload, setSubmitting, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/status/notes/bulk`, payload);
    dispatch(bulkSendNotes());
    toggle();
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
  }
};

// START_UPDATE_PIN_STATUS
export const startUpdatePinStatus = (payload) => async (dispatch) => {
  const { action, user_id, status_id } = payload;
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/status/${action}/${user_id}/${status_id}`, payload);
    await dispatch(updatePinStatus());
    dispatch(startGetStatus(user_id));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    if (error.response.status === 400) {
      toast.warning(error.response.data.message);
    } else {
      toast.error('It looks like something went wrong. Please try again.');
    }
  }
};



// ///////////////////////////////
// /////// APPLICANTS ////////////
// ///////////////////////////////

// GET_APPLICANTS_ALL
export const startGetApplicantsAll = (payload) => async (dispatch, getState) => {
  dispatch(applicantBegin());
  const existingRequestId = getState().applicants.applicantsRequestId;
  const newRequestId = existingRequestId + 1;
  dispatch(updateApplicantsRequestId(newRequestId));
  try {
    const url = assembleSSPaginationURL(`${baseURL}/users/all`, payload);
    const filters = payload.filters && payload.filters.filter((d) => d.filterKey && d.operatorValue && d.filterValue);
    const filtersPayload = filters && filters.map((d) => ({
      column: d.filterKey,
      filter: d.operatorValue,
      value: d.filterValue,
    }));
    const response = await axios.post(url, {
      carpentr_filters: filtersPayload,
      search_value: payload.search_value,
    });
    if (getState().applicants.applicantsRequestId === newRequestId) {
      dispatch(getApplicants(response.data));
    } else {
      dispatch(applicantEnd());
    }
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// Select All Applicant Uuids (All)
export const startSelectAllApplicants = (payload) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const url = assembleSSPaginationURL(`${baseURL}/users/all/select-all`, payload);
    const filters = payload.filters && payload.filters.filter((d) => d.filterKey && d.operatorValue && d.filterValue);
    const filtersPayload = filters.map((d) => ({
      column: d.filterKey,
      filter: d.operatorValue,
      value: d.filterValue,
    }));
    const response = await axios.post(url, {
      carpentr_filters: filtersPayload,
      search_value: payload.search_value,
    });
    dispatch(applicantEnd());
    return response.data;
  } catch (error) {
    dispatch(applicantEnd());
    console.error(error.response);
  }
};



// GET_APPLICANTS_GROUP
export const startGetApplicantsGroup = (payload) => async (dispatch, getState) => {
  dispatch(applicantBegin());
  const existingRequestId = getState().applicants.applicantsRequestId;
  const newRequestId = existingRequestId + 1;
  dispatch(updateApplicantsRequestId(newRequestId));
  try {
    const url = assembleSSPaginationURL(`${baseURL}/users/group`, payload);
    const filters = payload.filters && payload.filters.filter((d) => d.filterKey && d.operatorValue && d.filterValue);
    const filtersPayload = filters && filters.map((d) => ({
      column: d.filterKey,
      filter: d.operatorValue,
      value: d.filterValue,
    }));
    const response = await axios.post(url, {
      carpentr_filters: filtersPayload,
      search_value: payload.search_value,
    });
    if (getState().applicants.applicantsRequestId === newRequestId) {
      dispatch(getApplicants(response.data));
    } else {
      dispatch(applicantEnd());
    }
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// Select All Applicant UUIDs (Group)
export const startSelectAllApplicantsGroup = (payload) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const url = assembleSSPaginationURL(`${baseURL}/users/group/select-all`, payload);
    const filters = payload.filters && payload.filters.filter((d) => d.filterKey && d.operatorValue && d.filterValue);
    const filtersPayload = filters.map((d) => ({
      column: d.filterKey,
      filter: d.operatorValue,
      value: d.filterValue,
    }));
    const response = await axios.post(url, {
      carpentr_filters: filtersPayload,
      search_value: payload.search_value,
    });
    dispatch(applicantEnd());
    return response.data;
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// GET_APPLICANTS_GROUP
export const startGetApplicantsAssigned = (payload) => async (dispatch, getState) => {
  dispatch(applicantBegin());
  const existingRequestId = getState().applicants.applicantsRequestId;
  const newRequestId = existingRequestId + 1;
  dispatch(updateApplicantsRequestId(newRequestId));
  try {
    const url = assembleSSPaginationURL(`${baseURL}/users/assigned`, payload);
    const filters = payload.filters && payload.filters.filter((d) => d.filterKey && d.operatorValue && d.filterValue);
    const filtersPayload = filters && filters.map((d) => ({
      column: d.filterKey,
      filter: d.operatorValue,
      value: d.filterValue,
    }));
    const response = await axios.post(url, {
      carpentr_filters: filtersPayload,
      search_value: payload.search_value,
    });
    if (getState().applicants.applicantsRequestId === newRequestId) {
      dispatch(getApplicants(response.data));
    } else {
      dispatch(applicantEnd());
    }
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// Select All Applicant Uuids (Assigned)
export const startSelectAllApplicantsAssigned = (payload) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const url = assembleSSPaginationURL(`${baseURL}/users/assigned/select-all`, payload);
    const filters = payload.filters && payload.filters.filter((d) => d.filterKey && d.operatorValue && d.filterValue);
    const filtersPayload = filters.map((d) => ({
      column: d.filterKey,
      filter: d.operatorValue,
      value: d.filterValue,
    }));
    const response = await axios.post(url, {
      carpentr_filters: filtersPayload,
      search_value: payload.search_value,
    });
    dispatch(applicantEnd());
    return response.data;
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// GET_APPLICANT
export const startGetApplicant = (uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/users/${uuid}`);
    dispatch(getApplicant(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// UPDATE_APPLICANT
export const startUpdateApplicant = (applicant_uuid, payload, setSubmitting, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/users/${applicant_uuid}`, payload);
    await dispatch(updateApplicant());
    dispatch(startGetApplicant(applicant_uuid));
    toggle();
    toast.success('Updated!');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// UPDATE_APPLICANT_METER
export const startUpdateApplicantMeter = (applicant_uuid, payload) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/users/meter/${applicant_uuid}`, payload);
    await dispatch(updateApplicantMeter());
    dispatch(startGetApplicant(applicant_uuid));
    toast.success('Updated!');
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// ASSIGN_APPLICANT_TO_ADMIN
export const startAssignApplicantToAdmin = (applicant_uuid, payload, path = '') => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.put(`${baseURL}/users/assign/admin/${applicant_uuid}`, payload);
    await dispatch(assignApplicant(response.data));
    await dispatch(startGetApplicant(applicant_uuid));
    if (path.includes(':applicant_uuid')) await dispatch(startGetStatus(applicant_uuid));
    toast.success('Updated Staff!');
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// ASSIGN_APPLICANT_TO_GROUP
export const startAssignApplicantToGroup = (applicant_uuid, payload, path = '') => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.put(`${baseURL}/users/assign/group/${applicant_uuid}`, payload);
    await dispatch(assignApplicant(response.data));
    await dispatch(startGetApplicant(applicant_uuid));
    if (path.includes(':applicant_uuid')) await dispatch(startGetStatus(applicant_uuid));
    toast.success('Updated Group!');
  } catch (error) {
    console.error(error);
    dispatch(applicantEnd());
  }
};



// BULK_ASSIGN_APPLICANTS
export const startBulkAssignApplicants = (payload, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.put(`${baseURL}/users/assign/bulk`, payload);
    await dispatch(bulkUpdateApplicants());
    toggle();
    toast.success('Updated!');
    return response.status;
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// ASSIGN_REVIEWER
export const startAssignReviewer = (applicant_uuid, payload, path = '') => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/users/assign/reviewer/${applicant_uuid}`, payload);
    await dispatch(addReviewer());
    await dispatch(startGetApplicant(applicant_uuid));
    if (path.includes(':applicant_uuid')) await dispatch(startGetStatus(applicant_uuid));
    toast.success('Updated Reviewers!');
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
    dispatch(applicantEnd());
  }
};



// BULK_ASSIGN_REVIEWERS
export const startBulkAssignReviewers = (payload) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/users/assign/reviewer-bulk`, payload);
    await dispatch(bulkAddReviewer());
    toast.success('Updated Reviewers!');
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// CREATE_MANUAL_APPLICANT
export const startCreateManualApplicant = (payload, setSubmitting, toggleModal) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.post(`${baseURL}/users/create-manual`, payload);
    const user_uuid = response.data;
    dispatch(createManualApplicant());
    history.push(`/applicants/${user_uuid}/status`);
    toast.success('Manual applicant successfully created.');
  } catch (error) {
    setSubmitting();
    toggleModal();
    toast.error('It looks like something went wrong. Please try again.');
    console.error(error);
    dispatch(applicantEnd());
  }
};



// DELETE_APPLICANT
export const startDeleteApplicant = (applicant_uuid, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.delete(`${baseURL}/users/${applicant_uuid}`);
    await dispatch(deleteApplicant());
    dispatch(startGetApplicantsAll());
    toggle();
    toast.warning('Deleted!');
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// EXPORT_APPLICANTS
export const startExportApplicants = (payload, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios({ url: `${baseURL}/users/export`, method: 'POST', responseType: 'blob', data: payload });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(exportApplicants());
    toggle();
    toast.success('Downloaded!');
  } catch (error) {
    toast.error('It looks like something went wrong. Please try again.');
    console.error(error);
    dispatch(applicantEnd());
  }
};



// REFER APPLICANT
export const startReferApplicant = (applicant_uuid, payload, setSubmitting, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/users/update-counseling/${applicant_uuid}`, payload);
    await dispatch(startGetApplicant(applicant_uuid));
    await dispatch(startGetStatus(applicant_uuid));
    toggle();
  } catch (error) {
    setSubmitting();
    toast.error('It looks like something went wrong. Please try again');
    console.error(error.response);
    dispatch(applicantEnd);
  }
};



// RESTORE REFERRED APPLICANT
export const startRestoreReferredApplicant = (applicant_uuid, payload, setSubmitting, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/users/update-counseling/${applicant_uuid}`, payload);
    await dispatch(startGetApplicant(applicant_uuid));
    await dispatch(startGetStatus(applicant_uuid));
    // dispatch(startGetLetters(applicant_uuid)) won't need unless we integrate letters with referrals
    toggle();
    toast.success('Applicant Restored');
  } catch (error) {
    setSubmitting();
    toast.error('It looks like something went wrong. Please try again');
    console.error(error.response);
    dispatch(applicantEnd);
  }
};



// DENY_APPLICANT
export const startDenyApplicant = (applicant_uuid, payload, setSubmitting, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/users/deny/${applicant_uuid}`, payload);
    await dispatch(startGetApplicant(applicant_uuid));
    dispatch(startGetStatus(applicant_uuid));
    dispatch(startGetLetters(applicant_uuid));
    toggle();
    toast.warning('Applicant Denied');
  } catch (error) {
    setSubmitting();
    toast.error('It looks like something went wrong. Please try again.');
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// WITHDRAW_APPLICANT
export const startWithdrawApplicant = (applicant_uuid, payload, setSubmitting, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/users/withdraw/${applicant_uuid}`, payload);
    await dispatch(startGetApplicant(applicant_uuid));
    await dispatch(startGetStatus(applicant_uuid));
    dispatch(startGetLetters(applicant_uuid));
    toggle();
    toast.warning('Applicant Withdrawn');
  } catch (error) {
    setSubmitting();
    toast.error('It looks like something went wrong. Please try again');
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// REACTIVATE_APPLICANT
export const startReactivateApplicant = (applicant_uuid, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/users/reactivate/${applicant_uuid}`);
    await dispatch(startGetApplicant(applicant_uuid));
    await dispatch(startGetStatus(applicant_uuid));
    toggle();
    toast.success('Applicant Reactivated');
  } catch (error) {
    toast.error('It looks like something went wrong. Please try again');
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// GET_VERIFIED_ADDRESSES
export const startGetVerifiedAddresses = (enteredAddress) => async (dispatch) => {
  dispatch(getVerifiedAddresses(null));
  dispatch(applicantBegin());
  const addressString = `${enteredAddress.property_address_one}${enteredAddress.property_address_two ? ` ${enteredAddress.property_address_two}` : ''}, ${enteredAddress.property_city}, ${enteredAddress.property_state} ${enteredAddress.property_zip}`;
  try {
    const response = await axios.post(`${baseURL}/validate-address`, { address: addressString });
    const addresses = {
      enteredAddress,
      verifiedAddresses: response.data,
    };
    dispatch(getVerifiedAddresses(addresses));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// GET_LWC_HOMESTEAD_OCC
export const startGetLWCHomesteadOcc = (payload) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.post(`${baseURL}/lwc-housing/address`, payload);
    dispatch(getLWCHomesteadOcc());
    if (!response.data) {
      toast.warning('Unable to find LWC Homestead/Occupancy data for this address');
    }
    return response.data;
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



export const startGetSSN = (entity, uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/ssn-unmask/${entity}/${uuid}`);
    switch (entity) {
      case 'user':
      case 'afr-app':
        return dispatch(getSSN(response.data.ssn));
      case 'coapp':
      case 'afr-coapp':
        return dispatch(getCoAppSSN({ ssn: response.data.ssn, uuid }));
      case 'hh':
      case 'afr-hh':
        return dispatch(getHouseholdSSN({ ssn: response.data.ssn, uuid }));
    }
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



export const startDownloadApplicantExport = (applicant_uuid, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios({ url: `${baseURL}/users/audit-package/${applicant_uuid}`, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(downloadApplicantExport());
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
  toggle();
};



export const startProhibitApplicant = (applicant_uuid, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.post(`${baseURL}/prohibit-applicant/${applicant_uuid}`);
    dispatch(prohibitApplicant());
    toast.warning('Prohibited the applicant successfully.');
    toggle(false);
    return response.data;
  } catch (error) {
    toggle(false);
    console.error(error.response);
    dispatch(applicantEnd());
  }
};


export const startGetProhibitApplicantSearchResult = (payload, setSubmitting) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.post(`${baseURL}/prohibit-applicant/search/result`, payload);
    await dispatch(prohibitApplicantSearchResult(response.data));
    setSubmitting();
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
  }
};


export const startCheckEmail = (payload) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.post(`${baseURL}/users/check-email`, payload);
    await dispatch(checkEmail());
    return response;
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('Something went wrong, please try again.');
  }
};

export const startUpdateEmail = (payload, setSubmitting, toggle, applicant_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/users/change-email`, payload);
    await dispatch(updateEmail());
    await dispatch(startGetApplicant(applicant_uuid));
    toast.success('Updated Email!');
    toggle();
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('Something went wrong, please try again.');
  }
};


export const startStatusCheckEmail = (email, toggleLoading) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.post(`${baseURL}/users/suppression-list/check`, email);
    dispatch(statusCheckEmail());
    toggleLoading();
    return response;
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('Something went wrong, please try again.');
    toggleLoading();
  }
};


export const startUnblockEmail = (email, toggleLoading) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.put(`${baseURL}/users/suppression-list/unblock`, email);
    dispatch(statusCheckEmail());
    toast.success('Successfully removed email from SES Suppression List.');
    toggleLoading();
    return response;
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('Something went wrong, please try again.');
    toggleLoading();
  }
};



// ///////////////////////////////
// /////// COAPPLICANTS /////////
// /////////////////////////////

// GET_COAPPLICANTS
export const startGetCoapplicants = (user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/coapplicants/${user_uuid}`);
    dispatch(getCoapplicants(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// ADD_COAPPLICANT
export const startAddCoapplicant = (payload, setSubmitting, user_uuid, setDisplay) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/coapplicants/${user_uuid}`, payload);
    await dispatch(addCoapplicant());
    await dispatch(startGetCoapplicants(user_uuid));
    await dispatch(startGetApplicant(user_uuid));
    toast.success('Created!');
    setDisplay('view');
    history.push(`/applicants/${user_uuid}/co-applicant`);
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// UPDATE_COAPPLICANT
export const startUpdateCoapplicant = (coapplicant_uuid, payload, setSubmitting, user_uuid, setDisplay) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/coapplicants/${coapplicant_uuid}`, payload);
    await dispatch(updateCoapplicant());
    await dispatch(startGetCoapplicants(user_uuid));
    toast.success('Updated!');
    setDisplay('view');
    history.push(`/applicants/${user_uuid}/co-applicant`);
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// DELETE_COAPPLICANT
export const startDeleteCoapplicant = (coapplicant_uuid, user_uuid, setDisplay) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.delete(`${baseURL}/coapplicants/${coapplicant_uuid}`);
    await dispatch(deleteCoapplicant());
    await dispatch(startGetCoapplicants(user_uuid));
    toast.warning('Deleted!');
    setDisplay('view');
    history.push(`/applicants/${user_uuid}/co-applicant`);
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// ///////////////////////////////
// /////// HOUSEHOLD ////////////
// //////////////////////////////

// GET_HOUSEHOLD
export const startGetHousehold = (user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/household-members/${user_uuid}`);
    dispatch(getHousehold(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// ADD_HOUSEHOLD
export const startAddHousehold = (payload, setSubmitting, toggle, user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/household-members/${user_uuid}`, payload);
    await dispatch(addHousehold());
    await dispatch(startGetHousehold(user_uuid));
    await dispatch(startGetApplicant(user_uuid));
    history.push(`/applicants/${user_uuid}/household`);
    toggle();
    toast.success('Created!');
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// UPDATE_HOUSEHOLD
export const startUpdateHousehold = (household_uuid, payload, setSubmitting, toggle, user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/household-members/${household_uuid}`, payload);
    await dispatch(updateHousehold());
    await dispatch(startGetHousehold(user_uuid));
    history.push(`/applicants/${user_uuid}/household`);
    toggle();
    toast.success('Updated!');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// DELETE_HOUSEHOLD
export const startDeleteHousehold = (household_uuid, toggle, user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.delete(`${baseURL}/household-members/${household_uuid}`);
    await dispatch(deleteHousehold());
    await dispatch(startGetHousehold(user_uuid));
    history.push(`/applicants/${user_uuid}/household`);
    toggle();
    toast.warning('Deleted!');
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// //////////////////////////////
// /////////// LOANS ///////////
// /////////////////////////////

// GET_LOANS
export const startGetLoans = (user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/loans/${user_uuid}`);
    dispatch(getLoans(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// GET_LOANS_AND_AFR_LOANS (Get mixed loans/AFR loans for selection in custom letter dropdown)
export const startGetLoansAndAFRLoans = (applicant_uuid) => async (dispatch) => {
  dispatch(getLoansAndAFRLoans([]));
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/loans/dropdown/${applicant_uuid}`);
    dispatch(getLoansAndAFRLoans(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// ADD_LOAN
export const startAddLoan = (payload, setSubmitting, user_uuid, setDisplay) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/loans/${user_uuid}`, payload);
    await dispatch(addLoan());
    await dispatch(startGetLoans(user_uuid));
    await dispatch(startGetApplicant(user_uuid));
    setDisplay('view');
    toast.success('Created!');
    history.push(`/applicants/${user_uuid}/loan`);
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// UPDATE_LOAN
export const startUpdateLoan = (loan_uuid, payload, setSubmitting, user_uuid, setDisplay) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/loans/${loan_uuid}`, payload);
    await dispatch(updateLoan());
    await dispatch(startGetLoans(user_uuid));
    setDisplay('view');
    toast.success('Updated!');
    history.push(`/applicants/${user_uuid}/loan`);
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// DELETE_LOAN
export const startDeleteLoan = (loan_uuid, user_uuid, toggleDelete, setDisplay) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.delete(`${baseURL}/loans/${loan_uuid}`);
    await dispatch(deleteLoan());
    await dispatch(startGetLoans(user_uuid));
    setDisplay('view');
    toast.warning('Deleted!');
    toggleDelete();
    history.push(`/applicants/${user_uuid}/loan`);
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// Start Calculate Loan Limit Eligibility (OFR & AFR)
export const startCalculateLoanLimitEligibility = (loan_uuid, payload, user_uuid, afr, setSubmitting) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/loan-limit/${loan_uuid}`, payload);
    if (payload.is_afr && afr && afr.uuid) {
      dispatch(startGetAFRLoans(afr.uuid));
    } else {
      dispatch(startGetLoans(user_uuid));
    }
    toast.success('Submitted!');
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// Reset Loan Limit Eligibility (OFR & AFR)
export const startResetLoanLimitEligibility = (loan_uuid, is_afr, user_uuid, afr, setSubmitting) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.delete(`${baseURL}/loan-limit/${loan_uuid}`);
    if (is_afr && afr && afr.uuid) {
      dispatch(startGetAFRLoans(afr.uuid));
    } else {
      dispatch(startGetLoans(user_uuid));
    }
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// ///////////////////////////////////
// /////// ASSISTANCE REQUESTS ///////
// ///////////////////////////////////

// GET_ASSISTANCE_REQUESTS
export const startGetAssistanceRequests = (user_uuid) => async (dispatch) => {
  dispatch(getAssistanceRequests([]));
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/assistance-requests/${user_uuid}`);
    dispatch(getAssistanceRequests(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// ADD_ASSISTANCE_REQUEST
export const startAddAssistanceRequest = (user_uuid, payload, setSubmitting, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/assistance-requests/${user_uuid}`, payload);
    await dispatch(addAssistanceRequest());
    await dispatch(startGetAssistanceRequests(user_uuid));
    await dispatch(startGetApplicant(user_uuid));
    toast.success('Saved!');
    toggle();
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// UPDATE_ASSISTANCE_REQUEST
export const startUpdateAssistanceRequest = (assistance_uuid, payload, setSubmitting, toggle, user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/assistance-requests/${assistance_uuid}`, payload);
    await dispatch(updateAssistanceRequest());
    dispatch(startGetAssistanceRequests(user_uuid));
    toggle();
    toast.success('Updated!');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// //////////////////////////////////////
// /////// FINANCIAL_RECORDS ///////////
// ////////////////////////////////////

// GET_FINANCIAL_RECORDS
export const startGetFinancialRecords = (user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/financial-records/${user_uuid}`);
    dispatch(getFinancialRecords(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// ADD_FINANCIAL_RECORD
export const startAddFinancialRecord = (payload, setSubmitting, toggle, user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/financial-records/${user_uuid}`, payload);
    await dispatch(addFinancialRecord());
    await dispatch(startGetFinancialRecords(user_uuid));
    await dispatch(startGetApplicant(user_uuid));
    toggle();
    toast.success('Created!');
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// UPDATE_FINANCIAL_RECORD
export const startUpdateFinancialRecord = (financialRecord_id, payload, setSubmitting, toggle, user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/financial-records/${financialRecord_id}`, payload);
    await dispatch(updateFinancialRecord());
    await dispatch(startGetFinancialRecords(user_uuid));
    toggle();
    toast.success('Updated!');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// DELETE_FINANCIAL_RECORD
export const startDeleteFinancialRecord = (financialRecord_id, toggle, user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.delete(`${baseURL}/financial-records/${financialRecord_id}`);
    await dispatch(deleteFinancialRecord());
    await dispatch(startGetFinancialRecords(user_uuid));
    toggle();
    toast.warning('Deleted!');
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// GET_AMI_VERSIONS_DATA
export const startGetAMIVersionsData = (user_uuid, total_incomes) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.post(`${baseURL}/financial-records/ami/${user_uuid}`, { total_incomes });
    dispatch(getAMIVersionsData(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// Update AMI Version
export const startUpdateAMIVersion = (user_uuid, ami_version, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/users/ami/${user_uuid}`, { ami_version });
    await dispatch(startGetApplicant(user_uuid));
    await dispatch(startGetFinancialRecords(user_uuid));
    toast.success('Updated!');
    toggle();
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// ME Township: Get available townships
export const startGetTownships = (user_uuid) => async (dispatch) => {
  dispatch(getTownships([]));
  dispatch(townshipsBegin());
  try {
    const response = await axios.get(`${baseURL}/townships/${user_uuid}`);
    dispatch(getTownships(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(townshipsEnd());
  }
};



// ME Township: Assign township to applicant
export const startAssignTownship = (user_uuid, township_uuid) => async (dispatch) => {
  dispatch(townshipsBegin());
  try {
    await axios.put(`${baseURL}/townships/${user_uuid}/${township_uuid}`);
    await dispatch(startGetApplicant(user_uuid));
    await dispatch(startGetFinancialRecords(user_uuid));
    dispatch(townshipsEnd());
  } catch (error) {
    console.error(error.response);
    dispatch(townshipsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// ///////////////////////////////////
// /////////// HARDSHIP //////////////
// ///////////////////////////////////

// GET_HARDSHIP
export const startGetHardship = (user_uuid) => async (dispatch) => {
  dispatch(getHardship([]));
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/hardship/${user_uuid}`);
    dispatch(getHardship(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// ADD_HARDSHIP
export const startAddHardship = (user_uuid, payload, setSubmitting, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/hardship/${user_uuid}`, payload);
    await dispatch(addHardship());
    await dispatch(startGetHardship(user_uuid));
    await dispatch(startGetApplicant(user_uuid));
    toast.success('Saved!');
    toggle();
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// UPDATE_HARDSHIP
export const startUpdateHardship = (assistance_uuid, payload, setSubmitting, toggle, user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/hardship/${assistance_uuid}`, payload);
    await dispatch(updateHardship());
    await dispatch(startGetHardship(user_uuid));
    toggle();
    toast.success('Updated!');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// //////////////////////////////
// /////// SIGNATURES ///////////
// //////////////////////////////

// GET_SIGNATURES
export const startGetSignatures = (user_uuid) => async (dispatch) => {
  dispatch(signatureBegin());
  try {
    const response = await axios.get(`${baseURL}/signatures/${user_uuid}`);
    if (response.data !== '') {
      if (!response.data.agreement_options) {
        response.data.agreement_options = {};
      }
      dispatch(getSignatures(response.data));
    }
  } catch (error) {
    console.error(error.response);
    dispatch(signatureEnd());
  }
};



// VOID_ENVELOPE
export const startVoidEnvelope = (user_uuid, cb) => async (dispatch) => {
  dispatch(signatureBegin());
  try {
    await axios.put(`${baseURL}/signatures/void/${user_uuid}`);
    await dispatch(voidEnvelope());
    cb();
    toast.warning('Signature Voided');
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
    dispatch(signatureEnd());
  }
};



// DOWNLOAD_COMPLETED_SIGNATURE
export const startDownloadCompletedSignature = (external_id) => async (dispatch) => {
  dispatch(signatureBegin());
  try {
    const response = await axios({ url: `${baseURL}/signatures/download/${external_id}`, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(downloadCompletedSignature());
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
    dispatch(signatureEnd());
  }
};



// DOWNLOAD_MANUAL_SIGNATURES
export const startDownloadManualSignatures = (user_uuid) => async (dispatch) => {
  dispatch(signatureBegin());
  try {
    const response = await axios({ url: `${baseURL}/signatures/download-manual/${user_uuid}`, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    await dispatch(startGetApplicant(user_uuid));
    dispatch(downloadManualSignatures());
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
    dispatch(signatureEnd());
  }
};



// UNLOCK_SIGNATURE
export const startUnlockSignature = (user_uuid, cb) => async (dispatch) => {
  dispatch(signatureBegin());
  try {
    await axios.put(`${baseURL}/unlock-signature/${user_uuid}`);
    await dispatch(unlockSignature());
    cb();
    toast.success('E-Sign Unlocked');
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
    dispatch(signatureEnd());
  }
};



// TOGGLE_SIGNATURE
export const startToggleSignature = (user_uuid, desiredSigType, toggle) => async (dispatch) => {
  dispatch(signatureBegin());
  try {
    await axios.put(`${baseURL}/signatures/toggle/${user_uuid}/${desiredSigType}`);
    await dispatch(startGetSignatures(user_uuid));
    await dispatch(startGetApplicant(user_uuid));
    await dispatch(startGetDocuments(user_uuid));
    toggle();
    toast.success('Signature Toggled');
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
    toggle();
    dispatch(signatureEnd());
  }
};



// SEND_SIGNATURE_REMINDER
export const startSendSignatureReminder = (agreement_id, signatory, user_uuid, toggleReminder) => async (dispatch) => {
  dispatch(signatureBegin());
  try {
    const payload = {
      recipientParticipantIds: signatory.map((d) => d.id),
      agreementId: agreement_id,
    };
    await axios.post(`${baseURL}/signatures/reminders/${user_uuid}`, payload);
    await dispatch(sendSignatureReminder());
    toast.success('Reminder Issued!');
    toggleReminder();
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
    dispatch(signatureEnd());
  }
};



// UPDATE_SIGNATURE_EMAIL
export const startUpdateSignatureEmail = (data, setSubmitting, agreement_id, user_uuid, toggleUpdateSignatureEmail) => async (dispatch) => {
  dispatch(signatureBegin());
  try {
    const payload = {
      agreementId: agreement_id,
      participantSetId: data.id,
      email: data.email,
    };
    await axios.put(`${baseURL}/signatures/update-signer/${user_uuid}`, payload);
    await dispatch(updateSignatureEmail());
    toast.success('Updated Email!');
    toggleUpdateSignatureEmail();
    history.push(`/applicants/${user_uuid}/signatures`);
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
    dispatch(signatureEnd());
  }
};



// ///////////////////////////////////
// ///////////// FLAGS /////////////
// ///////////////////////////////////

// GET_FLAGS
export const startGetFlags = () => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/flags`);
    dispatch(getFlags(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// ADD_FLAGS
export const startAddFlags = (payload, user_uuid, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/flags/${user_uuid}`, payload);
    await dispatch(addFlags());
    await dispatch(startGetApplicant(user_uuid));
    toast.success('Updated Flags!');
    toggle();
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('Something went wrong, please try again.');
  }
};



// ///////////////////////////////////
// ////////////// LWC ////////////////
// ///////////////////////////////////

// GET_HOUSEHOLDS_FOR_LWC
export const startGetHouseholdsForLWC = () => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/lwc-user-data/households`);
    dispatch(getHouseholdsForLWC(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// GET_LWC_DATA_BY_USER
export const startGetLWCDataByUser = (user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/lwc-user-data/${user_uuid}`);
    dispatch(getLWCDataByUser(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// ADD PRESCREENING RESPONSE
export const startAddPrescreeningResponse = (answer, question) => (dispatch, getState) => {
  try {
    const { prescreen } = getState().applicants.applicant;
    const value = { question, answer };
    const return_values = [...prescreen, value];
    dispatch(savePrescreeningValues(return_values));
  } catch (error) {
    console.error(error.response);
  }
};



// RESET PRESCREENING VALUES
export const startResetPrescreening = () => (dispatch) => {
  try {
    dispatch(resetPrescreening());
  } catch (error) {
    console.error(error.response);
  }
};



// PRESCREEN FAILURE
export const startSendPrescreenFailure = (payload) => (dispatch) => {
  try {
    axios.post(`${baseURL}/pre_screen_failures`, payload);
    dispatch(sendPrescreenFailure());
  } catch (error) {
    console.error(error.response);
  }
};



export default slice.reducer;
