import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { Can } from 'bumblebee-ui';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { history } from '../routers/AppRouter';

const config = stateConfig;

const NavBar = ({ isLoggedIn, isServicerLoggedIn, onClickLogOut, onClickLogIn }) => {
  const [activeDropdown, setActiveDropdown] = useState(false);
  const permissions = useSelector((state) => state.auth.currentUser.permissions);
  const { pathname } = useLocation();

  return (
    <Fragment>
      <nav className='navbar col-12 p-0 fixed-top d-flex flex-row'>
        <div className='navbar-menu-wrapper d-flex align-items-center justify-content-between'>
          <button type='button' className='ml-3 btn p-0' onClick={() => history.push(pathname.includes('/servicers') ? '/servicers/login' : '/')}>
            <div className='mb-0 d-flex align-items-center'>
              <img data-cy='state-logo' src={config.style.logo} style={{ maxHeight: '60px', maxWidth: '280px' }} alt={`${config.programName} logo`} />
              <h5 className='text-primary mb-0 pl-3'>{pathname.includes('/servicers') ? 'Servicer Portal' : 'Processor Portal'}</h5>
            </div>
          </button>
          <ul className='navbar-nav'>
            {/* SKIP NAVIGATION */}
            <button type='button' className='skip-link btn btn-outline-primary btn-xs text-black f-regular mx-3 sr-only' data-cy='skip-nav' onClick={(e) => {
              e.preventDefault();
              const container = document.getElementById('app-wrapper');
              if (container) {
                container.tabIndex = -1;
                container.focus();
                setTimeout(() => container.removeAttribute('tabindex'), 1000);
              }
            }}>Skip Navigation</button>
            {isLoggedIn &&
              <Fragment>
                {/* App Configuration */}
                <Can
                  permissions={permissions}
                  perform={'config:read'}
                  yes={() => (
                    <li className={`nav-item d-none d-lg-flex justify-content-center ${pathname === '/app-config' ? 'active' : ''}`} >
                      <button className='btn px-0 px-lg-3 nav-link pointer d-flex align-items-center justify-content-center' onClick={() => {
                        history.push('/app-config');
                      }} data-cy='app-config'>
                        <div><span className='fas fa-tools mr-2' />App Configuration</div>
                      </button>
                    </li>)} />

                {/* Applicants */}
                <Can
                  permissions={permissions}
                  perform={['applicant:read-all', 'applicant:read-group', 'applicant:read-assigned']}
                  yes={() => (
                    <li className={`nav-item d-none d-lg-flex justify-content-center ${pathname === '/applicants' ? 'active' : ''}`}>
                      <button className='btn px-0 px-lg-3 nav-link pointer d-flex align-items-center justify-content-center' onClick={() => {
                        history.push('/applicants');
                      }} data-cy='applicants'>
                        <div><span className='fas fa-users mr-2' />Applicants</div>
                      </button>
                    </li>)} />

                {/* Reports */}
                <Can
                  permissions={permissions}
                  perform={'report:read'}
                  yes={() => (
                    <li className={`nav-item d-none d-lg-flex justify-content-center ${pathname === '/reports' ? 'active' : ''}`}>
                      <button className='btn px-0 px-lg-3 nav-link pointer d-flex align-items-center justify-content-center' onClick={() => {
                        history.push('/reports');
                      }} data-cy='reports'>
                        <div><span className='fas fa-file-alt mr-2' />Reports</div>
                      </button>
                    </li>)} />

                {/* Account */}
                <li className={`nav-item d-none d-lg-flex justify-content-center ${pathname === '/account' ? 'active' : ''}`}>
                  <button className='btn px-0 px-lg-3 nav-link pointer d-flex align-items-center justify-content-center' onClick={() => {
                    history.push('/account');
                  }} data-cy='account'>
                    <div><span className='fas fa-user-cog mr-2' />Account</div>
                  </button>
                </li>
              </Fragment>
            }

            {!isLoggedIn && !isServicerLoggedIn
              ? (
                <li className='nav-link d-none d-lg-flex'>
                  <button className='btn px-3 btn-success btn-sm mx-1 mx-sm-3 text-white' type='button' onClick={onClickLogIn} data-cy="login">Login</button>
                </li>
              ) : (
                <li className='nav-link d-none d-lg-flex'>
                  <button className='btn px-3 btn-outline-primary btn-sm mx-1 mx-sm-3' type='button' onClick={onClickLogOut} data-cy="logout">Logout</button>
                </li>
              )}

            {/* TABLET/MOBILE */}
            <li className={`nav-item dropdown ${activeDropdown ? 'show selected' : ''} d-flex d-lg-none justify-content-center align-items-center`}>
              <div className='nav-link mobile-menu pointer' onClick={() => setActiveDropdown(!activeDropdown)}>
                <span className='nav-icon fas fa-bars' />
              </div>

              <div className={`navbar-dropdown dropdown-menu shadow fh-regular pointer ${activeDropdown ? 'show' : ''}`}>

                {isLoggedIn &&
                  <Fragment>
                    <div className='dropdown-item' onClick={() => {
                      setActiveDropdown(false); history.push('/app-config');
                    }}>
                      <div className='body px-3'><span><span className='icon fas fa-tools mr-3' />App Configuration</span></div>
                    </div>

                    <div className='dropdown-divider'></div>

                    <div className='dropdown-item' onClick={() => {
                      setActiveDropdown(false); history.push('/applicants');
                    }}>
                      <div className='body px-3'><span><span className='icon fas fa-users mr-3' />Applicants</span></div>
                    </div>

                    <div className='dropdown-divider'></div>

                    <div className='dropdown-item' onClick={() => {
                      setActiveDropdown(false); history.push('/reports');
                    }}>
                      <div className='body px-3'><span><span className='icon fas fa-file-alt mr-3' />Reports</span></div>
                    </div>

                    <div className='dropdown-divider'></div>

                    <div className='dropdown-item' onClick={() => {
                      setActiveDropdown(false); history.push('/account');
                    }}>
                      <div className='body px-3'><span><span className='icon fas fa-user-cog mr-3' />Account</span></div>
                    </div>

                    <div className='dropdown-divider'></div>
                  </Fragment>
                }

                {/* LOG IN/OUT BUTTON */}
                {!isLoggedIn && !isServicerLoggedIn
                  ? (
                    <div
                      className='dropdown-item'
                      onClick={() => {
                        setActiveDropdown(false);
                        onClickLogIn();
                      }}
                    >
                      <div className='body no-hover d-flex align-items-center px-3'>
                        <button className='btn btn-success btn-xs btn-block px-5 text-white' type='button'>Log in</button>
                      </div>
                    </div>
                  ) : (
                    <div
                      className='dropdown-item'
                      onClick={() => {
                        setActiveDropdown(false);
                        onClickLogOut();
                      }}
                    >
                      <div className='body no-hover d-flex align-items-center px-3'>
                        <button className='btn btn-primary btn-xs btn-block px-5 text-white' type='button'>Log out</button>
                      </div>
                    </div>
                  )}

              </div>
            </li>
          </ul>
        </div>
      </nav>
    </Fragment>
  );
};

NavBar.propTypes = {
  isLoggedIn: PropTypes.bool,
  isServicerLoggedIn: PropTypes.bool,
  onClickLogOut: PropTypes.func,
  onClickLogIn: PropTypes.func,
};

export default NavBar;
