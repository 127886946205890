import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router';

const PublicRoute = ({ redirectPath = '/account', children }) => {
  const location = useLocation();

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isServicerLoggedIn = useSelector((state) => state.servicers.auth.isLoggedIn);

  if (isLoggedIn) return <Navigate to={redirectPath} replace state={{ from: location }}/>;
  if (isServicerLoggedIn) return <Navigate to={'/servicers/dashboard/account'} replace state={{ from: location }}/>;
  return children;
};

PublicRoute.propTypes = {
  redirectPath: PropTypes.string,
  children: PropTypes.element,
};

export default PublicRoute;
