import React from 'react';
import { createRoot } from 'react-dom/client';

import { ScrollToTopButton } from 'bumblebee-ui';
import ls from 'local-storage';
import { Provider } from 'react-redux';
import { animateScroll } from 'react-scroll';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';

import { login } from './redux/reducers/auth';
import { login as servicerLogin } from './redux/reducers/servicers/servicersAuth';
import { getConfigs } from './redux/reducers/config';
import createStore from './redux/store';
import AppRouter from './routers/AppRouter';
import './styles/styles.scss';
import 'focus-visible';
import { GlobalStyles } from './theme/GlobalStyles';
import { FocusVisible } from './theme/FocusVisible';

export const store = createStore();

if (ls('user')) {
  const user = ls('user');
  console.log('User: ', user);
  store.dispatch(login(user));
}

if (ls('user-servicer')) {
  const userServicer = ls('user-servicer');
  console.log('userServicer: ', userServicer);
  store.dispatch(servicerLogin(userServicer));
}

if (ls('authorization')) {
  ls.remove('authorization');
}

if (ls('config')) {
  const config = ls('config');
  store.dispatch(getConfigs(config));
}



const jsx = (
  <Provider store={store}>
    <ToastContainer
      position='top-right'
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange
      draggable
      pauseOnHover />

    <FocusVisible className="js-focus-visible focus-visible">
      <ThemeProvider theme={stateConfig.style}>
        <GlobalStyles />
        <AppRouter />
      </ThemeProvider>
    </FocusVisible>

    <ScrollToTopButton handleClick={() => {
      animateScroll.scrollToTop();
    }} />
  </Provider>
);

if (window.Cypress && environment !== 'production') {
  window.store = store;
  window.config = stateConfig;
}

const container = document.getElementById('app');
const root = createRoot(container);
root.render(jsx);
