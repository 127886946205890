import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { FieldError, Asterisk } from 'bumblebee-ui';
import { Formik } from 'formik';
import * as Yup from 'yup';


const ChecklistInstanceForm = ({ config, onSubmit }) => {
  const initialValues = {
    copy_name: config.name ? `${config.name} (copy)` : '',
  };

  const formSchema = Yup.object().shape({
    copy_name: Yup.string().trim().max(255),
  });

  const renderForm = ({ handleChange, handleBlur, handleSubmit, isSubmitting, errors, values, touched }) => (
    <form onSubmit={handleSubmit} noValidate autoComplete='off' encType='multipart/form-data'>
      <p>You are creating a copy of <span className='f-bold'>{config.name}</span>.</p>
      <div className='form-row'>
        <div className={`form-group col mb-0 ${(errors.name && touched.name) ? 'has-danger' : ''}`}>
          <label htmlFor='copy_name'>Name<Asterisk /></label>
          <input
            className={errors.copy_name ? 'form-control form-control-danger' : 'form-control'}
            type='text'
            aria-label='copy_name'
            name='copy_name'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.copy_name} />
          <FieldError error={errors.copy_name} touched={touched.copy_name} />
        </div>
      </div>

      <div className='col-12 p-0 text-right'>
        <button type='submit' className='btn btn-success btn-block my-2' disabled={isSubmitting}>Create Copy</button>
      </div>
    </form>
  );

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={formSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting();
          onSubmit(values);
        }}>
        {renderForm}
      </Formik>
    </Fragment>
  );
};

ChecklistInstanceForm.propTypes = {
  config: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default ChecklistInstanceForm;
