import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import axios from '../../../utils/axios';

const initialState = {
  loading: false,
};



const slice = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    vendorBegin: (vendor) => {
      vendor.loading = true;
    },
    vendorEnd: (vendor) => {
      vendor.loading = false;
    },

    getVendor: (vendor, action) => {
      const payload = {
        ...action.payload,
        loading: false,
      };
      return payload;
    },
  },
});



export const {
  vendorBegin,
  vendorEnd,

  getVendor,
} = slice.actions;



// GET_VENDOR
export const startGetVendor = () => async (dispatch) => {
  dispatch(vendorBegin());
  try {
    const response = await axios.get(`${servicerBaseURL}/vendor`);
    dispatch(getVendor(response.data));
  } catch (error) {
    toast.error('It looks like something went wrong. Please try again.');
    console.error(error.response);
    dispatch(vendorEnd());
  }
};



// Update SFTP Setup
export const startUpdateSFTPSetup = (payload, setSubmitting = () => { }) => async (dispatch) => {
  dispatch(vendorBegin());
  try {
    await axios.put(`${servicerBaseURL}/vendor`, payload);
    await dispatch(startGetVendor());
    toast.success('SFTP settings updated.');
    dispatch(vendorEnd());
  } catch (error) {
    toast.error('It looks like something went wrong. Please try again.');
    console.error(error.response);
    dispatch(vendorEnd());
  }
  setSubmitting(false);
};



// SEND SERVICER INVITE
export const startSendServicerInvite = (payload, setSubmitting, toggle) => async (dispatch) => {
  dispatch(vendorBegin());
  try {
    await axios.post(`${servicerBaseURL}/user-servicer-invites/invite`, payload);
    await dispatch(startGetVendor());
    dispatch(vendorEnd());
    toggle();
    toast.success('Invite Sent!');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(vendorEnd());
    if (error.response.status === 400) {
      toast.warning(error.response.data.message);
    } else {
      toast.error('It looks like something went wrong. Please try again.');
    }
  }
};



// REVOKE SERVICER INVITE
export const startRevokeServicerInvite = (invite_uuid, toggle) => async (dispatch) => {
  dispatch(vendorBegin());
  try {
    await axios.put(`${servicerBaseURL}/user-servicer-invites/revoke/${invite_uuid}`);
    await dispatch(startGetVendor());
    dispatch(vendorEnd());
    toggle();
    toast.warning('Invite Revoked');
  } catch (error) {
    console.error(error.response);
    dispatch(vendorEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// UPDATE USER SERVICER
export const startUpdateUserServicer = (servicer_user_uuid, payload, setSubmitting, toggle) => async (dispatch) => {
  dispatch(vendorBegin());
  try {
    await axios.put(`${servicerBaseURL}/user-servicers/${servicer_user_uuid}`, payload);
    await dispatch(startGetVendor());
    dispatch(vendorEnd());
    toggle();
    toast.success('Updated');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(vendorEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// DELETE USER SERVICER
export const startRemoveUserServicer = (servicer_user_uuid, toggle) => async (dispatch) => {
  dispatch(vendorBegin());
  try {
    await axios.delete(`${servicerBaseURL}/user-servicers/${servicer_user_uuid}`);
    await dispatch(startGetVendor());
    dispatch(vendorEnd());
    toggle();
    toast.warning('Servicer User Removed');
  } catch (error) {
    console.error(error.response);
    dispatch(vendorEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



export default slice.reducer;
