import { createSlice } from '@reduxjs/toolkit';
import ls from 'local-storage';
import { toast } from 'react-toastify';

import axios from '../../utils/axios';

const initialState = {
  additional_funding_requests: [{ is_accepting_requests: false, is_accepting_requests_automation: false }],
  ami: {},
  census_tracts_sdi: [],
  census_tracts_fbp: [],
  census_tracts_urban: [],
  communications_canned: [],
  counseling_agencies: [],
  counties: [],
  document_notes_canned: [],
  e_sign: [{ is_e_sign_manual: false }],
  employment_status: [],
  expense_categories: [],
  flags: [],
  groups: [],
  howd_you_hear: [],
  income_categories: [],
  landing_page: [],
  letters: [],
  loan_limits: {
    available_versions: [],
    latest_version: {},
  },
  marital_status: [],
  mortgage_types: [],
  processor_status: [],
  programs: [],
  prohibited_applicants: [],
  property_types: [],
  referral_codes: [],
  reservation_global_cap: {},
  user_denial_reasons: [],
  user_withdraw_reasons: [],
  vendors: [],
  zip_codes: [],
  recertification: {},
  loading: false,
  loading_docs: false,
};



const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    configsBegin: (config) => {
      config.loading = true;
    },
    configsEnd: (config) => {
      config.loading = false;
    },

    configDocsBegin: (config) => {
      config.loading_docs = true;
    },
    configDocsEnd: (config) => {
      config.loading_docs = false;
    },

    getConfigs: (config, action) => {
      const payload = {
        ...config,
        ...action.payload,
        loading: false,
      };
      return payload;
    },
    addConfig: (config) => {
      config.loading = false;
    },
    updateConfig: (config) => {
      config.loading = false;
    },
    deleteConfig: (config) => {
      config.loading = false;
    },

    getAmiRecords: (config, action) => {
      config.ami = action.payload;
      config.loading = false;
    },
    clearAmiRecords: (config) => {
      config.ami = {};
      config.loading = false;
    },

    getLoanLimits: (config, action) => {
      config.loan_limits = action.payload;
      config.loading = false;
    },
    clearLoanLimits: (config) => {
      config.loan_limits = initialState.loan_limits;
      config.loading = false;
    },

    getLettersConfig: (config, action) => {
      config.letters = action.payload;
      config.loading = false;
    },

    clearConfigs: (config) => {
      const payload = {
        ...config,
        ...initialState,
        loading: false,
      };
      return payload;
    },

    previewLetterEmail: (config, action) => {
      config.letter_email_preview = action.payload;
      config.loading = false;
    },

    updateAdditionalFunding: (config) => {
      config.loading = false;
    },
  },
});



export const {
  configsBegin,
  configsEnd,

  configDocsBegin,
  configDocsEnd,

  getConfigs,
  addConfig,
  updateConfig,
  deleteConfig,

  getAmiRecords,
  clearAmiRecords,

  getLoanLimits,
  clearLoanLimits,

  getLettersConfig,

  clearConfigs,

  previewLetterEmail,

  updateAdditionalFunding,
} = slice.actions;



// GET_CONFIGS
export const startGetConfigs = () => async (dispatch) => {
  dispatch(configsBegin());
  try {
    const response = await axios.get(`${baseURL}/app-config`);
    ls('config', response.data);
    dispatch(getConfigs(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(configsEnd());
  }
};



// ADD_CONFIG
export const startAddConfig = (type, payload, setSubmitting, toggle) => async (dispatch) => {
  dispatch(configsBegin());
  try {
    const response = await axios.post(`${baseURL}/config/${type}`, payload);
    await dispatch(addConfig());
    await dispatch(startGetConfigs());
    if (type === 'vendors') {
      toggle(response.data.id);
    } else {
      toggle();
    }
    toast.success('Created!');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(configsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// UPDATE_CONFIG
export const startUpdateConfig = (type, configID, payload, setSubmitting, toggle) => async (dispatch) => {
  dispatch(configsBegin());
  try {
    await axios.put(`${baseURL}/config/${type}/${configID}`, payload);
    await dispatch(updateConfig());
    await dispatch(startGetConfigs());
    toggle();
    toast.success('Updated!');
    setSubmitting();
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(configsEnd());
    if (error.response?.status === 409) {
      toast.error(error.response.data.message);
    } else {
      toast.error('It looks like something went wrong. Please try again.');
    }
  }
};



// DELETE_CONFIG
export const startDeleteConfig = (type, configID, toggle) => async (dispatch) => {
  dispatch(configsBegin());
  try {
    await axios.delete(`${baseURL}/config/${type}/${configID}`);
    await dispatch(deleteConfig());
    await dispatch(startGetConfigs());
    toggle();
    toast.warning('Deleted!');
  } catch (error) {
    console.error(error.response);
    dispatch(configsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// GET_AMI_RECORDS
export const startGetAmiRecords = (county_uuid, ami_version) => async (dispatch) => {
  dispatch(configsBegin());
  try {
    const response = await axios.post(`${baseURL}/config/counties/ami`, { county_uuid, ami_version });
    dispatch(getAmiRecords(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(configsEnd());
  }
};



// Get Loan Limits
export const startGetLoanLimits = (county_uuid, version_uuid) => async (dispatch) => {
  dispatch(configsBegin());
  try {
    const response = await axios.post(`${baseURL}/config/counties/loan-limit`, { county_uuid, version_uuid });
    dispatch(getLoanLimits(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(configsEnd());
  }
};



// GET_LETTERS
export const startGetLettersConfig = () => async (dispatch) => {
  dispatch(configsBegin());
  try {
    const response = await axios.get(`${baseURL}/config/letters`);
    const lsConfigs = ls('config');
    if (lsConfigs) {
      ls('config', {
        ...lsConfigs,
        letters: response.data,
      });
    }
    dispatch(getLettersConfig(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(configsEnd());
  }
};



// DOWNLOAD VENDORS LIST
export const startDownloadVendors = () => async (dispatch) => {
  dispatch(configsBegin());
  try {
    const response = await axios({ url: `${baseURL}/config/vendors/export`, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
  }
  dispatch(configsEnd());
};



// UPLOAD VENDOR DOCUMENT
export const startUploadVendorDocument = (file, vendor_uuid) => async (dispatch) => {
  dispatch(configDocsBegin());
  try {
    // const config = { headers: { 'content-type': 'multipart/form-data' } };
    const formData = new FormData();
    formData.append('document', file);

    const response = await axios.post(`${baseURL}/vendor-documents/upload/${vendor_uuid}`, formData, {
      // ...config,
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percentage = Math.floor((loaded * 100) / total);
        console.log(percentage);
      },
    });

    console.log('Upload successful. Server response:', response.data);
    await dispatch(startGetConfigs());
    toast.success('Document Uploaded');
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
  }
  dispatch(configDocsEnd());
};



// DOWNLOAD ALL VENDOR DOCUMENTS
export const startDownloadVendorDocumentsAll = (vendor_uuid) => async (dispatch) => {
  dispatch(configsBegin());
  try {
    const response = await axios({ url: `${baseURL}/vendor-documents/download/vendor/${vendor_uuid}`, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
  }
  dispatch(configsEnd());
};



// DOWNLOAD VENDOR DOCUMENT BY UUID
export const startDownloadVendorDocument = (document_uuid) => async (dispatch) => {
  dispatch(configsBegin());
  try {
    const response = await axios({ url: `${baseURL}/vendor-documents/download/${document_uuid}`, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
  }
  dispatch(configsEnd());
};



// DELETE VENDOR DOCUMENT
export const startDeleteVendorDocument = (document_uuid, toggle) => async (dispatch) => {
  dispatch(configsBegin());
  try {
    await axios.delete(`${baseURL}/vendor-documents/${document_uuid}`);
    await dispatch(startGetConfigs());
    toggle();
    toast.warning('Deleted!');
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
  }
  dispatch(configsEnd());
};



// PREVIEW_LETTER_EMAIL
export const startPreviewLetterEmail = (letter_uuid, type, toggle) => async (dispatch) => {
  dispatch(previewLetterEmail(null));
  dispatch(configsBegin());
  try {
    const response = await axios.get(`${baseURL}/config/letters/preview/${letter_uuid}/${type}`);
    dispatch(previewLetterEmail(response.data));
  } catch (error) {
    console.error(error.response);
    toggle(false);
    toast.error('It looks like something went wrong. Please try again.');
  }
  dispatch(configsEnd());
};



// DOWNLOAD LETTER ATTACHMENT
export const startDownloadLetterTemplateAttachment = (letter_uuid) => async (dispatch) => {
  dispatch(configsBegin());
  try {
    const response = await axios({ url: `${baseURL}/config/letters/attachment/${letter_uuid}`, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
  }
  dispatch(configsEnd());
};



// UPDATE_ADDITIONAL_FUNDING
export const startUpdateAdditionalFunding = (uuid, payload, toggle) => async (dispatch) => {
  dispatch(configsBegin());
  try {
    await axios.put(`${baseURL}/config/additional-funding-requests/${uuid}`, payload);
    await dispatch(updateAdditionalFunding());
    await dispatch(startGetConfigs());
    toggle();
    toast.success('Updated!');
  } catch (error) {
    console.error(error.response);
    dispatch(configsEnd());
    toggle();
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// ADD SUB SERVICER
export const startAddSubServicer = (payload, toggle) => async (dispatch) => {
  dispatch(configsBegin());
  try {
    await axios.post(`${baseURL}/sub-servicers`, payload);
    await dispatch(startGetConfigs());
    dispatch(configsEnd());
    toggle();
    toast.success('Added!');
  } catch (error) {
    console.error(error.response);
    dispatch(configsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// REMOVE SUB SERVICER
export const startRemoveSubServicer = (parent_servicer_id, toggle) => async (dispatch) => {
  dispatch(configsBegin());
  try {
    await axios.delete(`${baseURL}/sub-servicers/${parent_servicer_id}`);
    await dispatch(startGetConfigs());
    dispatch(configsEnd());
    toggle();
    toast.warning('Removed!');
  } catch (error) {
    console.error(error.response);
    dispatch(configsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// SEND SERVICER INVITE
export const startSendServicerInvite = (payload, setSubmitting, toggle) => async (dispatch) => {
  dispatch(configsBegin());
  try {
    await axios.post(`${baseURL}/user-servicer-invites/invite`, payload);
    await dispatch(startGetConfigs());
    dispatch(configsEnd());
    toggle();
    toast.success('Invite Sent!');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(configsEnd());
    if (error.response.status === 400) {
      toast.warning(error.response.data.message);
    } else {
      toast.error('It looks like something went wrong. Please try again.');
    }
  }
};



// UPDATE USER SERVICER
export const startUpdateUserServicer = (servicer_user_uuid, payload, setSubmitting, toggle) => async (dispatch) => {
  dispatch(configsBegin());
  try {
    await axios.put(`${baseURL}/user-servicers/${servicer_user_uuid}`, payload);
    await dispatch(startGetConfigs());
    dispatch(configsEnd());
    toggle();
    toast.success('Updated');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(configsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// REVOKE SERVICER INVITE
export const startRevokeServicerInvite = (invite_uuid, toggle) => async (dispatch) => {
  dispatch(configsBegin());
  try {
    await axios.put(`${baseURL}/user-servicer-invites/revoke/${invite_uuid}`);
    await dispatch(startGetConfigs());
    dispatch(configsEnd());
    toggle();
    toast.warning('Invite Revoked');
  } catch (error) {
    console.error(error.response);
    dispatch(configsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// DELETE USER SERVICER
export const startRemoveUserServicer = (servicer_user_uuid, toggle) => async (dispatch) => {
  dispatch(configsBegin());
  try {
    await axios.delete(`${baseURL}/user-servicers/remove/${servicer_user_uuid
      }`);
    await dispatch(startGetConfigs());
    dispatch(configsEnd());
    toggle();
    toast.warning('Servicer User Removed');
  } catch (error) {
    console.error(error.response);
    dispatch(configsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// GENERATE REFERRAL CODE
export const startGenerateReferralCode = (payload, setSubmitting, toggle) => async (dispatch) => {
  dispatch(configsBegin());
  try {
    await axios.post(`${baseURL}/referral-code`, payload);
    await dispatch(startGetConfigs());
    dispatch(configsEnd());
    toggle();
    toast.success('Referral Code Generated!');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(configsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



export const startAddServicerNotificationEmail = (payload, setSubmitting, resetForm) => async (dispatch) => {
  dispatch(configsBegin());
  try {
    await axios.post(`${baseURL}/vendor-emails`, payload);
    await dispatch(startGetConfigs());
    dispatch(configsEnd());
    setSubmitting();
    resetForm();
    toast.success('Added!');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(configsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



export const startDeleteServicerNotificationEmail = (email_uuid, toggle) => async (dispatch) => {
  dispatch(configsBegin());
  try {
    await axios.delete(`${baseURL}/vendor-emails/${email_uuid}`);
    await dispatch(startGetConfigs());
    dispatch(configsEnd());
    toggle();
    toast.warning('Deleted!');
  } catch (error) {
    console.error(error.response);
    dispatch(configsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



export default slice.reducer;
