import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { Collapse } from 'reactstrap';
import { formatUTCDateForHTML } from 'wrkrb';
import * as Yup from 'yup';

import { startUpdateChecklistInstance, setChecklistSticky } from '../../../redux/reducers/checklists';


const Basic2Instance = ({ config, isOpen, toggle, sticky }) => {
  const dispatch = useDispatch();
  const applicant = useSelector((state) => state.applicants.applicant);
  const currentUser = useSelector((state) => state.auth.currentUser);

  const { first_name, last_name, permissions } = currentUser;
  const canEdit = permissions.indexOf('applicant-checklist:update') > -1;

  const processed_options = JSON.parse(config.options);
  const { is_complete, payload } = processed_options;

  const initialValues = {};

  payload.forEach((item) => {
    initialValues[item.id] = item.value || '';
    if (item.response_type === 'yes/no/other') {
      initialValues[`${item.id}_explain`] = item.explain_value || '';
    }
  });

  const createYupSchema = (schema, configs) => {
    const { id } = configs;
    const validator = Yup.string();
    // Additional required logic : validator = validator['required'](['this field is required'])
    schema[id] = validator;
    return schema;
  };

  const yupSchema = payload.reduce(createYupSchema, {});
  const formSchema = Yup.object().shape(yupSchema);

  const responseTypes = [
    { type: 'yes/no', choices: ['Yes', 'No'] },
    { type: 'yes/no/na', choices: ['Yes', 'No', 'NA'] },
    { type: 'yes/no/other', choices: ['Yes', 'No', 'Other (Explain)'] },
    { type: 'confirm/waive', choices: ['Confirm', 'Waive'] },
    { type: 'free text', choices: [] },
    { type: 'date', choices: [] },
  ];

  const renderForm = ({ handleChange, handleBlur, handleSubmit, isSubmitting, values, errors, touched, resetForm }) => (
    <form onSubmit={handleSubmit} noValidate autoComplete='off' encType='multipart/form-data'>
      {payload.map((d, i) => {
        const responseTypeObj = responseTypes.find((r) => r.type === d.response_type);
        return (
          <Fragment key={i}>
            <fieldset>
              <div className='form-row mb-2'>
                <div className={`form-group col mb-0 ${(errors[d.id] && touched[d.id]) ? 'has-danger' : ''}`}>
                  <div className='d-flex'>
                    <legend>
                      <label className='d-block f-bold mb-1' htmlFor={[d.id]}>{d.title}</label>
                    </legend>
                    {d.completed_by &&
                      <div className='ml-2 d-inline-block text-muted'>
                        {sticky
                          ? `${formatUTCDateForHTML(d.completed_at, true)}`
                          : `${d.completed_by}, ${formatUTCDateForHTML(d.completed_at, true)}`
                        }
                      </div>
                    }
                  </div>
                  <div className='mb-4'>
                    {responseTypeObj.choices.map((c, ci) => (
                      <Fragment key={ci} >
                        <div className='form-check form-check-inline' onClick={() => {
                          if (d.value !== 'Confirm') {
                            d.completed_at = new Date().toISOString();
                            d.completed_by = `${first_name} ${last_name}`;
                          }
                        }}>
                          <input
                            id={`${d.id}-${ci}`}
                            className='form-check-input pointer'
                            type='radio'
                            aria-label={c}
                            disabled={applicant.read_only ? applicant.read_only : !canEdit ? is_complete : false}
                            name={`${d.id}`}
                            onChange={(e) => {
                              d.completed_at = new Date().toISOString();
                              d.completed_by = `${first_name} ${last_name}`;
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            checked={values[d.id] === c}
                            value={c} />
                          <label htmlFor={`${d.id}-${ci}`} className='form-check-label pointer'>{c}</label>
                        </div>

                        {c === 'Other (Explain)' && values[d.id] === c && (
                          <input
                            className={'form-control'}
                            placeholder='Explain...'
                            type='text'
                            disabled={applicant.read_only ? applicant.read_only : !canEdit ? is_complete : false}
                            aria-label={`${d.id}_explain`}
                            name={`${d.id}_explain`}
                            onChange={(e) => {
                              d.completed_at = new Date().toISOString();
                              d.completed_by = `${first_name} ${last_name}`;
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            value={values[`${d.id}_explain`]} />
                        )}
                      </Fragment>
                    ))}

                    {responseTypeObj.type === 'free text' && (
                      <input
                        className={'form-control'}
                        placeholder='Type here...'
                        type='text'
                        disabled={applicant.read_only ? applicant.read_only : !canEdit ? is_complete : false}
                        aria-label={`${d.id}_text`}
                        name={`${d.id}`}
                        onChange={(e) => {
                          d.completed_at = new Date().toISOString();
                          d.completed_by = `${first_name} ${last_name}`;
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        value={values[d.id]} />
                    )}

                    {responseTypeObj.type === 'date' && (
                      <input
                        className={'form-control'}
                        type='date'
                        disabled={applicant.read_only ? applicant.read_only : !canEdit ? is_complete : false}
                        max='9999-12-31'
                        aria-label={`${d.id}_date`}
                        name={`${d.id}`}
                        onChange={(e) => {
                          d.completed_at = new Date().toISOString();
                          d.completed_by = `${first_name} ${last_name}`;
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        value={values[d.id]} />
                    )}

                  </div>
                </div>
              </div>
            </fieldset>
          </Fragment>
        );
      })}

      {((canEdit || !is_complete) && !applicant.read_only) && (
        <div className='mt-3'>
          <button type='submit' className='btn btn-success btn-block' disabled={isSubmitting}>Save</button>
          <button type='button' className='btn btn-white btn-block my-2' onClick={async () => {
            await resetForm({ values: initialValues });
            sticky
              ? dispatch(setChecklistSticky({}))
              : toggle();
          }}>
            Cancel
          </button>
        </div>
      )}
    </form>
  );

  return (
    <Collapse isOpen={isOpen} className='collapse-nav'>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={formSchema}
        onSubmit={(values, actions) => {
          const copiedPayload = [...payload];
          let is_complete = false;
          let completed_at;
          let completed_by;
          let count = 0;

          const updatedPayload = copiedPayload.map((d) => {
            if (values[d.id]) {
              d.value = values[d.id];
              count += 1;
            }
            if (d.value !== initialValues[d.id]) {
              d.completed_at = new Date().toISOString();
              d.completed_by = `${first_name} ${last_name}`;
            }
            if (values[`${d.id}_explain`]) {
              d.explain_value = values[`${d.id}_explain`];
            }
            return d;
          });
          if (count === updatedPayload.length) {
            is_complete = true;
            completed_at = new Date().toISOString();
            completed_by = `${first_name} ${last_name}`;
          }

          const submitPayload = {
            name: config.name,
            description: config.description,
            type: config.type,
            options: JSON.stringify({
              is_complete,
              completed_at,
              completed_by,
              payload: updatedPayload,
            }),
          };
          dispatch(startUpdateChecklistInstance(config.uuid, applicant.uuid, submitPayload, actions.setSubmitting, toggle, sticky));
        }}>
        {renderForm}
      </Formik>
    </Collapse>
  );
};

Basic2Instance.propTypes = {
  config: PropTypes.object,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  sticky: PropTypes.bool,
};

export default Basic2Instance;
