import React from 'react';
import PropTypes from 'prop-types';

import ChecklistHeader from './ChecklistHeader';
import Basic2Instance from './Basic2Instance';
import { useToggle } from '../../../hooks';


const Basic2InstanceView = ({ config, sticky = false }) => {
  const [isOpen, toggleOpen] = useToggle(sticky);

  return (
    <div className={sticky ? 'mb-3' : 'border p-3 mb-3'} style={{ borderRadius: '6px' }}>
      <ChecklistHeader sticky={sticky} config={config} isOpen={isOpen} toggle={toggleOpen}/>

      <div className='row px-3 mb-3'>
        <div className='col'>{config.description}</div>
      </div>

      <div className='row px-3'>
        <div className='col'>
          <Basic2Instance isOpen={isOpen} config={config} toggle={toggleOpen} sticky={sticky} />
        </div>
      </div>
    </div>
  );
};

Basic2InstanceView.propTypes = {
  config: PropTypes.object,
  sticky: PropTypes.bool,
};

export default Basic2InstanceView;
