import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { applicantBegin, applicantEnd } from './applicants';
import { history } from '../../routers/AppRouter';
import axios from '../../utils/axios';

const initialState = {
  loading: false,
  checklists_template: [],
  all_checklist_templates: [],
  checklist_template: {},
  checklist_instances: [],
  checklist_sticky: {},
  is_sticky_checklist: false,
};

const slice = createSlice({
  name: 'checklists',
  initialState,
  reducers: {
    // CHECKLIST TEMPLATE
    getChecklistTemplate: (checklists, action) => {
      checklists.checklist_template = action.payload;
      checklists.loading = false;
    },
    getChecklistsTemplate: (checklists, action) => {
      checklists.checklists_template = action.payload;
      checklists.loading = false;
    },
    updateChecklistTemplate: (checklists) => {
      checklists.loading = false;
    },
    updateChecklistTemplatesSortOrder: (checklists) => {
      checklists.loading = false;
    },
    deleteChecklistTemplate: (checklists) => {
      checklists.loading = false;
    },
    createChecklistTemplate: (checklists) => {
      checklists.loading = false;
    },
    getAllChecklistTemplates: (checklists, action) => {
      checklists.all_checklist_templates = action.payload;
      checklists.loading = false;
    },
    bulkDownloadChecklists: (checklists) => {
      checklists.loading = false;
    },
    // CHECKLIST INSTANCE
    getChecklistsInstance: (checklists, action) => {
      checklists.checklist_instances = action.payload;
      checklists.loading = false;
    },
    setChecklistSticky: (checklists, action) => {
      checklists.checklist_sticky = action.payload;
      checklists.loading = false;
    },
    setStickyChecklist: (checklists, action) => {
      checklists.is_sticky_checklist = action.payload;
      checklists.loading = false;
    },
    updateChecklistInstance: (checklists) => {
      checklists.loading = false;
    },
    downloadChecklistInstance: (checklists) => {
      checklists.loading = false;
    },
    createChecklistInstance: (checklists) => {
      checklists.loading = false;
    },
  },
});



export const {
  // CHECKLIST TEMPLATE
  getChecklistTemplate,
  getChecklistsTemplate,
  updateChecklistTemplate,
  updateChecklistTemplatesSortOrder,
  deleteChecklistTemplate,
  createChecklistTemplate,
  getAllChecklistTemplates,
  bulkDownloadChecklists,

  // CHECKLIST INSTANCE
  getChecklistsInstance,
  setChecklistSticky,
  setStickyChecklist,
  updateChecklistInstance,
  downloadChecklistInstance,
  createChecklistInstance,
} = slice.actions;



// ///////////////////////////////////
// /////// CHECKLIST TEMPLATE ///////
// ///////////////////////////////////

// GET_CHECKLIST_TEMPLATE
export const startGetChecklistTemplate = (checklist_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/checklist-templates/${checklist_uuid}`);
    const processed = response.data.map((d) => ({ ...d, options: JSON.stringify(d.options) }));
    dispatch(getChecklistTemplate(processed[0]));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// GET_CHECKLISTS_TEMPLATE
export const startGetChecklistsTemplate = () => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/checklist-templates`);
    const processed = response.data.map((d) => ({ ...d, options: JSON.stringify(d.options) }));
    dispatch(getChecklistsTemplate(processed));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// CREATE_CHECKLIST_TEMPLATE
export const startCreateChecklistTemplate = (payload, setSubmitting) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/checklist-templates`, payload);
    dispatch(createChecklistTemplate());
    toast.success('Created!');
    history.push('/app-config');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// UPDATE_CHECKLIST_TEMPLATE
export const startUpdateChecklistTemplate = (checklist_uuid, payload, setSubmitting) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/checklist-templates/${checklist_uuid}`, payload);
    dispatch(updateChecklistTemplate());
    toast.success('Updated!');
    history.push('/app-config');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// UPDATE_CHECKLIST_TEMPLATE
export const startUpdateChecklistTemplatesSortOrder = (payload, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/checklist-templates/sort`, payload);
    dispatch(updateChecklistTemplatesSortOrder());
    dispatch(startGetChecklistsTemplate());
    toggle();
    toast.success('Sort Order Updated!');
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// DELETE_CHECKLIST_TEMPLATE
export const startDeleteChecklistTemplate = (checklist_uuid, setSubmitting) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.delete(`${baseURL}/checklist-templates/${checklist_uuid}`);
    dispatch(deleteChecklistTemplate());
    toast.warning('Deleted!');
    history.push('/app-config');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// GET_ALL_CHECKLIST_TEMPLATES
export const startGetAllChecklistTemplates = () => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/checklist-templates/reports`);
    const processed = response.data.map((d) => ({ ...d, options: JSON.stringify(d.options) }));
    dispatch(getAllChecklistTemplates(processed));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// BULK_DOWNLOAD_CHECKLISTS
export const startBulkDownloadChecklists = (payload, setSubmitting, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios({ url: `${baseURL}/checklist-instances/report`, method: 'POST', responseType: 'blob', data: payload });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(bulkDownloadChecklists());
    dispatch(applicantEnd());
    toast.success('Downloaded!');
  } catch (error) {
    toast.error('It looks like something went wrong. Please try again.');
    console.error(error);
    dispatch(applicantEnd());
  }
  toggle();
};



// ///////////////////////////////////
// /////// CHECKLIST INSTANCE ///////
// ///////////////////////////////////

// GET_CHECKLISTS_INSTANCE
export const startGetChecklistsInstance = (user_uuid) => async (dispatch) => {
  dispatch(getChecklistsInstance([]));
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/checklist-instances/${user_uuid}`);
    const processed = response.data.map((d) => ({ ...d, options: JSON.stringify(d.options) }));
    dispatch(getChecklistsInstance(processed));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// UPDATE_CHECKLIST_INSTANCE
export const startUpdateChecklistInstance = (checklist_uuid, user_uuid, payload, setSubmitting, toggle, sticky) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/checklist-instances/${checklist_uuid}`, payload);
    dispatch(updateChecklistInstance());
    dispatch(startGetChecklistsInstance(user_uuid));
    toast.success('Updated!');
    setSubmitting();
    sticky
      ? dispatch(setChecklistSticky({}))
      : toggle();
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// DOWNLOAD CHECKLIST_INSTANCE
export const startDownloadChecklistInstance = (payload, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios({ url: `${baseURL}/checklist-instances/download/${payload.uuid}`, method: 'GET', responseType: 'blob', data: payload });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(downloadChecklistInstance());
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
  toggle();
};

// CREATE_CHECKLIST_INSTANCE
export const startCreateChecklistInstance = (data, toggle) => async (dispatch) => {
  const payload = {
    copy_name: data.copy_name,
    template: data.template,
  };
  dispatch(applicantBegin());
  try {
    const response = await axios.post(`${baseURL}/checklist-instances/${data.user_id}`, payload);
    await dispatch(createChecklistInstance(response.data));
    await dispatch(startGetChecklistsInstance(data.user_id));
    toast.success('Created Checklist!');
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    if (error.response.status === 400) {
      toast.error('Cannot create new instance of deleted template.');
    } else {
      toast.error('It looks like something went wrong. Please try again.');
    }
  }
  toggle();
};



export default slice.reducer;
