import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { applicantBegin, applicantEnd } from './applicants';
import axios from '../../utils/axios';

const initialState = {
  signature_loading: false,
  loading: false,
  responses: [],
  custom_communications: [],
};



const slice = createSlice({
  name: 'communications',
  initialState,
  reducers: {
    // COMMUNICATIONS
    getCommunications: (communications, action) => {
      communications.responses = action.payload;
      communications.loading = false;
    },
    sendCommunications: (communications) => {
      communications.loading = false;
    },
    sendBulkCommunications: (communications) => {
      communications.loading = false;
    },
    getCustomCommunicationTemplates: (communications, action) => {
      communications.custom_communications = action.payload;
      communications.loading = false;
    },
    addCustomCommunicationTemplate: (communications) => {
      communications.loading = false;
    },
    updateCustomCommunicationTemplate: (communications) => {
      communications.loading = false;
    },
    deleteCustomCommunicationTemplate: (communications) => {
      communications.loading = false;
    },
  },
});



export const {
  getCommunications,
  sendCommunications,
  sendBulkCommunications,
  getCustomCommunicationTemplates,
  addCustomCommunicationTemplate,
  updateCustomCommunicationTemplate,
  deleteCustomCommunicationTemplate,
} = slice.actions;



// GET_COMMUNICATIONS
export const startGetCommunications = (userID) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/communications/${userID}`);
    dispatch(getCommunications(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// SEND_COMMUNICATIONS
export const startSendCommunication = (userID, payload, setSubmitting, cb) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.post(`${baseURL}/communications/${userID}`, payload);
    await dispatch(sendCommunications(response.data));
    await dispatch(startGetCommunications(userID));
    cb();
    toast.success('Message Sent!');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// SEND_BULK_COMMUNICATIONS
export const startSendBulkCommunications = (payload, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/communications/bulk`, payload);
    dispatch(sendBulkCommunications());
    toggle();
    toast.success('Sent!');
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// SEND_READ_RECEIPTS
export const startSendReadReceipts = (user_uuid, receipts) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/communications/read/${user_uuid}`, receipts);
    await dispatch(startGetCommunications(user_uuid));
    toast.success('Message(s) marked as read.');
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// UNDO_READ_RECEIPTS
export const startUndoReadReceipts = (user_uuid, receipts) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/communications/undo/${user_uuid}`, receipts);
    await dispatch(startGetCommunications(user_uuid));
    toast.success('Message(s) marked as unread.');
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// GET_CUSTOM_COMMUNICATION_TEMPLATE
export const startGetCustomCommunicationTemplates = () => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/communications_admins`);
    const payload = {
      appConfig: response.data.all_responses.appConfig,
      custom: response.data.all_responses.custom,
    };
    dispatch(getCustomCommunicationTemplates({ all_templates: payload }));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// ADD_CUSTOM_COMMUNICATION_TEMPLATE
export const startAddCustomCommunicationTemplate = (payload, setSubmitting) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/communications_admins`, payload);
    await dispatch(addCustomCommunicationTemplate());
    await dispatch(startGetCustomCommunicationTemplates());
    toast.success('Created!');
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// UPDATE_CUSTOM_COMMUNICATION_TEMPLATE
export const startUpdateCustomCommunicationTemplate = (template, setSubmitting, uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/communications_admins/${uuid}`, template);
    await dispatch(updateCustomCommunicationTemplate());
    await dispatch(startGetCustomCommunicationTemplates());
    toast.success('Updated!');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// DELETE_CUSTOM_COMMUNICATION_TEMPLATE
export const startDeleteCustomCommunicationTemplate = (template) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.delete(`${baseURL}/communications_admins/${template.uuid}`);
    await dispatch(deleteCustomCommunicationTemplate());
    await dispatch(startGetCustomCommunicationTemplates());
    toast.warning('Deleted!');
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};


export default slice.reducer;
