import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { applicantBegin, applicantEnd, startGetApplicant } from './applicants';
import { history } from '../../routers/AppRouter';
import axios from '../../utils/axios';

const initialState = {
  i_records: [],
  i_record: {},
  response_records: [],
  payment_balance: {},
  payment_history: [],
  reservation_limit: {},
  recertification: [],
};



const slice = createSlice({
  name: 'cdf',
  initialState,
  reducers: {
    // I_RECORDS
    getIRecords: (applicants, action) => {
      applicants.i_records = action.payload;
      applicants.loading = false;
    },
    getIRecord: (applicants, action) => {
      applicants.i_record = action.payload;
      applicants.loading = false;
    },
    addIRecord: (applicants) => {
      applicants.loading = false;
    },
    updateIRecord: (applicants) => {
      applicants.loading = false;
    },
    deleteIRecord: (applicants) => {
      applicants.loading = false;
    },
    clearIRecords: (applicants) => {
      applicants.i_records = [];
      applicants.loading = false;
    },
    clearIRecord: (applicants) => {
      applicants.i_record = {};
      applicants.loading = false;
    },
    reactivateIRecord: (applicants) => {
      applicants.loading = false;
    },
    manualReadIRecord: (applicants) => {
      applicants.loading = false;
    },
    manualUnreadIRecord: (applicants) => {
      applicants.loading = false;
    },
    updateIRecordProgram: (applicants) => {
      applicants.loading = false;
    },

    // RESPONSE_RECORDS
    getResponseRecords: (applicants, action) => {
      applicants.response_records = action.payload;
      applicants.loading = false;
    },
    deleteResponseRecord: (applicants) => {
      applicants.loading = false;
    },
    sendQRecord: (applicants) => {
      applicants.loading = false;
    },
    sendGRecord: (applicants) => {
      applicants.loading = false;
    },
    sendARecord: (applicants) => {
      applicants.loading = false;
    },
    sendDRecord: (applicants) => {
      applicants.loading = false;
    },
    sendTRecord: (applicants) => {
      applicants.loading = false;
    },
    toggleBRecordReport: (applicants) => {
      applicants.loading = false;
    },

    // RESERVATIONS
    addReservation: (applicants) => {
      applicants.loading = false;
    },
    updateReservation: (applicants) => {
      applicants.loading = false;
    },
    deleteReservation: (applicants) => {
      applicants.loading = false;
    },
    getReservationLimit: (applicants, action) => {
      applicants.reservation_limit = action.payload;
      applicants.loading = false;
    },

    // PAYMENT
    getPaymentBalance: (applicants, action) => {
      applicants.payment_balance = action.payload;
      applicants.loading = false;
    },
    getPaymentHistory: (applicants, action) => {
      applicants.payment_history = action.payload;
      applicants.loading = false;
    },
    addPayment: (applicants) => {
      applicants.loading = false;
    },
    updatePayment: (applicants) => {
      applicants.loading = false;
    },
    deletePayment: (applicants) => {
      applicants.loading = false;
    },

    // RECERTIFICATION
    getRecertification: (applicants, action) => {
      applicants.recertification = action.payload;
      applicants.loading = false;
    },
    sendApproveRecertification: (applicants) => {
      applicants.loading = false;
    },
    updateRecertification: (applicants) => {
      applicants.loading = false;
    },
  },
});



export const {
  // I_RECORDS
  getIRecords,
  getIRecord,
  addIRecord,
  updateIRecord,
  deleteIRecord,
  clearIRecords,
  clearIRecord,
  reactivateIRecord,
  manualReadIRecord,
  manualUnreadIRecord,
  updateIRecordProgram,

  // RESPONSE_RECORDS
  getResponseRecords,
  deleteResponseRecord,
  sendQRecord,
  sendGRecord,
  sendARecord,
  sendDRecord,
  sendTRecord,
  toggleBRecordReport,

  // RESERVATIONS
  addReservation,
  updateReservation,
  deleteReservation,
  getReservationLimit,

  // PAYMENT
  getPaymentBalance,
  getPaymentHistory,
  addPayment,
  updatePayment,
  deletePayment,

  // RECERTIFICATION
  getRecertification,
  sendApproveRecertification,
  updateRecertification,

} = slice.actions;



// ///////////////////////////////////
// ///////////// I RECORD ////////////
// ///////////////////////////////////

// GET_I_RECORDS
export const startGetIRecords = (user_uuid) => async (dispatch) => {
  dispatch(clearIRecords());
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/irecords/${user_uuid}`);
    dispatch(getIRecords(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// GET_I_RECORD
export const startGetIRecord = (user_uuid, record_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/irecords/${user_uuid}/${record_uuid}`);
    dispatch(getIRecord(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// ADD_I_RECORD
export const startAddIRecord = (payload, setSubmitting, toggle, user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/irecords/${user_uuid}`, payload);
    await dispatch(addIRecord());
    await dispatch(startGetIRecords(user_uuid));
    toggle();
    toast.success('Created!');
  } catch (error) {
    if (error.response && error.response.status === 403) {
      toast.error('An I Record for this Program type and Loan already exists. Please terminate the existing record if you wish to proceed.');
    } else if (error.response?.status === 400) {
      toast.error(error.response.data.message);
    } else {
      toast.error('It looks like something went wrong. Please try again.');
    }
    console.error(error.response);
    setSubmitting();
    dispatch(applicantEnd());
  }
};



// UPDATE_I_RECORD
export const startUpdateIRecord = (i_record_id, payload, setSubmitting, toggle, applicant_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/irecords/edit/${i_record_id}`, payload);
    await dispatch(updateIRecord());
    await dispatch(startGetIRecord(applicant_uuid, i_record_id));
    toggle();
    toast.success('Updated!');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// DELETE_I_RECORD
export const startDeleteIRecord = (i_record_id, user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.delete(`${baseURL}/irecords/${i_record_id}`);
    await dispatch(deleteIRecord());
    dispatch(startGetIRecords(user_uuid));
    toast.warning('Canceled!');
    history.push(`/applicants/${user_uuid}/records`);
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// REACTIVATE_I_RECORD
export const startReactivateIRecord = (uuid, user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/irecords/${uuid}`);
    await dispatch(deleteIRecord());
    await dispatch(startGetIRecord(user_uuid, uuid));
    toast.success('Success!');
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again');
    dispatch(applicantEnd());
  }
};



// MANUAL_READ_I_RECORD
export const startManualReadIRecord = (uuid, user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/irecords/read/${uuid}`);
    await dispatch(manualReadIRecord());
    await dispatch(startGetIRecord(user_uuid, uuid));
    toast.success('Marked as Seen!');
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again');
    dispatch(applicantEnd());
  }
};


// MANUAL_UNREAD_I_RECORD
export const startManualUnreadIRecord = (uuid, user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/irecords/undo/${uuid}`);
    await dispatch(manualUnreadIRecord());
    await dispatch(startGetIRecord(user_uuid, uuid));
    toast.success('Marked as Unseen!');
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again');
    dispatch(applicantEnd());
  }
};



// UPDATE_I_RECORD_PROGRAM
export const startUpdateIRecordProgram = (program_uuid, payload, setSubmitting, toggle, user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/irecords/program-swap/${program_uuid}`, payload);
    await dispatch(updateIRecordProgram());
    await dispatch(startGetIRecords(user_uuid));
    toggle();
    toast.success('Program Updated!');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    if (error.response.status === 400) {
      toast.error(error.response.data.message);
    } else {
      toast.error('It looks like something went wrong. Please try again.');
    }
    dispatch(applicantEnd());
  }
};



// //////////////////////////////////////////
// ///////////// RESPONSE RECORD ////////////
// //////////////////////////////////////////

// GET_RESPONSE_RECORDS
export const startGetResponseRecords = (i_record_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/response-records/${i_record_uuid}`);
    dispatch(getResponseRecords(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// DELETE_RESPONSE_RECORD
export const startDeleteResponseRecord = (response_record_id, i_record_uuid, user_uuid, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.delete(`${baseURL}/response-records/${response_record_id}`);
    await dispatch(deleteResponseRecord());
    await dispatch(startGetResponseRecords(i_record_uuid));
    await dispatch(startGetIRecord(user_uuid, i_record_uuid));
    toast.warning('Canceled!');
    toggle();
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// SEND_Q_RECORD
export const startSendQRecord = (toggle, user_uuid, i_record_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/response-records/${user_uuid}/${i_record_uuid}/q`);
    await dispatch(sendQRecord());
    await dispatch(startGetResponseRecords(i_record_uuid));
    await dispatch(startGetIRecord(user_uuid, i_record_uuid));
    toggle();
    toast.success('Success!');
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// SEND_G_RECORD
export const startSendGRecord = (toggle, user_uuid, i_record_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/response-records/${user_uuid}/${i_record_uuid}/g`);
    await dispatch(sendGRecord());
    await dispatch(startGetResponseRecords(i_record_uuid));
    await dispatch(startGetIRecord(user_uuid, i_record_uuid));
    toggle();
    toast.success('Success!');
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again');
  }
};



// SEND_A_RECORD
export const startSendARecord = (toggle, user_uuid, i_record_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/response-records/${user_uuid}/${i_record_uuid}/a`);
    await dispatch(sendARecord());
    await dispatch(startGetResponseRecords(i_record_uuid));
    await dispatch(startGetIRecord(user_uuid, i_record_uuid));
    toggle();
    toast.success('Success!');
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// SEND_D_RECORD
export const startSendDRecord = (reason, toggle, user_uuid, i_record_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const payload = {
      reason,
    };
    await axios.post(`${baseURL}/response-records/${user_uuid}/${i_record_uuid}/d`, payload);
    await dispatch(sendDRecord());
    await dispatch(startGetResponseRecords(i_record_uuid));
    await dispatch(startGetIRecord(user_uuid, i_record_uuid));
    toggle();
    toast.success('Success!');
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// SEND_T_RECORD
export const startSendTRecord = (reason, toggle, user_uuid, i_record_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const payload = {
      reason,
    };
    await axios.post(`${baseURL}/response-records/${user_uuid}/${i_record_uuid}/t`, payload);
    await dispatch(sendTRecord());
    await dispatch(startGetResponseRecords(i_record_uuid));
    await dispatch(startGetIRecord(user_uuid, i_record_uuid));
    toggle();
    toast.success('Success!');
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};


// TOGGLE_B_RECORD_REPORT
export const startToggleBRecordReport = ({ response_record_uuid, payload, toggle, setSubmitting, user_uuid, i_record_uuid }) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/response-records/${response_record_uuid}/toggle-b`, payload);
    await dispatch(toggleBRecordReport());
    await dispatch(startGetResponseRecords(i_record_uuid));
    await dispatch(startGetIRecord(user_uuid, i_record_uuid));
    toggle();
    toast.success('Success!');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// ///////////////////////////////////
// ///////////// RESERVATION ////////////
// ///////////////////////////////////

// ADD_RESERVATION
export const startAddReservation = (payload, setSubmitting, toggle, user_uuid, i_record_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/reservations/${user_uuid}/${i_record_uuid}`, payload);
    await dispatch(addReservation());
    await dispatch(startGetIRecord(user_uuid, i_record_uuid));
    toggle();
    toast.success('Created!');
  } catch (error) {
    console.error(error.response);
    if (error.response.status === 400) {
      toast.error(error.response.data.message);
    } else {
      toast.error('It looks like something went wrong. Please try again.');
    }
    setSubmitting();
    dispatch(applicantEnd());
  }
};



// UPDATE_RESERVATION
export const startUpdateReservation = (reservation_id, payload, setSubmitting, toggle, user_uuid, i_record_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/reservations/${reservation_id}`, payload);
    await dispatch(updateReservation());
    await dispatch(startGetIRecord(user_uuid, i_record_uuid));
    toggle();
    toast.success('Updated!');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    if (error.response.status === 400) {
      toast.error(error.response.data.message);
    } else {
      toast.error('It looks like something went wrong. Please try again.');
    }
    dispatch(applicantEnd());
  }
};



// DELETE_RESERVATION
export const startDeleteReservation = (reservation_id, toggle, user_uuid, i_record_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.delete(`${baseURL}/reservations/${reservation_id}`);
    await dispatch(deleteReservation());
    await dispatch(startGetIRecord(user_uuid, i_record_uuid));
    toggle();
    toast.warning('Deleted!');
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// GET_RESERVATION_LIMIT
export const startGetReservationLimit = (program_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/programs/reservation-limit/${program_uuid}`);
    await dispatch(getReservationLimit(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// ///////////////////////////////////
// ///////////// PAYMENT ////////////
// ///////////////////////////////////

// GET_PAYMENT_BALANCE
export const startGetPaymentBalance = (user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/payments/balance/${user_uuid}`);
    dispatch(getPaymentBalance(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// ADD_PAYMENT
export const startAddPayment = (payload, setSubmitting, toggle, user_uuid, i_record_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/payments/${user_uuid}`, payload);
    await dispatch(addPayment());
    await dispatch(startGetIRecord(user_uuid, i_record_uuid));
    toggle();
    toast.success('Created!');
  } catch (error) {
    if (error.response.status === 409) {
      toast.error(error.response.data.message);
    }
    toggle();
    console.error(error.response);
    setSubmitting();
    dispatch(applicantEnd());
  }
};



// UPDATE_PAYMENT
export const startUpdatePayment = (payment_uuid, payload, setSubmitting, toggle, user_uuid, i_record_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/payments/${payment_uuid}/${user_uuid}`, payload);
    await dispatch(updatePayment());
    await dispatch(startGetIRecord(user_uuid, i_record_uuid));
    toggle();
    toast.success('Updated!');
  } catch (error) {
    if (error.response.status === 409) {
      toast.error(error.response.data.message);
    }
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// DELETE_PAYMENT
export const startDeletePayment = (payment_uuid, toggle, user_uuid, i_record_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.delete(`${baseURL}/payments/${payment_uuid}`);
    await dispatch(deletePayment());
    await dispatch(startGetIRecord(user_uuid, i_record_uuid));
    toggle();
    toast.warning('Deleted!');
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// ///////////////////////////////////
// //////// RECERTIFICATION /////////
// ///////////////////////////////////

// GET_RECERTIFICATION
export const startGetRecertification = (user_uuid) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/recertification/${user_uuid}`);
    dispatch(getRecertification(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// SEND_APPROVE_RECERTIFICATION
export const startSendApproveRecertification = (user_uuid, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/recertification/${user_uuid}`);
    await dispatch(sendApproveRecertification());
    await dispatch(startGetRecertification(user_uuid));
    await dispatch(startGetApplicant(user_uuid));
    toggle();
  } catch (error) {
    if (error.response.data?.message === 'Exceeded recertification cap') {
      toast.error('Fail to approve. Recertification Cap Reached.');
    }
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// UPDATE_RECERTIFICATION
export const startUpdateRecertification = (recert_uuid, user_uuid, payload, toggle, setSubmitting) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/recertification/${recert_uuid}`, payload);
    await dispatch(updateRecertification());
    await dispatch(startGetRecertification(user_uuid));
    toggle();
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



export default slice.reducer;
