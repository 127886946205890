import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { Can, Icon } from 'bumblebee-ui';
import { useSelector, useDispatch } from 'react-redux';
import { formatUTCDateForHTML } from 'wrkrb';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { useToggle } from '../../../hooks';
import {
  startDownloadChecklistInstance,
  setStickyChecklist,
  setChecklistSticky,
} from '../../../redux/reducers/checklists';
import CreateChecklistInstance from '../../applicants/tabs/modals/CreateChecklistInstance';


const ChecklistHeader = ({ config, sticky = false, toggle, isOpen }) => {
  const dispatch = useDispatch();
  const [isLoading, toggleLoading] = useToggle();
  const [dismissDropdown, setDismissDropdown] = useState(false);
  const [isCreateOpen, toggleCreate] = useToggle(false);

  const processed_options = JSON.parse(config.options);
  const { is_complete, completed_at, completed_by } = processed_options;
  const [latestChecklistItem] = processed_options.payload
    .filter((d) => d.completed_at)
    .sort((a, b) => Date.parse(b.completed_at) - Date.parse(a.completed_at));
  const permissions = useSelector((state) => state.auth.currentUser.permissions);

  return (
    <Fragment>
      <div className='d-flex flex-wrap justify-content-between my-2'>
        {!sticky && <h5 className='col-auto my-auto'>{config.name}</h5>}

        <div className={`${((sticky && is_complete) || (sticky && latestChecklistItem))
          ? 'col-12 d-flex justify-content-between align-items-center'
          : (!is_complete && sticky)
            ? 'col-12 d-flex justify-content-end align-items-center'
            : 'col-auto d-flex align-items-center'}`}>

          {is_complete &&
            <div className='d-inline my-auto'>
              <span className='text-success f-bold pr-2'>
                <Icon name='check' className='pr-2' />
                <span className='d-md-inline-block d-none'>Completed</span>
              </span>
              <span className='mr-3 d-xl-inline-block d-none text-muted'>{`${completed_by}, ${formatUTCDateForHTML(completed_at, true)}`}</span>
            </div>}

          {(!is_complete && latestChecklistItem) &&
            <div className='d-inline my-auto'>
              <span className='text-muted f-bold pr-2'>
                <Icon name='check' className='pr-2' />
                <span className='d-md-inline-block d-none'>In Progress</span>
              </span>
              <span className='mr-3 d-xl-inline-block d-none text-muted'>{`${latestChecklistItem.completed_by}, ${formatUTCDateForHTML(latestChecklistItem.completed_at, true)}`}</span>
            </div>}

          {!sticky &&
            <>
              <button className='btn btn-sm d-lg-block d-none mx-1 text-link' onClick={() => {
                toggleLoading();
                dispatch(startDownloadChecklistInstance(config, toggleLoading));
              }}>
                {isLoading ? <span className='fas fa-spinner fa-spin mr-2' /> : <span className='fas fa-download mr-2' />}
                PDF
              </button><Can
                permissions={permissions}
                perform={'applicant-checklist:clone'}
                yes={() => (
                  <button className='btn btn-sm d-lg-block d-none mx-1 text-link' onClick={toggleCreate}>
                    <span className='fas fa-copy mr-2' />
                    Make a Copy
                  </button>
                )} />
              <button className='btn btn-sm d-lg-block d-none mx-1 text-link' onClick={() => {
                dispatch(setStickyChecklist(true));
                dispatch(setChecklistSticky(config));
              }}>
                <span className='fas fa-window-restore mr-2' />
                Pop Out
              </button>

              <div className='d-block d-lg-none'>
                <Dropdown
                  isOpen={dismissDropdown === config.uuid}
                  toggle={() => {
                    if (dismissDropdown === config.uuid) {
                      setDismissDropdown(false);
                    } else {
                      setDismissDropdown(config.uuid);
                    }
                  }}
                >
                  <DropdownToggle
                    className='btn btn-outline-primary btn-sm'
                    tag='button'
                    data-cy={`dismissDropdownBtn-${config.name}`}
                  >
                    <span className='fas fa-ellipsis' />
                  </DropdownToggle>

                  <DropdownMenu right className='p-0'>
                    <DropdownItem
                      data-cy={`dismissDropdown-${config.name}`}
                      onClick={() => {
                        toggleLoading();
                        dispatch(startDownloadChecklistInstance(config, toggleLoading));
                      }}
                    >
                      {isLoading ? <span className='fas fa-spinner fa-spin mr-2' /> : <span className='fas fa-download mr-2' />}
                      PDF
                    </DropdownItem>
                    <Can
                      permissions={permissions}
                      perform={'applicant-checklist:clone'}
                      yes={() => (
                        <DropdownItem
                          data-cy={`dismissDropdown${config.uuid}`}
                          onClick={toggleCreate}
                        >
                          <span className='fas fa-copy mr-2' />
                          Make a Copy
                        </DropdownItem>
                      )} />
                    <DropdownItem
                      data-cy={`dismissDropdown${config.uuid}`}
                      onClick={() => {
                        dispatch(setStickyChecklist(true));
                        dispatch(setChecklistSticky(config));
                      }}
                    >
                      <span className='fas fa-window-restore mr-2' />
                      Pop Out
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </>
          }

          {(!sticky && !is_complete) &&
            <Fragment>
              {isOpen
                ? <button className='btn btn-sm mx-1 text-link' onClick={toggle}>Collapse</button>
                : <button className='btn btn-primary btn-sm mx-1 d-flex' onClick={toggle}>
                  <span className='fas fa-edit mr-2' />
                  Fill out
                </button>}
            </Fragment>}

          {(!sticky && is_complete) &&
            <Can
              permissions={permissions}
              perform={'applicant-checklist:update'}
              yes={() => (isOpen
                ? <button className='btn btn-sm mx-1 text-link' onClick={toggle}>Collapse</button>
                : <button className='btn btn-outline-primary btn-sm mx-1 d-flex' onClick={toggle}>
                  <span className='fas fa-edit mr-2' />
                  Edit
                </button>
              )}
              no={() => (isOpen
                ? <button className='btn btn-sm mx-1 text-link' onClick={toggle}>Collapse</button>
                : <button className='btn btn-sm mx-1 text-link' onClick={toggle}>View</button>
              )} />}
        </div>

      </div>
      <CreateChecklistInstance
        isOpen={isCreateOpen}
        toggle={toggleCreate}
        config={config} />
    </ Fragment>
  );
};

ChecklistHeader.propTypes = {
  config: PropTypes.object,
  sticky: PropTypes.bool,
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default ChecklistHeader;
