import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import axios from '../../utils/axios';
import { applicantBegin, applicantEnd } from './applicants';

const initialState = {
  active: [],
  inactive: [],
  loading: {
    template: false,
    identity: false,
    income: false,
    property: false,
    hardship: false,
    internal: false,
    recertification: false,
    signatures: false,
    onboarding: false,
    select: false,
  },
  document_note_templates: [],
};



const slice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    documentsBegin: (documents, action) => {
      if (typeof documents.loading === 'object') {
        documents.loading[action.payload] = true;
      }
    },
    documentsEnd: (documents) => {
      documents.loading = initialState.loading;
    },

    getTemplateDocuments: (documents) => {
      documents.loading = initialState.loading;
    },
    downloadTemplateDocument: (documents) => {
      documents.loading = initialState.loading;
    },
    downloadGrantLetter: (documents) => {
      documents.loading = initialState.loading;
    },
    downloadGrantLetterTemplate: (documents) => {
      documents.loading = initialState.loading;
    },
    downloadOnboardingFile: (documents) => {
      documents.loading = initialState.loading;
    },

    getDocuments: (documents, action) => {
      documents.active = action.payload.active;
      documents.inactive = action.payload.inactive;
      documents.loading = initialState.loading;
    },
    updateDocument: (documents) => {
      documents.loading = initialState.loading;
    },
    uploadDocument: (documents) => {
      documents.loading = initialState.loading;
    },
    deleteDocument: (documents) => {
      documents.loading = initialState.loading;
    },
    downloadDocument: (documents) => {
      documents.loading = initialState.loading;
    },
    downloadSelectedDocuments: (documents) => {
      documents.loading = initialState.loading;
    },
    restoreDocument: (documents) => {
      documents.loading = initialState.loading;
    },
    getNoteTemplates: (documents, action) => {
      documents.document_note_templates = action.payload;
      documents.loading = initialState.loading;
    },
    addCustomNoteTemplate: (documents) => {
      documents.loading = initialState.loading;
    },
    updateCustomNoteTemplate: (documents) => {
      documents.loading = initialState.loading;
    },
    deleteNoteTemplate: (documents) => {
      documents.loading = initialState.loading;
    },
  },
});



export const {
  documentsBegin,
  documentsEnd,

  getTemplateDocuments,
  downloadTemplateDocument,
  downloadGrantLetter,
  downloadGrantLetterTemplate,
  downloadOnboardingFile,

  getDocuments,
  updateDocument,
  uploadDocument,
  deleteDocument,
  downloadDocument,
  downloadSelectedDocuments,
  restoreDocument,
  getNoteTemplates,
  addCustomNoteTemplate,
  updateCustomNoteTemplate,
  deleteNoteTemplate,
} = slice.actions;



// GET_TEMPLATE_DOCUMENT
export const startGetTemplateDocument = (code) => async (dispatch) => {
  dispatch(documentsBegin('template'));
  try {
    const response = await axios({ url: `${baseURL}/document-templates/${code}`, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(getTemplateDocuments());
  } catch (error) {
    console.error(error.response);
    dispatch(documentsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// GET_DOCUMENTS
export const startGetDocuments = (user_uuid) => async (dispatch) => {
  dispatch(documentsBegin('template'));
  try {
    const response = await axios.get(`${baseURL}/documents/${user_uuid}`);
    dispatch(getDocuments(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(documentsEnd());
  }
};



// GET_DOCUMENTS
export const startGetInternalDocuments = (user_uuid) => async (dispatch) => {
  dispatch(getDocuments({ active: [], inactive: [] }));
  dispatch(documentsBegin('internal'));
  try {
    const response = await axios.get(`${baseURL}/documents/internal/${user_uuid}`);
    dispatch(getDocuments({ active: response.data, inactive: [] }));
  } catch (error) {
    console.error(error.response);
    dispatch(documentsEnd());
  }
};



// UPDATE_DOCUMENT
export const startUpdateDocument = (uuid, user_uuid, payload, type) => async (dispatch) => {
  dispatch(documentsBegin(type));
  try {
    await axios.put(`${baseURL}/documents/${uuid}/${user_uuid}`, payload);
    await dispatch(updateDocument());
    await dispatch(startGetDocuments(user_uuid));
    toast.success('Document Updated');
  } catch (error) {
    console.error(error.response);
    dispatch(documentsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// UPLOAD_DOCUMENT
export const startUploadDocument = (file, user_uuid, type, afr_uuid) => async (dispatch) => {
  dispatch(documentsBegin(type));
  try {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    const formData = new FormData();
    formData.append('document', file);

    let url = `${baseURL}/documents/upload/${type}/${user_uuid}`;
    if (afr_uuid) url += `?afr_id=${afr_uuid}`;

    await axios.post(url, formData, config);
    await dispatch(uploadDocument());
    await dispatch(startGetDocuments(user_uuid));
    toast.success('Document Uploaded');
  } catch (error) {
    console.error(error.response);
    dispatch(documentsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// DOWNLOAD_DOCUMENT
export const startDownloadDocument = (document_uuid, applicant_uuid, type) => async (dispatch) => {
  dispatch(documentsBegin(type));
  try {
    const response = await axios({ url: `${baseURL}/documents/download/${document_uuid}/${applicant_uuid}`, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(downloadDocument());
  } catch (error) {
    console.error(error.response);
    dispatch(documentsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



export const startDownloadSelectedDocuments = (documents, applicant_uuid) => async (dispatch) => {
  dispatch(documentsBegin('select'));
  const payload = {
    documents: documents.map((d) => d.uuid),
    applicant_uuid,
  };
  try {
    const response = await axios({ url: `${baseURL}/documents/download/bulk`, method: 'POST', responseType: 'blob', data: payload });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(downloadSelectedDocuments());
  } catch (error) {
    console.error(error.response);
    dispatch(documentsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// DELETE_DOCUMENT
export const startDeleteDocument = (document_uuid, user_uuid, toggle, type) => async (dispatch) => {
  dispatch(documentsBegin(type));
  try {
    await axios.delete(`${baseURL}/documents/${document_uuid}`);
    await dispatch(deleteDocument());
    await dispatch(startGetDocuments(user_uuid));
    toggle();
    toast.warning('Document Deleted!');
  } catch (error) {
    console.error(error.response);
    dispatch(documentsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// RESTORE_DOCUMENT
export const startRestoreDocument = (document_uuid, user_uuid, type) => async (dispatch) => {
  dispatch(documentsBegin(type));
  try {
    await axios.put(`${baseURL}/documents/${document_uuid}`);
    await dispatch(restoreDocument());
    await dispatch(startGetDocuments(user_uuid));
    toast.success('Document Restored!');
  } catch (error) {
    console.error(error.response);
    dispatch(documentsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// DOWNLOAD_ONBOARDING_FILE
export const startDownloadOnboardingFile = (user_uuid, i_record_uuid) => async (dispatch) => {
  dispatch(documentsBegin('onboarding'));
  try {
    const response = await axios({ url: `${baseURL}/response-records/onboarding/${user_uuid}/${i_record_uuid}`, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(downloadOnboardingFile());
  } catch (error) {
    console.error(error.response);
    dispatch(documentsEnd());
  }
};



// GET_CUSTOM_DOCUMENT_NOTE_TEMPLATE
export const startGetNoteTemplates = () => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    const response = await axios.get(`${baseURL}/document-notes-admins`);
    const payload = {
      appConfig: response.data.all_responses.appConfig,
      custom: response.data.all_responses.custom,
    };
    dispatch(getNoteTemplates({ all_responses: payload }));
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
  }
};



// ADD_CUSTOM_DOCUMENT_NOTE_TEMPLATE
export const startAddCustomNoteTemplate = (payload, setSubmitting, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.post(`${baseURL}/document-notes-admins`, payload);
    await dispatch(addCustomNoteTemplate());
    await dispatch(startGetNoteTemplates());
    toast.success('Custom Note Created!');
    toggle();
  } catch (error) {
    console.error(error.response);
    setSubmitting();
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// UPDATE_CUSTOM_DOCUMENT_NOTE_TEMPLATE
export const startUpdateCustomNoteTemplate = (payload, setSubmitting, uuid, toggle) => async (dispatch) => {
  dispatch(applicantBegin());
  try {
    await axios.put(`${baseURL}/document-notes-admins/${uuid}`, payload);
    await dispatch(updateCustomNoteTemplate());
    await dispatch(startGetNoteTemplates());
    toast.success('Custom Note Updated!');
    toggle();
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// DELETE_CUSTOM_DOCUMENT_NOTE_TEMPLATE
export const startDeleteCustomNoteTemplate = (uuid, toggle) => async (dispatch) => {
  try {
    await axios.delete(`${baseURL}/document-notes-admins/${uuid}`);
    await dispatch(deleteNoteTemplate());
    await dispatch(startGetNoteTemplates());
    toast.warning('Custom Note Deleted!');
    toggle();
  } catch (error) {
    console.error(error.response);
    dispatch(applicantEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};


export default slice.reducer;
