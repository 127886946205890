import { createSlice } from '@reduxjs/toolkit';
import ls from 'local-storage';
import { toast } from 'react-toastify';

import { startGetConfigs, clearConfigs } from '../reducers/config';
import { history } from '../../routers/AppRouter';
import axios from '../../utils/axios';

const initialState = {
  currentUser: {},
  registerToken: {},
  permissions: [],
  permission_groups: [],
  pg_permissions: [],
  admins: [],
  isLoggedIn: false,
  isPWResetTokenValid: false,
  loading: false,
  journal_logins_self: [],
  journal_logins_by_id: [],
};



const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authBegin: (auth) => {
      auth.loading = true;
    },
    authEnd: (auth) => {
      auth.loading = false;
    },

    /* AUTH */
    login: (auth, action) => {
      auth.currentUser = action.payload || {};
      auth.registerToken = {};
      auth.isLoggedIn = true;
      auth.loading = false;
    },
    logout: (auth) => {
      auth.currentUser = {};
      auth.isLoggedIn = false;
      auth.loading = false;
    },
    getRegisterToken: (auth, action) => {
      auth.registerToken = action.payload;
      auth.loading = false;
    },
    sendForgotPasswordEmail: (auth) => {
      auth.loading = false;
    },
    resetPasswordValid: (auth, action) => {
      auth.isPWResetTokenValid = action.payload;
      auth.loading = false;
    },
    resetPassword: () => {
      const payload = initialState;
      return payload;
    },
    inviteAdmin: (auth) => {
      auth.loading = false;
    },

    /* REPORT FRAUD */
    sendReportFraud: (auth) => {
      auth.loading = false;
    },

    /* PERMISSION GROUPS */
    getPermissions: (auth, action) => {
      auth.permissions = action.payload;
      auth.loading = false;
    },
    getPermissionGroups: (auth, action) => {
      auth.permission_groups = action.payload;
      auth.loading = false;
    },
    createPermissionGroup: (auth) => {
      auth.loading = false;
    },
    updatePermissionGroup: (auth) => {
      auth.loading = false;
    },
    deletePermissionGroup: (auth) => {
      auth.loading = false;
    },
    getPGPermissionGroup: (auth, action) => {
      auth.pg_permissions = action.payload;
      auth.loading = false;
    },
    exportPermissions: (auth) => {
      auth.loading = false;
    },

    /* ADMINS */
    getAdmins: (auth, action) => {
      auth.admins = action.payload;
      auth.loading = false;
    },
    updateAdmin: (auth) => {
      auth.loading = false;
    },
    deleteAdmin: (auth) => {
      auth.loading = false;
    },

    /* JOURNAL LOGINS */
    getJournalLoginsSelf: (auth, action) => {
      auth.journal_logins_self = action.payload;
      auth.loading = false;
    },
    getJournalLoginsByID: (auth, action) => {
      auth.journal_logins_by_id = action.payload;
      auth.loading = false;
    },
    logoutByDevice: (auth) => {
      auth.loading = false;
    },
    logoutAllDevices: (auth) => {
      auth.loading = false;
    },
  },
});



export const {
  authBegin,
  authEnd,

  /* AUTH */
  login,
  logout,
  getRegisterToken,
  sendForgotPasswordEmail,
  resetPasswordValid,
  resetPassword,
  inviteAdmin,

  /* REPORT FRAUD */
  sendReportFraud,

  /* PERMISSION GROUPS */
  getPermissions,
  getPermissionGroups,
  createPermissionGroup,
  updatePermissionGroup,
  deletePermissionGroup,
  getPGPermissionGroup,
  exportPermissions,

  /* ADMINS */
  getAdmins,
  updateAdmin,
  deleteAdmin,

  /* JOURNAL LOGINS */
  getJournalLoginsSelf,
  getJournalLoginsByID,
  logoutByDevice,
  logoutAllDevices,
} = slice.actions;



// LOGIN
export const startLogin = ({ payload, setSubmitting, deepLink, navigate }) => async (dispatch) => {
  dispatch(authBegin());
  try {
    const response = await axios.post(`${baseURL}/login`, payload);
    const { refresh_tos, user } = response.data;
    ls('user', user);
    await dispatch(login(user));
    await dispatch(startGetConfigs());

    deepLink
      ? navigate(deepLink)
      : navigate('/account');
  } catch (error) {
    let isToast = true;
    let toastMessage = 'It looks like something went wrong. Please try again.';

    if (error.response?.status === 409) {
      isToast = false;
      ls('email', payload.email);

      deepLink
        ? navigate('/two-factor', { state: { deepLink } })
        : navigate('/two-factor');
    }

    if (error.response?.status === 423) {
      toastMessage = 'Too many failed login attempts.  Please try again later.';
      navigate('/');
    }

    if (isToast) toast.error(toastMessage);
    console.error(error.response);
    dispatch(authEnd());
    setSubmitting();
  }
};



// REGISTER_TOKEN
export const startGetRegisterToken = (token) => async (dispatch) => {
  dispatch(authBegin());
  try {
    const response = await axios.get(`${baseURL}/register/${token}`);
    await dispatch(getRegisterToken(response.data));
  } catch (error) {
    if (error.response.status === 400) {
      dispatch(getRegisterToken({ isInvalid: true }));
    }
    console.error(error.response);
    dispatch(authEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// REGISTER
export const startRegister = (payload, setSubmitting) => async (dispatch) => {
  dispatch(authBegin());
  try {
    await axios.post(`${baseURL}/register`, payload);
  } catch (error) {
    let isToast = true;
    const toastMessage = 'It looks like something went wrong. Please try again.';

    if (error.response?.status === 409) {
      isToast = false;
      ls('email', payload.email);
      history.push('/two-factor');
    }

    if (isToast) toast.error(toastMessage);
    console.error(error.response);
    dispatch(authEnd());
    setSubmitting();
  }
};



// VALIDATE SESSION
export const startValidateSession = (values, setSubmitting, deepLink) => async (dispatch) => {
  dispatch(authBegin());
  try {
    const response = await axios.post(`${baseURL}/validate/session`, values);
    if (response.data.user) {
      const { refresh_tos, user } = response.data;
      ls('user', user);
      ls.remove('email');
      await dispatch(login(user));
      await dispatch(startGetConfigs());
      deepLink
        ? history.push(deepLink)
        : history.push('/account');
    } else {
      toast.warn('Something went wrong, Resend a new code');
      setSubmitting();
    }
  } catch (error) {
    console.error(error.response);
    dispatch(authEnd());
    setSubmitting();
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// RESEND_TWO_FACTOR
export const startResendTwoFactor = () => async (dispatch) => {
  dispatch(authBegin());
  try {
    const email = ls('email');
    await axios.post(`${baseURL}/resend/mfa`, { email });
    toast.success('New Code Sent');
  } catch (error) {
    console.error(error.response);
    dispatch(authEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// LOGOUT
export const startLogout = () => async (dispatch) => {
  dispatch(authBegin());
  try {
    await axios.get(`${baseURL}/logout`);
    ls.remove('user');
    ls.remove('page-has-been-force-refreshed');
    ls.remove('config');
    dispatch(clearConfigs());
    dispatch(logout());
    history.push('/');
  } catch (error) {
    console.error(error.response);
    ls.clear();
    dispatch(clearConfigs());
    dispatch(logout());
    history.push('/');
    toast.error('It looks like something went wrong.');
  }
};



// SEND_FORGOT_PASSWORD_EMAIL
export const startSendForgotPasswordEmail = (payload, setSubmitting, cb) => async (dispatch) => {
  dispatch(authBegin());
  try {
    await axios.post(`${baseURL}/reset-password-email`, payload);
    setSubmitting();
    dispatch(sendForgotPasswordEmail());
    cb();
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(authEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// RESET_PASSWORD_VALID
export const startValidateToken = (token) => async (dispatch) => {
  dispatch(authBegin());
  try {
    const response = await axios.get(`${baseURL}/reset-password/${token}`);
    dispatch(resetPasswordValid(response.data.isValid));
  } catch (error) {
    console.error(error.response);
    dispatch(authEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// RESET_PASSWORD
export const startResetPassword = (payload, setSubmitting) => async (dispatch) => {
  dispatch(authBegin());
  try {
    await axios.post(`${baseURL}/reset-password`, payload);
    dispatch(resetPassword());
    ls.clear();
    toast.success('Password updated. Please log back in.');
    history.push('/login');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(authEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// INVITE_ADMIN
export const startExportAdmins = () => async (dispatch) => {
  dispatch(authBegin());
  try {
    const response = await axios({ url: `${baseURL}/admins/export`, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
  }
  dispatch(authEnd());
};



// INVITE_ADMIN
export const startInviteAdmin = (payload, setSubmitting) => async (dispatch) => {
  dispatch(authBegin());
  try {
    await axios.post(`${baseURL}/invite`, payload);
    dispatch(inviteAdmin());
    toast.success(`Thank you. Your invite has been sent to ${payload.email}.`);
    history.push('/');
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(authEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// GET_PERMISSION_GROUPS
export const startGetPermissions = () => async (dispatch) => {
  dispatch(authBegin());
  try {
    const response = await axios.get(`${baseURL}/permissions`);
    dispatch(getPermissions(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(authEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// GET_PERMISSION_GROUPS
export const startGetPermissionGroups = () => async (dispatch) => {
  dispatch(authBegin());
  try {
    const response = await axios.get(`${baseURL}/permission-groups`);
    dispatch(getPermissionGroups(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(authEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// CREATE_PERMISSION_GROUP
export const startCreatePermissionGroup = (name, relationships) => async (dispatch) => {
  dispatch(authBegin());
  try {
    const payload = { name, relationships };
    await axios.post(`${baseURL}/permission-groups`, payload);
    await dispatch(createPermissionGroup());
    await dispatch(startGetPermissionGroups());
    toast.success('Permission Group Created');
  } catch (error) {
    console.error(error.response);
    dispatch(authEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// UPDATE_PERMISSION_GROUP
export const startUpdatePermissionGroup = (currentGroup, relationships) => async (dispatch) => {
  dispatch(authBegin());
  try {
    const payload = { name: currentGroup.name, relationships };
    await axios.put(`${baseURL}/permission-groups/${currentGroup.id}`, payload);
    await dispatch(updatePermissionGroup());
    await dispatch(startGetPermissionGroups());
    toast.success('Permission Group Updated');
  } catch (error) {
    console.error(error.response);
    dispatch(authEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// DELETE_PERMISSION_GROUP
export const startDeletePermissionGroup = (id, toggle) => async (dispatch) => {
  dispatch(authBegin());
  try {
    await axios.delete(`${baseURL}/permission-groups/${id}`);
    await dispatch(deletePermissionGroup());
    await dispatch(startGetPermissionGroups());
    toast.warning('Permission Group Deleted');
    toggle();
  } catch (error) {
    console.error(error.response);
    dispatch(authEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// GET_PG_PERMISSIONS
export const startGetPGPermissions = (id) => async (dispatch) => {
  dispatch(authBegin());
  try {
    const response = await axios.get(`${baseURL}/permission-groups/${id}`);
    const pg_permissions = response.data.map((d) => d.name);
    dispatch(getPGPermissionGroup(pg_permissions));
  } catch (error) {
    console.error(error.response);
    dispatch(authEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// EXPORT_PERMISSIONS
export const startExportPermissions = () => async (dispatch) => {
  dispatch(authBegin());
  try {
    const response = await axios({ url: `${baseURL}/permissions/export`, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(exportPermissions(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(authEnd());
  }
};



// GET_ADMINS_ALL
export const startGetAdminsAll = () => async (dispatch) => {
  dispatch(authBegin());
  try {
    const response = await axios.get(`${baseURL}/admins/all`);
    dispatch(getAdmins(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(authEnd());
  }
};



// GET_ADMINS_GROUP
export const startGetAdminsGroup = () => async (dispatch) => {
  dispatch(authBegin());
  try {
    const response = await axios.get(`${baseURL}/admins/group`);
    dispatch(getAdmins(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(authEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// UPDATE_ADMIN
export const startUpdateAdmin = (uuid, payload, permissions, toggle) => async (dispatch) => {
  dispatch(authBegin());
  try {
    await axios.put(`${baseURL}/admins/${uuid}`, payload);
    toast.success('Admin Updated');
    if (permissions.includes('admin:read-all')) {
      await dispatch(startGetAdminsAll());
    }
    if (permissions.includes('admin:read-group')) {
      await dispatch(startGetAdminsGroup());
    }
    await dispatch(startGetPermissionGroups());
    history.push('/account/team-settings');
    if (toggle) toggle();
    dispatch(updateAdmin());
  } catch (error) {
    console.error(error.response);
    dispatch(authEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// DELETE_ADMIN
export const startDeleteAdmin = (uuid, permissions, toggle) => async (dispatch) => {
  dispatch(authBegin());
  try {
    await axios.delete(`${baseURL}/admins/${uuid}`);
    toast.warning('Admin Deleted');
    if (permissions.includes('admin:read-all')) {
      await dispatch(startGetAdminsAll());
    }
    if (permissions.includes('admin:read-group')) {
      await dispatch(startGetAdminsGroup());
    }
    await dispatch(startGetPermissionGroups());
    history.push('/account/team-settings');
    if (toggle) toggle();
    dispatch(deleteAdmin());
  } catch (error) {
    console.error(error.response);
    dispatch(authEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// GET_JOURNAL_LOGINS_SELF
export const startGetJournalLoginsSelf = () => async (dispatch) => {
  dispatch(authBegin());
  try {
    const response = await axios.get(`${baseURL}/journal-logins/self`);
    dispatch(getJournalLoginsSelf(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(authEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};

// GET_JOURNAL_LOGINS_BY_ID
export const startGetJournalLoginsByID = (uuid) => async (dispatch) => {
  dispatch(authBegin());
  try {
    const response = await axios.get(`${baseURL}/journal-logins/${uuid}`);
    dispatch(getJournalLoginsByID(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(authEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};

// LOGOUT_BY_DEVICE
export const startLogoutByDevice = (session_id, toggle) => async (dispatch) => {
  dispatch(authBegin());
  try {
    await axios.get(`${baseURL}/logout/${session_id}`);
    dispatch(logoutByDevice());
    dispatch(startGetJournalLoginsSelf());
    toggle();
    toast.success('The session has been successfully logged out of the system.');
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong.');
  }
};

// LOGOUT_ALL_DEVICES
export const startLogoutAllDevices = () => async (dispatch) => {
  dispatch(authBegin());
  try {
    await axios.get(`${baseURL}/logout/all`);
    dispatch(logoutAllDevices());
    ls.remove('user');
    ls.remove('page-has-been-force-refreshed');
    ls.remove('config');
    dispatch(clearConfigs());
    dispatch(logout());
    history.push('/');
    toast.warning('All sessions have been successfully logged out of the system.');
  } catch (error) {
    console.error(error.response);
    ls.clear();
    dispatch(clearConfigs());
    dispatch(logout());
    history.push('/');
    toast.error('It looks like something went wrong.');
  }
};


export default slice.reducer;
