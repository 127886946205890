import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Can } from 'bumblebee-ui';
import { useSelector } from 'react-redux';

import { history } from '../../../routers/AppRouter';

const BasicTemplateView = ({ config, isReorder, orderedChecklists, setOrderedChecklists }) => {
  const permissions = useSelector((state) => state.auth.currentUser.permissions);
  const processed_options = JSON.parse(config.options);
  const { payload } = processed_options;

  const templateIndex = orderedChecklists.findIndex((d) => d.uuid === config.uuid);

  return (
    <Fragment>
      <hr />
      <div className='row mb-2 py-2'>
        <div className='col'>
          <div className='d-flex'>
            <h5 className='mb-3 mr-3'>{config.name}</h5>
            <span className='text-muted'>{`Ver. ${config.version?.length === 1 ? '0' : ''}${config.version}`}</span>
          </div>
          <div>{config.description}</div>
        </div>

        <Can
          permissions={permissions}
          perform={'config-checklist:edit'}
          yes={() => (
            <div className='col-auto'>
              {isReorder
                ? (
                  <div className='d-flex align-items-center'>
                    <button type='button' className='btn p-0' onClick={() => {
                      if (templateIndex !== 0) {
                        const newOrderedChecklists = orderedChecklists.slice();
                        [newOrderedChecklists[templateIndex], newOrderedChecklists[templateIndex - 1]] = [newOrderedChecklists[templateIndex - 1], newOrderedChecklists[templateIndex]];
                        setOrderedChecklists(newOrderedChecklists);
                      }
                    }} >
                      <span className={`fas fa-chevron-up mb-0 ml-2 ${templateIndex !== 0 ? 'text-primary' : 'text-muted'}`} />
                    </button>
                    <button type='button' className='btn p-0' onClick={() => {
                      if (templateIndex !== orderedChecklists.length - 1) {
                        const newOrderedChecklists = orderedChecklists.slice();
                        [newOrderedChecklists[templateIndex], newOrderedChecklists[templateIndex + 1]] = [newOrderedChecklists[templateIndex + 1], newOrderedChecklists[templateIndex]];
                        setOrderedChecklists(newOrderedChecklists);
                      }
                    }}
                    >
                      <span className={`fas fa-chevron-down mb-0 mx-3 ${templateIndex !== orderedChecklists.length - 1 ? 'text-primary' : 'text-muted'}`} />
                    </button>
                  </div>
                )
                : (
                  <button
                    className='btn btn-primary btn-xs'
                    onClick={() => history.push(`/app-config/checklist/edit/${config.uuid}`)}
                  >
                    <span className='fas fa-pen mr-2' />Edit
                  </button>
                )
              }
            </div>
          )} />
      </div>
      <ul>
        {payload.map((option, i) => (
          <li key={i}>{option.title}</li>
        ))}
      </ul>
    </Fragment>
  );
};

BasicTemplateView.propTypes = {
  config: PropTypes.object,
  isReorder: PropTypes.bool,
  orderedChecklists: PropTypes.array,
  setOrderedChecklists: PropTypes.func,
};

export default BasicTemplateView;
