import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ContentModal } from 'bumblebee-ui';

import ChecklistInstanceForm from '../forms/ChecklistInstanceForm';
import { startCreateChecklistInstance } from '../../../../redux/reducers/checklists';


const CreateChecklistInstance = ({ isOpen, toggle, config }) => {
  const dispatch = useDispatch();
  return <ContentModal
    isOpen={isOpen}
    toggle={toggle}
    size='sm'
    title='Make Checklist Copy'
  >
    <ChecklistInstanceForm
      config={config}
      onSubmit={(payload) => {
        const data = {
          copy_name: payload.copy_name,
          template: config.template_id,
          user_id: config.user_id,
        };
        dispatch(startCreateChecklistInstance(data, toggle));
      }} />
  </ContentModal>;
};

CreateChecklistInstance.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  config: PropTypes.object,
};

export default CreateChecklistInstance;
