import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import authReducer, { logout } from './reducers/auth';
import applicantReducer from './reducers/applicants';
import configReducer from './reducers/config';
import documentsReducer from './reducers/documents';
import reportReducer from './reducers/reports';
import servicersAuthReducer from './reducers/servicers/servicersAuth';
import vendorReducer from './reducers/servicers/vendor';
import filesReducer from './reducers/servicers/files';
import createReducer from './reducers/servicers/create';
import communicationReducer from './reducers/communications';
import checklistReducer from './reducers/checklists';
import afrReducer from './reducers/afr';
import cdfReducer from './reducers/cdf';
import letterReducer from './reducers/letters';

export default () => {
// eslint-disable-next-line no-underscore-dangle
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const middleware = environment === 'production'
    ? applyMiddleware(thunk)
    : composeEnhancers(applyMiddleware(thunk));

  const servicersReducer = combineReducers({
    auth: servicersAuthReducer,
    vendor: vendorReducer,
    files: filesReducer,
    create: createReducer,
  });

  const appReducer = combineReducers({
    auth: authReducer,
    applicants: applicantReducer,
    config: configReducer,
    documents: documentsReducer,
    reports: reportReducer,
    servicers: servicersReducer,
    communications: communicationReducer,
    checklists: checklistReducer,
    afr: afrReducer,
    cdf: cdfReducer,
    letters: letterReducer,
  });

  const rootReducer = (state, action) => {
    if (action.type === logout.type) return appReducer(undefined, action);
    return appReducer(state, action);
  };

  return createStore(rootReducer, middleware);
};
