import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { uriBuilder } from 'wrkrb';

import { startGetApplicant } from '../reducers/applicants';
// import { history } from '../../routers/AppRouter';
import axios from '../../utils/axios';
import { generateUserPreSignStatus } from '../../utils/helpers';

const initialState = {
  reports: [],
  report: {},
  loading: false,
  cdf_upload: null,
  accounting_upload: null,
  generated_reports: [],
  b_reports: [],
  b_report: [],
  processed_records: [],
  processed_e_records: [],
  executive_overview: [],
  applicant_export_data: [],
  treasury_report: {
    programmatic: [],
    disaggregated: {},
    geographic: [],
    download_loading: false,
  },
  funding_report: {},
  applicant_data_info: {},
  charts_data: {},
  b_record_report: [],
  iq_record_report: [],
  processor_performance: [],
  ssn_report: [],
  reservation_reconciliation_report: [],
  payment_reconciliation_report: [],
  sftp_upload_queue: [],
  filter_groups: [],
};



const slice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    reportsBegin: (reports) => {
      reports.loading = true;
    },
    reportsEnd: (reports) => {
      reports.loading = false;
    },

    getReports: (reports, action) => {
      reports.reports = action.payload;
      reports.loading = false;
    },
    getReport: (reports, action) => {
      reports.report = action.payload;
      reports.loading = false;
    },
    downloadReport: (reports) => {
      reports.loading = false;
    },
    downloadBReport: (reports) => {
      reports.loading = false;
    },
    validateCDFUpload: (reports, action) => {
      reports.cdf_upload = action.payload;
      reports.loading = false;
    },
    validateAccountingUpload: (reports, action) => {
      reports.accounting_upload = action.payload;
      reports.loading = false;
    },

    getAllGeneratedReports: (reports, action) => {
      reports.generated_reports = action.payload;
      reports.loading = false;
    },
    getBReports: (reports, action) => {
      reports.b_reports = action.payload;
      reports.loading = false;
    },
    getBReport: (reports, action) => {
      reports.b_report = action.payload;
      reports.loading = false;
    },
    generateReports: (reports) => {
      reports.loading = false;
    },
    generateBReportSample: (reports) => {
      reports.loading = false;
    },
    generateBReport: (reports) => {
      reports.loading = false;
    },

    getExportDBInfo: (reports, action) => {
      reports.applicant_data_info = action.payload;
      reports.loading = false;
    },
    exportDB: (reports) => {
      reports.loading = false;
    },

    getAllProcessedRecords: (reports, action) => {
      reports.processed_records = action.payload;
      reports.loading = false;
    },

    generateProcessedRecordsReport: (reports) => {
      reports.loading = false;
    },

    getProcessedERecords: (reports, action) => {
      reports.processed_e_records = action.payload;
      reports.loading = false;
    },

    getExecutiveOverview: (reports, action) => {
      reports.executive_overview = action.payload;
      reports.loading = false;
    },

    getApplicantExportData: (reports, action) => {
      reports.applicant_export_data = action.payload;
      reports.loading = false;
    },
    generateApplicantExportData: (reports) => {
      reports.loading = false;
    },

    getTreasuryReportProgrammatic: (reports, action) => {
      reports.treasury_report.programmatic = action.payload;
      reports.loading = false;
    },
    getTreasuryReportDisaggregated: (reports, action) => {
      reports.treasury_report.disaggregated = action.payload;
      reports.loading = false;
    },
    getTreasuryReportGeographic: (reports, action) => {
      reports.treasury_report.geographic = action.payload;
      reports.loading = false;
    },
    treasuryDownloadBegin: (reports) => {
      reports.treasury_report.download_loading = true;
    },
    treasuryDownloadEnd: (reports) => {
      reports.treasury_report.download_loading = false;
    },

    getFundingReport: (reports, action) => {
      reports.funding_report = action.payload;
      reports.loading = false;
    },

    getReportCharts: (reports, action) => {
      reports.charts_data = action.payload;
      reports.loading = false;
    },

    getBRecordReport: (reports, action) => {
      reports.b_record_report = action.payload;
      reports.loading = false;
    },

    getIQRecordReport: (reports, action) => {
      reports.iq_record_report = action.payload;
      reports.loading = false;
    },

    getProcessorPerformance: (reports, action) => {
      reports.processor_performance = action.payload;
      reports.loading = false;
    },

    getSSNReport: (reports, action) => {
      reports.ssn_report = action.payload;
      reports.loading = false;
    },

    getReservationReconciliationReport: (reports, action) => {
      reports.reservation_reconciliation_report = action.payload;
      reports.loading = false;
    },

    getPaymentReconciliationReport: (reports, action) => {
      reports.payment_reconciliation_report = action.payload;
      reports.loading = false;
    },

    getSFTPUploadQueue: (reports, action) => {
      reports.sftp_upload_queue = action.payload;
      reports.loading = false;
    },

    lwcExport: (reports) => {
      reports.loading = false;
    },
    lwcImport: (reports) => {
      reports.loading = false;
    },

    getCoreLogic: (reports) => {
      reports.loading = false;
    },

    getFilterGroups: (reports, action) => {
      reports.filter_groups = action.payload;
      reports.loading = false;
    },
  },
});



export const {
  reportsBegin,
  reportsEnd,

  getReports,
  getReport,
  downloadReport,
  downloadBReport,
  validateCDFUpload,
  validateAccountingUpload,

  getAllGeneratedReports,
  getBReports,
  getBReport,
  generateReports,
  generateBReportSample,
  generateBReport,

  getExportDBInfo,
  exportDB,

  getAllProcessedRecords,
  generateProcessedRecordsReport,

  getProcessedERecords,

  getExecutiveOverview,

  getApplicantExportData,
  generateApplicantExportData,

  getTreasuryReportProgrammatic,
  getTreasuryReportDisaggregated,
  getTreasuryReportGeographic,
  treasuryDownloadBegin,
  treasuryDownloadEnd,

  getFundingReport,

  getReportCharts,

  getBRecordReport,

  getIQRecordReport,

  getProcessorPerformance,

  getSSNReport,

  getReservationReconciliationReport,

  getPaymentReconciliationReport,

  getSFTPUploadQueue,

  lwcExport,
  lwcImport,

  getCoreLogic,

  getFilterGroups,
} = slice.actions;



// GET_REPORTS
export const startGetReports = () => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios.get(`${baseURL}/reports`);
    dispatch(getReports(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// GET_REPORTS
export const startGetReport = (report_uuid) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios.get(`${baseURL}/reports/${report_uuid}`);
    dispatch(getReport(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// DOWNLOAD_REPORT
export const startDownloadReport = (report_id, process_date, setSubmitting) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    let uri = `${baseURL}/reports/download/${report_id}/`;
    if (process_date) {
      uri = uriBuilder(uri, 'process_date', process_date);
    }
    const response = await axios({ url: uri, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(downloadReport(response.data));
  } catch (error) {
    setSubmitting();
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// DOWNLOAD_B_REPORT
export const startDownloadBReport = (file_id, report_id) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios({ url: `${baseURL}/reports/download/b/${report_id}/${file_id}`, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(downloadBReport(response.data));
  } catch (error) {
    toast.error('It looks like something went wrong. Please try again.');
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// VALIDATE_UPLOAD
export const startValidateCDFUpload = (file) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    const formData = new FormData();
    formData.append('document', file);
    const response = await axios.post(`${baseURL}/reports/validate-cdf-upload`, formData, config);
    dispatch(validateCDFUpload(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(validateCDFUpload(null));
    toast.error('It looks like something went wrong. Please try again.');
  }
  dispatch(reportsEnd());
};



// VALIDATE_UPLOAD (from SFTP)
export const startValidateSFTPCDF = (report_id) => async (dispatch) => {
  await dispatch(validateCDFUpload(null));
  dispatch(reportsBegin());
  try {
    const response = await axios.get(`${baseURL}/reports/validate-servicer-cdf-upload/${report_id}`);
    dispatch(validateCDFUpload(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// Upload Report
export const startUploadReport = (payload, toggle, cb) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    await axios.post(`${baseURL}/reports/upload-cdf`, payload);
    cb();
    toast.success('CDF Successfully Uploaded!');
    dispatch(validateCDFUpload(null));
  } catch (error) {
    console.error(error.response);
    toggle();
    toast.error('It looks like something went wrong. Please try again.');
  }
  dispatch(reportsEnd());
};



// VALIDATE_ACCOUNTING_UPLOAD
export const startValidateAccountingUpload = (payload) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios.post(`${baseURL}/reports/validate-accounting-upload`, payload);
    dispatch(validateAccountingUpload(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// UPLOAD_ACCOUNTING
export const startUploadAccounting = (payload, toggle, clearAccountingUpload) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    await axios.post(`${baseURL}/reports/upload-accounting`, payload);
    clearAccountingUpload();
    toast.success('Accounting Import Successfully Uploaded!');
  } catch (error) {
    console.error(error.response);
    toggle();
    toast.error('It looks like something went wrong. Please try again.');
  }
  dispatch(reportsEnd());
};



// GET_REPORTS
export const startGetAllGeneratedReports = () => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios.get(`${baseURL}/reports/records/all`);
    dispatch(getAllGeneratedReports(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// GET_B_REPORTS
export const startGetBReports = (start_date, end_date) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    let uri = `${baseURL}/reports/records/b/`;
    if (start_date) {
      uri = uriBuilder(uri, 'start_date', start_date);
    }
    if (end_date) {
      uri = uriBuilder(uri, 'end_date', end_date);
    }
    const response = await axios.get(`${uri}`);
    dispatch(getBReports(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// GET_B_REPORT
export const startGetBReport = (toggle) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios.get(`${baseURL}/reports/b/list`);
    dispatch(getBReport(response.data));
    toggle();
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// GENERATE REPORTS SAMPLE
export const startGenerateReportsSample = (payload) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios({ url: `${baseURL}/reports/generate/all/sample`, method: 'POST', responseType: 'blob', data: payload });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
  }
  dispatch(reportsEnd());
};



// GENERATE_REPORTS
export const startGenerateReports = (payload, toggle) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios({ url: `${baseURL}/reports/generate/all`, method: 'POST', responseType: 'blob', data: payload });
    // if only SFTP export type, there will be no download
    if (response.status !== 201) {
      const a = document.createElement('a');
      const [, fileName] = response.headers['content-disposition'].split('filename=');
      a.download = decodeURI(fileName);
      a.href = window.URL.createObjectURL(new Blob([response.data]));
      document.body.append(a);
      a.click();
    }
    dispatch(generateReports());
    await dispatch(startGetAllGeneratedReports());
    toggle();
    toast.success('Success!');
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
    toggle();
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// GENERATE_B_REPORT_SAMPLE
export const startGenerateBReportSample = (payload) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios({ url: `${baseURL}/reports/generate/b/sample`, method: 'POST', responseType: 'blob', data: payload });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(generateBReportSample());
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// GENERATE_B_REPORT
export const startGenerateBReport = (payload, cb) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios({ url: `${baseURL}/reports/generate/b`, method: 'POST', responseType: 'blob', data: payload });
    // if only SFTP export type, there will be no download
    if (response.status !== 201) {
      const a = document.createElement('a');
      const [, fileName] = response.headers['content-disposition'].split('filename=');
      a.download = decodeURI(fileName);
      a.href = window.URL.createObjectURL(new Blob([response.data]));
      document.body.append(a);
      a.click();
    }
    dispatch(generateBReport());
    toast.success('Success!');
    cb();
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// GET_ALL_PROCESSED_RECORDS
export const startGetAllProcessedRecords = () => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios.get(`${baseURL}/reports/records/all-processed`);
    dispatch(getAllProcessedRecords(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// GENERATE_PROCESSED_RECORDS_REPORT
export const startGenerateProcessedRecordsReport = (payload) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios({ url: `${baseURL}/reports/records/processed/download`, method: 'POST', responseType: 'blob', data: payload });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(generateProcessedRecordsReport());
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// GET_PROCESSED_E_RECORDS
export const startGetProcessedERecords = () => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios.get(`${baseURL}/reports/records/processed/e`);
    dispatch(getProcessedERecords(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// GET_EXECUTIVE_OVERVIEW
export const startGetExecutiveOverview = (start_date, end_date) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    let uri = `${baseURL}/reports/executive-overview/`;
    if (start_date) {
      uri = uriBuilder(uri, 'start_date', start_date);
    }
    if (end_date) {
      uri = uriBuilder(uri, 'end_date', end_date);
    }
    const response = await axios.get(uri);
    const updateStatus = response.data.map((d) => {
      if (!d.status) {
        const filter_status = generateUserPreSignStatus(d);
        d.status = {
          theme: 'info',
          internal_name: filter_status,
        };
        d.current_status = filter_status;
      } else {
        d.current_status = d.status.internal_name;
      }
      return d;
    });
    dispatch(getExecutiveOverview(updateStatus));
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// GET_APPLICANT_EXPORT_DATA
export const startGetApplicantsExportData = () => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios.get(`${baseURL}/reports/applicant-export`);
    dispatch(getApplicantExportData(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// GENERATE_APPLICANTS_EXPORT_DATA
export const startGenerateApplicantExportData = (payload, toggle) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios({ url: `${baseURL}/reports/applicant-export/download`, method: 'POST', responseType: 'blob', data: payload });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(generateApplicantExportData());
    toggle();
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// GET_TREASURY_REPORT_PROGRAMMATIC, GET_TREASURY_REPORT_DISAGGREGATED, GET_TREASURY_REPORT_GEOGRAPHIC
export const startGetTreasuryReport = (start_date, end_date, data_type) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    let uri = `${baseURL}/reports/treasury/${data_type}/`;
    if (start_date) {
      uri = uriBuilder(uri, 'start_date', start_date);
    }
    if (end_date) {
      uri = uriBuilder(uri, 'end_date', end_date);
    }
    const response = await axios.get(uri);
    if (data_type === 'programmatic') {
      dispatch(getTreasuryReportProgrammatic(response.data));
    } else if (data_type === 'disaggregated') {
      dispatch(getTreasuryReportDisaggregated(response.data));
    } else if (data_type === 'geographic') {
      dispatch(getTreasuryReportGeographic(response.data));
    } else {
      dispatch(reportsEnd());
    }
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// Download Treasury Report
export const startDownloadTreasuryReport = (start_date, end_date) => async (dispatch) => {
  dispatch(treasuryDownloadBegin());
  try {
    let uri = `${baseURL}/reports/treasury/download/`;
    if (start_date) {
      uri = uriBuilder(uri, 'start_date', start_date);
    }
    if (end_date) {
      uri = uriBuilder(uri, 'end_date', end_date);
    }
    const response = await axios({ url: uri, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(treasuryDownloadEnd());
  } catch (error) {
    console.error(error.response);
    dispatch(treasuryDownloadEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// GET_FUNDING_REPORT
export const startGetFundingReport = (start_date, end_date) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    let uri = `${baseURL}/reports/funding/`;
    if (start_date) {
      uri = uriBuilder(uri, 'start_date', start_date);
    }
    if (end_date) {
      uri = uriBuilder(uri, 'end_date', end_date);
    }
    const response = await axios.get(uri);
    dispatch(getFundingReport(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// GET_EXPORT_DB_INFO
export const startGetExportDBInfo = (toggle) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios.get(`${baseURL}/reports/all-data`);
    dispatch(getExportDBInfo(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
    toggle(false);
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// EXPORT_DB
export const startExportDB = (toggle, isExcel) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const url = isExcel ? `${baseURL}/reports/all-data/download/excel` : `${baseURL}/reports/all-data/download/all`;
    const response = await axios({ url, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(exportDB());
    toggle();
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// GET_REPORT_CHARTS
export const startGetReportCharts = () => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios.get(`${baseURL}/reports/charts`);
    dispatch(getReportCharts(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// GET_B_RECORD_REPORT
export const startGetBRecordReport = () => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios.get(`${baseURL}/reports/b-record`);
    dispatch(getBRecordReport(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// GET_IQ_RECORD_REPORT
export const startGetIQRecordReport = () => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios.get(`${baseURL}/reports/iq-record`);
    dispatch(getIQRecordReport(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// Get Processor Performance
export const startGetProcessorPerformance = (start_date, end_date) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    let uri = `${baseURL}/reports/processor-performance/`;
    if (start_date) {
      uri = uriBuilder(uri, 'start_date', start_date);
    }
    if (end_date) {
      uri = uriBuilder(uri, 'end_date', end_date);
    }
    const response = await axios.get(uri);
    dispatch(getProcessorPerformance(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// Get SSN Report
export const startGetSSNReport = () => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios.get(`${baseURL}/reports/ssn`);
    dispatch(getSSNReport(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// GET_RESERVATION_RECONCILIATION_REPORT
export const startGetReservationReconciliationReport = () => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios.get(`${baseURL}/reports/reservation-reconciliation`);
    dispatch(getReservationReconciliationReport(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// GET_PAYMENT_RECONCILIATION_REPORT
export const startGetPaymentReconciliationReport = (start_date, end_date) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    let uri = `${baseURL}/reports/payment-reconciliation/`;
    if (start_date) {
      uri = uriBuilder(uri, 'start_date', start_date);
    }
    if (end_date) {
      uri = uriBuilder(uri, 'end_date', end_date);
    }
    const response = await axios.get(uri);
    dispatch(getPaymentReconciliationReport(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// GET_SFTP_UPLOAD_QUEUE
export const startGetSFTPUploadQueue = () => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios.get(`${baseURL}/reports/sftp-upload-queue/`);
    dispatch(getSFTPUploadQueue(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// Download File from SFTP
export const startDownloadSFTPFile = (file_uuid) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios({ url: `${baseURL}/reports/sftp-uploads/download/${file_uuid}`, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
  }
  dispatch(reportsEnd());
};



// Dismiss/Undismiss SFTP file
export const startDismissSFTPFile = (file_uuid, is_dismissed) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    await axios.put(`${baseURL}/reports/sftp-uploads/dismiss/${file_uuid}`, { is_dismissed });
    await dispatch(startGetSFTPUploadQueue());
    toast.success(is_dismissed ? 'File Dismissed' : 'File Undismissed');
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
  }
  dispatch(reportsEnd());
};



// LWC_EXPORT
export const startLWCExport = (payload) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios({ url: `${baseURL}/lwc-user-data/export`, method: 'POST', responseType: 'blob', data: payload });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(lwcExport());
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// LWC_IMPORT
export const startLWCImport = (payload) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios.post(`${baseURL}/lwc-user-data/import`, payload);
    dispatch(lwcImport());
    toast.success(`Imported ${response.data.validRecords} valid records!`);
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
    toast.error(error.response.status === 400 ? 'No unique records' : 'It looks like something went wrong. Please try again.');
  }
};



// CORE_LOGIC
export const startGetCoreLogic = (user_uuid, i_record_id) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    const response = await axios({ url: `${baseURL}/core-logic/${user_uuid}/${i_record_id}`, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const [, fileName] = response.headers['content-disposition'].split('filename=');
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(getCoreLogic());
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// Resware
export const startRequestResware = (user_uuid, toggle, setSubmitting) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    await axios.post(`${baseURL}/resware/${user_uuid}`);
    await dispatch(startGetApplicant(user_uuid));
    toggle();
    toast.success('Requested!');
  } catch (error) {
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
  }
  setSubmitting();
  dispatch(reportsEnd());
};



// GET_FILTER_GROUPS
export const startGetFilterGroups = (path, isDelete) => async (dispatch) => {
  if (!isDelete) dispatch(getFilterGroups([]));
  dispatch(reportsBegin());
  try {
    const response = await axios.post(`${baseURL}/filter-groups`, { path });
    dispatch(getFilterGroups(response.data));
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
  }
};



// Create filter group
export const startCreateFilterGroup = (path, payload, toggle) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    await axios.post(`${baseURL}/filter-groups/create`, { ...payload, path });
    await dispatch(startGetFilterGroups(path));
    toggle();
    toast.success('Saved!');
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};


// Update filter group
export const startUpdateFilterGroup = (path, uuid, payload) => async (dispatch) => {
  dispatch(reportsBegin());
  try {
    await axios.put(`${baseURL}/filter-groups/update`, { ...payload, uuid });
    await dispatch(startGetFilterGroups(path));
    toast.success('Updated!');
  } catch (error) {
    console.error(error.response);
    dispatch(reportsEnd());
    toast.error('It looks like something went wrong. Please try again.');
  }
};



// Delete filter group
export const startDeleteFilterGroup = (path, filter_group_uuid) => async (dispatch, getState) => {
  // optimistic update filter groups
  const currentFilterGroups = getState().reports.filter_groups;
  dispatch(getFilterGroups(currentFilterGroups.filter((group) => group.uuid !== filter_group_uuid)));

  dispatch(reportsBegin());
  try {
    await axios.delete(`${baseURL}/filter-groups/${filter_group_uuid}`);
    await dispatch(startGetFilterGroups(path, true));
    toast.warning('Deleted!');
  } catch (error) {
    // revert optimistic update
    getFilterGroups(currentFilterGroups);
    console.error(error.response);
    toast.error('It looks like something went wrong. Please try again.');
  }
};



export default slice.reducer;
